import React, { useContext, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SpeedIcon from '@mui/icons-material/Speed';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import Slider from '@mui/material/Slider';

import Tooltip from '@mui/material/Tooltip';

import { TextField } from '@mui/material';

import FormatSizeIcon from '@mui/icons-material/FormatSize';

export default function BasicPopover({
  valueKey,
  value,
  handleClose: handleMenuClose
}) {
  const { widgets, setWidgets, selectedWidgetIds, presetColors } =
    useContext(ReportBuilderContext);
  // console.log('daysToGraph: ', daysToGraph);

  // const [value, setValue] = React.useState(false);

  // useEffect(() => {
  //   const defaultFontSize =
  //     widgets.filter((wid) => selectedWidgetIds.includes(wid.id))?.[0]?.[
  //       valueKey
  //     ] || 14;
  //   setValue(defaultFontSize);
  // }, [anchorEl, selectedWidgetIds]);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const copyOfWidgets = [...widgets];
    for (let copyOfWidget of copyOfWidgets) {
      if (selectedWidgetIds.includes(copyOfWidget.id)) {
        copyOfWidget[valueKey] = value;
      }
    }

    // setValue(!value);
    setWidgets(copyOfWidgets);
    handleMenuClose();
  };

  // let name = '';
  // switch (valueKey) {
  //   case 'isTitleFontBold':
  //     name = 'Title Font Bild';
  //     break;

  // }

  return (
    <>
      <Button
        // aria-describedby={id}
        style={{
          width: '100%',

          textAlign: 'start',
          textTransform: 'none',
          width: '100%',
          fontSize: '12px',
          padding: '10px 10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start'
        }}
        onClick={handleClick}
      >
        <span>{value}</span>
      </Button>
    </>
  );
}
