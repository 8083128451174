import React, { useState, useEffect, useContext, useRef } from 'react';

import { DialogContext } from '../../../../context/DialogContext';

import { styled, useTheme } from '@mui/material/styles';

import DatePickerWidget from './widgets/DatePickerWidget';

import ImageWidget from './widgets/ImageWidget';
import MockupWidget from './widgets/MockupWidget';
import LogoWidget from './widgets/LogoWidget';
import WidgetWrapper from './widgets/WidgetWrapper';
import TextWidget from './widgets/TextWidget';
import NumberWidget from './widgets/NumberWidget';
import LineChartWidget from './widgets/LineChartWidget';
import ComboChartWidget from './widgets/ComboChartWidget';
import PieChartWidget from './widgets/PieChartWidget';
import TableChartWidget from './widgets/TableChartWidget';
import DateWidget from './widgets/DateWidget';
import ListWidget from './widgets/list-widget/ListWidget';

import RectangleShapeWidget from './widgets/RectangleShapeWidget';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import WorkIcon from '@mui/icons-material/Work';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditLocationIcon from '@mui/icons-material/EditLocation';

import DeleteIcon from '@mui/icons-material/Delete';

import { Button } from '@mui/material';

import { SnackbarProvider, useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import { useLazyQuery, useMutation } from '@apollo/client';

import { AccountContext } from '../../../../context/AccountContext';

import { MutationContext } from '../../../../context/MutationContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { v4 as uuidv4 } from 'uuid';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../fields';

import {
  accountUsersQuery,
  updateDocumentMutation
} from '../../../../graphql/queries';

function ReportBuilderPage({
  zoom,
  setZoom,

  taxonomyFieldIdsInEditMode,
  setTaxonomyFieldIdsInEditMode,
  // editMode,
  // setEditMode,
  displayMode,
  // item,
  // rowIndex,
  deleted,
  setDeleted,
  isDraggingWidget,
  setIsDraggingWidget,

  setTaxonomyString

  // grabbing,
  // toggleGrabbing
  // itemStyle,
  // checked,
  // rowNumber
}) {
  const [isResizingWidget, setIsResizingWidget] = useState(false);

  // let zoom = 0.5;

  const [mouseDownWidgetIndex, setMouseDownWidgetIndex] = useState(null);
  const [mouseDownWidgetId, setMouseDownWidgetId] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  // console.log('isMouseDown: ', isMouseDown);

  const [mousePosition, setMousePosition] = React.useState({
    x: null,
    y: null
  });

  const [mouseDownPosition, setMouseDownPosition] = React.useState({
    x: null,
    y: null
  });

  const [onMouseDownPosition, setOnMouseDownPosition] = React.useState({
    x: null,
    y: null
  });

  const [mouseDownTimeboxIds, setMouseDownTimeboxIds] = useState([]);
  const [isShiftSelected, setIsShiftSelected] = useState(false);
  const [mouseDownAction, setMouseDownAction] = useState();

  const [originalWidgets, setOriginalWidgets] = useState([]);
  const [originalWidget, setOriginalWidget] = useState(null);

  const {
    selectedPage,
    widgets,
    setWidgets,
    updateWidget,
    selectedPageId,
    reportPage,
    setReportPage,
    currentlyDraggingWidget,
    setCurrentlyDraggingWidget,
    selectedWidgetIds,
    setSelectedWidgetIds,
    setWidgetIdThatTriggeredContextMenu,
    setWidgetElementThatTriggeredContextMenu,
    setContextMenuAnchorPosition,
    hasMouseMoved,
    setHasMouseMoved,
    inputImageTriggerCounter,
    setInputImageTriggerCounter,
    updateReportPage,
    widgetIdThatTriggeredContextMenu
  } = useContext(ReportBuilderContext);
  // const widgets = page.widgets;
  // const [reportBuilderFieldStyle, setReportBuilderStyle] = useState({
  //   height: 1080 * 1 + 'px',
  //   width: 1920 * 1 + 'px'
  // });
  const [reportBuilderOriginalFieldStyle, setReportBuilderOriginalFieldStyle] =
    useState({});

  const onMouseDownHandler = (e, widgetIndex, action) => {
    // console.log('onMouseDownHandler>action: ', action);
    e.stopPropagation();

    // if (action === 'create') {
    //   return;
    // }
    updateMousePosition(e);
    // console.log('originalWidget: ', originalWidget);

    // console.log('debug532>isMouseDown: ', isMouseDown);
    // console.log('debug532>mousePosition: ', mousePosition);
    if (!isMouseDown && !action) {
      setOnMouseDownPosition({ ...mousePosition });
    }

    if (!isMouseDown && action) {
      switch (action) {
        case 'move':
        case 'resize':
          const widget = widgets[widgetIndex];

          if (!widget?.type) {
            return;
          }
          // case 'changeEndTime':
          //   if (!mouseDownTimeboxIds.includes(timeboxId)) {
          //     setMouseDownTimeboxIds([timeboxId]);
          //     setIsShiftSelected(false);
          //     // setIsTimeboxesMoved(false);
          //     return;
          //   }
          //   timeboxId = _timeboxId;
          //   const originalTimeboxes = timeboxes.filter(
          //     (obj) => obj._id === timeboxId
          //   )[0];
          setOriginalWidget({
            ...widgets[widgetIndex]
            // style: { ...widgets[widgetIndex].style }
          });
          setOriginalWidgets((widgets || []).map((obj) => ({ ...obj })));

          //   setDisplayTimeboxes(originalTimebox_);

          break;

        case 'resize_page':
          setReportBuilderOriginalFieldStyle({
            height: reportPage.height,
            width: reportPage.width
            // style: { ...reportBuilderFieldStyle.style }
          });
          break;
      }
    }
    setOnMouseDownPosition({ ...mousePosition });
    setMouseDownAction(action);
    setMouseDownPosition({ ...mousePosition });
    setIsMouseDown(true);
  };

  const [lastUpdatedPixels, setLastUpdatedPixels] = useState(0);
  // console.log('hasMouseMoved: ', hasMouseMoved);
  useEffect(() => {
    // console.log('debug36>isMouseDown: ', isMouseDown);
    if (isMouseDown) {
      // console.log('debug36>mouseDownPosition: ', mouseDownPosition);
      // console.log('debug36>mouseDownAction: ', mouseDownAction);
      let pixelsMovedX = 0;
      let pixelsMovedY = 0;

      pixelsMovedX = Math.round(onMouseDownPosition.x - mouseDownPosition.x);
      pixelsMovedX = pixelsMovedX / zoom;
      pixelsMovedX = Math.round(pixelsMovedX / 10) * 10;
      pixelsMovedX = pixelsMovedX * zoom;

      pixelsMovedY = Math.round(onMouseDownPosition.y - mouseDownPosition.y);
      pixelsMovedY = pixelsMovedY / zoom;
      pixelsMovedY = Math.round(pixelsMovedY / 10) * 10;
      pixelsMovedY = pixelsMovedY * zoom;

      if (
        pixelsMovedX < 1 &&
        pixelsMovedX > -1 &&
        pixelsMovedY < 1 &&
        pixelsMovedY > -1 &&
        !hasMouseMoved
      ) {
        return;
      } else {
        setHasMouseMoved(true);
      }

      // console.log('debug93>pixelsMovedX: ', pixelsMovedX);
      // console.log('debug93>pixelsMovedY: ', pixelsMovedY);
      // console.log('debug93>mouseDownAction: ', mouseDownAction);

      switch (mouseDownAction) {
        case 'resize':
          setIsResizingWidget(true);
          let selectedWidgetIds_ = selectedWidgetIds.includes(mouseDownWidgetId)
            ? selectedWidgetIds
            : [mouseDownWidgetId];
          // if (selectedWidgetIds.length < 2) {
          //   return;
          // }
          // console.log('debug36>pixelsMovedX: ', pixelsMovedX);
          // if (
          //   pixelsMovedX < 1 &&
          //   pixelsMovedX > -1 &&
          //   pixelsMovedY < 1 &&
          //   pixelsMovedY > -1
          // ) {
          //   return;
          // }

          // move
          var copyOfOriginalWidgets = (originalWidgets || []).map((obj) => ({
            ...obj
          }));
          // console.log('debug36>copyOfOriginalWidgets: ', copyOfOriginalWidgets);
          var updatedWidgets = [];
          for (let copyOfOriginalWidget of copyOfOriginalWidgets) {
            const newWidget = copyOfOriginalWidget;
            // let copyOfWidget = { ...widget };
            if (selectedWidgetIds_.includes(copyOfOriginalWidget.id)) {
              const originalWidget = originalWidgets.filter(
                (wid) => wid.id === copyOfOriginalWidget.id
              )[0];
              // console.log(
              //   'debug36copyOfOriginalWidget.top: ',
              //   copyOfOriginalWidget.top
              // );
              // copyOfOriginalWidget.top =
              //   +originalWidget.top.split('px')[0] - pixelsMovedY + 'px';
              // copyOfOriginalWidget.left =
              //   +originalWidget.left.split('px')[0] - pixelsMovedX + 'px';

              let minHeight = 50;

              let minWidth = 50;

              switch (originalWidget?.type) {
                case 'MANAGER_ACCOUNT_LOGO_WIDGET':
                case 'DATE_PICKER_WIDGET':
                case 'TEXT_WIDGET':
                  break;
                case 'IMAGE_WIDGET':
                  break;
                case 'MOCKUP_WIDGET':
                  break;

                case 'RECTANGLE_SHAPE_WIDGET':
                  minHeight = 10;

                  minWidth = 10;
                  break;

                default:
                  minHeight = 150;

                  minWidth = 150;
              }

              var newWidth =
                originalWidget?.width?.split('px')?.[0] - pixelsMovedX / zoom;
              newWidget.width =
                Math.round((newWidth < minWidth ? minWidth : newWidth) / 10) *
                  10 +
                'px';
              // }
              // if (!pixelsMovedY % 5) {

              const ratio = originalWidget?.originalAspectRatio;

              // console.log('ratio: ', ratio);

              if (originalWidget?.isAspectRatioLocked) {
                var newHeight =
                  Math.round((newWidth * zoom * ratio) / zoom / 10) * 10;

                newWidget.height =
                  (newHeight < minHeight ? minHeight : newHeight) + 'px';
                // }
              } else {
                var newHeight =
                  originalWidget?.height?.split('px')?.[0] -
                  pixelsMovedY / zoom;
                newWidget.height =
                  Math.round(
                    (newHeight < minHeight ? minHeight : newHeight) / 10
                  ) *
                    10 +
                  'px';
                //(newHeight < minHeight ? minHeight : newHeight) + 'px';
                // }
              }
            }

            updatedWidgets.push(copyOfOriginalWidget);
          }
          setWidgets(updatedWidgets);

          // const widget = widgets[mouseDownWidgetIndex];
          // const newWidget = { ...widget };

          // const copyOfWidgets = (widgets || []).map((obj) => ({ ...obj }));

          // if (pixelsMovedX % 5 || pixelsMoveY % 5) {
          //   return;
          // }
          // console.log('debug93>newWidget: ', newWidget);

          // if (!pixelsMovedX % 5) {

          // let minHeight = 50;

          // let minWidth = 50;

          // switch (widget?.type) {
          //   case 'MANAGER_ACCOUNT_LOGO_WIDGET':
          //   case 'DATE_PICKER_WIDGET':
          //   case 'TEXT_WIDGET':
          //     break;
          //   case 'IMAGE_WIDGET':
          //     break;
          //   case 'MOCKUP_WIDGET':
          //     break;

          //   case 'RECTANGLE_SHAPE_WIDGET':
          //     minHeight = 10;

          //     minWidth = 10;
          //     break;

          //   default:
          //     minHeight = 150;

          //     minWidth = 150;
          // }

          // var newWidth =
          //   originalWidget?.width?.split('px')?.[0] - pixelsMovedX / zoom;
          // newWidget.width =
          //   Math.round((newWidth < minWidth ? minWidth : newWidth) / 10) * 10 +
          //   'px';
          // // }
          // // if (!pixelsMovedY % 5) {

          // const ratio = widget?.originalAspectRatio;

          // // console.log('ratio: ', ratio);

          // if (widget?.isAspectRatioLocked) {
          //   var newHeight =
          //     Math.round((newWidth * zoom * ratio) / zoom / 10) * 10;

          //   newWidget.height =
          //     (newHeight < minHeight ? minHeight : newHeight) + 'px';
          //   // }
          // } else {
          //   var newHeight =
          //     originalWidget?.height?.split('px')?.[0] - pixelsMovedY / zoom;
          //   newWidget.height =
          //     Math.round((newHeight < minHeight ? minHeight : newHeight) / 10) *
          //       10 +
          //     'px';
          //   //(newHeight < minHeight ? minHeight : newHeight) + 'px';
          //   // }
          // }

          // copyOfWidgets[mouseDownWidgetIndex] = newWidget;
          // // console.log('debug94>newWidget: ', newWidget);

          // setWidgets(copyOfWidgets);
          break;

        case 'resize_page':
          const newReportBuilderFieldStyle = {
            height: reportPage.height,
            width: reportPage.width
          };
          var newWidth =
            reportBuilderOriginalFieldStyle?.width?.split('px')?.[0] -
            pixelsMovedX / zoom;

          newWidth = Math.round(newWidth / 20) * 20 - 10;
          newReportBuilderFieldStyle.width =
            (newWidth < 400 ? 400 : newWidth) + 'px';
          // }
          // if (!pixelsMovedY % 5) {

          var newHeight =
            reportBuilderOriginalFieldStyle?.height?.split('px')?.[0] -
            pixelsMovedY / zoom;

          newHeight = Math.round(newHeight / 20) * 20 - 10;
          newReportBuilderFieldStyle.height =
            (newHeight < 400 ? 400 : newHeight) + 'px';

          setReportPage({ ...reportPage, ...newReportBuilderFieldStyle });

          // setReportBuilderStyle(newReportBuilderFieldStyle);
          break;
        case 'move':
          if (selectedWidgetIds.length < 1) {
            return;
          }
          // console.log('debug36>pixelsMovedX: ', pixelsMovedX);
          // if (
          //   pixelsMovedX < 1 &&
          //   pixelsMovedX > -1 &&
          //   pixelsMovedY < 1 &&
          //   pixelsMovedY > -1
          // ) {
          //   return;
          // }

          // move
          var copyOfOriginalWidgets = (originalWidgets || []).map((obj) => ({
            ...obj
          }));
          // console.log('debug36>copyOfOriginalWidgets: ', copyOfOriginalWidgets);
          var updatedWidgets = [];
          for (let copyOfOriginalWidget of copyOfOriginalWidgets) {
            // let copyOfWidget = { ...widget };
            if (selectedWidgetIds.includes(copyOfOriginalWidget.id)) {
              const originalWidget = originalWidgets.filter(
                (wid) => wid.id === copyOfOriginalWidget.id
              )[0];
              // console.log(
              //   'debug36copyOfOriginalWidget.top: ',
              //   copyOfOriginalWidget.top
              // );
              copyOfOriginalWidget.top =
                +originalWidget.top.split('px')[0] - pixelsMovedY / zoom + 'px';
              copyOfOriginalWidget.left =
                +originalWidget.left.split('px')[0] -
                pixelsMovedX / zoom +
                'px';
            }

            updatedWidgets.push(copyOfOriginalWidget);
          }
          setWidgets(updatedWidgets);
      }
    }
  }, [mouseDownPosition]);

  const onMouseUpHandler = async (e, elementType, timeboxId, trigger) => {
    // console.log('debug36>isMouseDown: ', isMouseDown);
    if (isResizingWidget) {
      setIsResizingWidget(false);
    }
    setIsMouseDown(false);
    setHasMouseMoved(false);
  };

  const updateMousePosition = (ev) => {
    if (isMouseDown) {
      // console.log('updateMousePosition>ev: ', ev);
      setMouseDownPosition({ x: ev.clientX, y: ev.clientY });
    }

    setMousePosition({ x: ev.clientX, y: ev.clientY });
    // }
  };

  // const [isEditSettingsMode, setIsEditSettingsMode] = useState(false);

  const [displayError, setDisplayError] = useState(null);

  const { currentManagerAccountId } = useContext(AccountContext);
  // const [updateDocument] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);
  const [editMode, setEditMode] = useState(false);
  const [numberOfReset, setNumberOfReset] = useState(0);
  const action = (key) => (
    <>
      {/* <button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
        Alert
      </button> */}
      <Button
        style={{ maxWidth: '40px', minWidth: '40px', width: '40px' }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon style={{ color: 'black' }} />
      </Button>
    </>
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();

  // const [widgets, setWidgets] = useState([]);

  // console.log('widgets: ', widgets);

  // const updateWidget = (widget, index) => {
  //   const copyOfWidgets = [...widgets];
  //   copyOfWidgets.splice(index, 1, widget);
  //   setWidgets(copyOfWidgets);
  // };

  const onContentCopyHandler = () => {
    navigator.clipboard.writeText(
      (widgets || []).map((widget) => widget?.value).join('')
    );
    enqueueSnackbar('Copied to clipboard', {
      action
      // variant: 'error',
      // persist: true
    });
  };

  const dragStart = (e, value, origin, originIndex) => {
    // toggleGrabbing(true);
    setCurrentlyDraggingWidget(value);
    setIsDraggingWidget(true);
    e.stopPropagation();
    console.log('origin: ', origin);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        value,
        origin,
        originIndex,
        eNativeEventOffsetX: e.nativeEvent.offsetX,
        eNativeEventOffsetY: e.nativeEvent.offsetY
      })
    );
    // console.log('{ value, origin, originIndex }: ', {
    //   value,
    //   origin,
    //   originIndex
    // });
    // console.log(
    //   'JSON.stringify({ value, origin, originIndex }): ',
    //   JSON.stringify({ value, origin, originIndex })
    // );

    // target.width =
    //   value.width.split('px')[0] * transformScale + 'px';
    // target.minWidth =
    //   value.width.split('px')[0] * transformScale + 'px';
    // target.height =
    //   value.height.split('px')[0] * transformScale + 'px';
    // target.minHeight =
    //   value.height.split('px')[0] * transformScale + 'px';
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    setCurrentlyDraggingWidget(null);
    setIsDraggingWidget(false);
    console.log('dragEnd');
    const target = e.target;

    // target.style.width = value.style.width.split('px')[0] * 1 + 'px';
    // target.style.minWidth = value.style.width.split('px')[0] * 1 + 'px';
    // target.style.height = value.style.height.split('px')[0] * 1 + 'px';
    // target.style.minHeight = value.style.height.split('px')[0] * 1 + 'px';

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropOnPage = (e, value) => {
    setIsMouseDown(false);
    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    console.log('isFileTransfer: ', isFileTransfer);

    if (isFileTransfer) {
      e.preventDefault();
      return;
    }
    console.log('debug76>dropOnPage');
    // toggleGrabbing(false);
    setIsDraggingWidget(false);
    console.log('value: ', value);
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    if (!droppedItemString) {
      return;
    }
    const droppedItem = JSON.parse(droppedItemString);
    console.log('debug>droppedItem: ', droppedItem);

    if (droppedItem?.value?.type === 'ERASE_ALL_TOOL') {
      setWidgets([], selectedPageId, true);
      return;
    }

    if (!droppedItem?.value.type.includes('WIDGET')) {
      return;
    }

    // console.log(
    //   'debug>droppedItem.originRowIndex:',
    //   droppedItem.originRowIndex
    // );
    // console.log('debug>rowIndex: ', rowIndex);
    // console.log(
    //   'debug>droppedItem.originRowIndex !== 0: ',
    //   droppedItem.originRowIndex !== 0
    // );
    // if (
    //   (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
    //   droppedItem.originRowIndex !== rowIndex
    // ) {
    //   return;
    // }
    let copyOfWidgets = [...widgets];

    // console.log('droppedItem.origin: ', droppedItem.origin);
    if (droppedItem.origin === 'field_widgets') {
      console.log('droppedItem.index: ', droppedItem.originIndex);
      copyOfWidgets.splice(droppedItem.originIndex, 1);

      // console.log('copyOfWidgets: ', copyOfWidgets);
    }
    // console.log('copyOfWidgets: ', copyOfWidgets);
    setWidgets(
      [
        ...copyOfWidgets,
        {
          id: uuidv4(),
          ...droppedItem.value,
          left:
            Math.round(
              (e.nativeEvent.offsetX - droppedItem.eNativeEventOffsetX) /
                zoom /
                10
            ) *
              10 +
            'px',
          top:
            Math.round(
              (e.nativeEvent.offsetY - droppedItem.eNativeEventOffsetY) /
                zoom /
                10
            ) *
              10 +
            'px'
        }
      ],
      selectedPageId,
      true
    );

    // const droppedItemElement = document.getElementById(droppedItem);
  };
  const dropOnWidget = (e, value, index) => {
    setIsMouseDown(false);
    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    if (isFileTransfer) {
      e.preventDefault();
      return;
    }
    // console.log('dropOnWidget>index: ', index);
    // console.log('debug55>dropOnWidget');
    // toggleGrabbing(false);
    // console.log('debug55>dropOnWidget 1');
    setIsDraggingWidget(false);
    // console.log('debug55>dropOnWidget 2');
    e.preventDefault();
    // console.log('debug55>dropOnWidget 3');
    e.stopPropagation();
    // console.log('debug55>dropOnWidget 4');
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    // console.log('debug55>dropOnWidget 5');
    // console.log('debug55>droppedItemString: ', droppedItemString);
    const droppedItem = JSON.parse(droppedItemString);
    // console.log('debug55>dropOnWidget 6');
    // if (
    //   (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
    //   droppedItem.originRowIndex !== rowIndex
    // ) {
    //   return;
    // }
    let copyOfWidgets = [...widgets];
    const copyOfDroppedOnWidget = { ...widgets[index] };
    // console.log('debug55>droppedItem.origin: ', droppedItem.origin);
    // console.log('droppedItem.origin: ', droppedItem.origin);

    let indexToSplice = index;

    // console.log('value.category: ', value.category);

    switch (true) {
      case droppedItem?.value?.type === 'ERASE_ALL_TOOL':
        setWidgets([]);
        return;

      case droppedItem?.value?.type === 'FREEZE_DATA_TOOL' &&
        value.category === 'widgets':
        // console.log(
        //   "debug1456>droppedItem.value.category === 'dimensions' && value.category === 'widgets'"
        // );
        // console.log(
        //   'droppedItem.value.dimensions: ',
        //   droppedItem.value.dimensions
        // );
        copyOfWidgets[index] = {
          ...copyOfDroppedOnWidget,
          isDataFrozen: !copyOfDroppedOnWidget.isDataFrozen
        };
        setWidgets([...copyOfWidgets]);

        break;

        return;
      case value.category === 'widgets' &&
        droppedItem.value.category === 'widgets':
        // let copyOfWidgets = [...widgets];

        // console.log('droppedItem.origin: ', droppedItem.origin);
        if (droppedItem.origin === 'field_widgets') {
          // console.log('droppedItem.index: ', droppedItem.originIndex);
          copyOfWidgets.splice(droppedItem.originIndex, 1);

          // console.log('copyOfWidgets: ', copyOfWidgets);
        }
        // console.log('copyOfWidgets: ', copyOfWidgets);

        // console.log(
        //   "debug9946>value.style.left.split('px')[0]: ",
        //   value.style.left.split('px')[0]
        // );

        // console.log('debug9946>e.nativeEvent.offsetX: ', e.nativeEvent.offsetX);
        // console.log(
        //   "'debug9946>droppedItem.eNativeEventOffsetX: ",
        //   droppedItem.eNativeEventOffsetX
        // );
        // console.log(
        //   'debug9946>total: ',
        //   +(+value.style.left.split('px')[0]) +
        //     e.nativeEvent.offsetX -
        //     droppedItem.eNativeEventOffsetX
        // );

        setWidgets(
          [
            ...copyOfWidgets,
            {
              id: uuidv4(),
              ...droppedItem.value,
              left:
                Math.round(
                  (+(+value.left.split('px')[0]) +
                    (e.nativeEvent.offsetX - droppedItem.eNativeEventOffsetX) /
                      zoom) /
                    10
                ) *
                  10 +
                'px',
              top:
                Math.round(
                  (+value.top.split('px')[0] +
                    (e.nativeEvent.offsetY - droppedItem.eNativeEventOffsetY) /
                      zoom) /
                    10
                ) *
                  10 +
                'px'
            }
          ],
          selectedPageId,
          true
        );

        return;
      // if (droppedItem.origin === 'field_widgets') {
      //   console.log(
      //     "droppedItem.origin === 'field_widgets': ",
      //     droppedItem.origin === 'field_widgets'
      //   );
      //   console.log('droppedItem.index: ', droppedItem.originIndex);
      //   copyOfWidgets.splice(droppedItem.originIndex, 1);

      //   console.log('copyOfWidgets: ', copyOfWidgets);
      //   indexToSplice = droppedItem.originIndex < index ? index - 1 : index;
      // }
      // console.log('droppedItem.value: ', droppedItem.value);
      // console.log('newWidgets: ', [
      //   ...copyOfWidgets,
      //   {
      //     ...droppedItem.value,
      //     style: {
      //       ...droppedItem.value,
      //       left:
      //         Math.round(
      //           (e.nativeEvent.offsetX - droppedItem.eNativeEventOffsetX) / 10
      //         ) *
      //           10 +
      //         'px',
      //       top:
      //         Math.round(
      //           (e.nativeEvent.offsetY - droppedItem.eNativeEventOffsetY) / 10
      //         ) *
      //           10 +
      //         'px'
      //     }
      //   }
      // ]);
      // setWidgets([
      //   ...copyOfWidgets,
      //   {
      //     ...droppedItem.value,
      //     style: {
      //       ...droppedItem.value,
      //       left:
      //         Math.round(
      //           (e.nativeEvent.offsetX - droppedItem.eNativeEventOffsetX) / 10
      //         ) *
      //           10 +
      //         'px',
      //       top:
      //         Math.round(
      //           (e.nativeEvent.offsetY - droppedItem.eNativeEventOffsetY) / 10
      //         ) *
      //           10 +
      //         'px'
      //     }
      //   }
      // ]);

      // break;

      case droppedItem.value.category === 'metrics' &&
        value.category === 'widgets':
        // console.log('event123: ', e);

        const widgetWidth = copyOfWidgets[index].width.split('px')[0];
        // console.log('debug7245>widgetWidth: ', widgetWidth);
        // console.log('debug7245>e.nativeEvent.offsetX', e?.nativeEvent?.offsetX);
        // console.log('debug7245>e.nativeEvent: ', e.nativeEvent);

        const axis =
          e?.nativeEvent?.layerX < (widgetWidth * zoom) / 2
            ? 'leftAxisId'
            : 'rightAxisId';

        const widgetMetricsGroupedByAxis = Object.groupBy(
          value.metrics,
          (row) => row['axis']
        );

        if (
          widgetMetricsGroupedByAxis[axis]?.filter((metric) =>
            Boolean(metric?.dimensions?.length)
          ).length
        ) {
          enqueueSnackbar(
            'Max ONE metric on the same axis when using dimensions',
            {
              variant: 'error'
            }
          );
          return;
        }

        // console.log(
        //   "debug1456>droppedItem.value.category === 'metrics' && value.category === 'widgets'"
        // );

        let newMetrics = [...(copyOfWidgets[index]?.metrics || [])];

        if (
          ![
            'NUMBER_WIDGET',
            'TABLE_CHART_WIDGET',
            'COMBO_CHART_WIDGET'
          ].includes(value?.type) &&
          ![...sumEvalFields, ...plannedEvalFields]?.includes(
            droppedItem?.value?.metrics?.[0]?.metric
          )
        ) {
          enqueueSnackbar('Not supported', {
            variant: 'info',
            action
            // persist: true
          });
          return;
        }

        if (
          ![
            'LINE_CHART_WIDGET',
            'COMBO_CHART_WIDGET',
            'TABLE_CHART_WIDGET',
            'NUMBER_WIDGET'
          ].includes(value.type)
        ) {
          const unsupportedMetrics = (droppedItem?.value?.metrics || []).filter(
            (metric) => nonSumEvalFields.includes(metric)
          );

          if (unsupportedMetrics.length) {
            enqueueSnackbar('Not supported', {
              variant: 'info',
              action
              // persist: true
            });
            return;
          }
        }

        if (
          [
            'TABLE_CHART_WIDGET',
            'LINE_CHART_WIDGET',
            'COMBO_CHART_WIDGET'
          ].includes(copyOfWidgets[index]?.type)
        ) {
          for (let metricObj of droppedItem?.value?.metrics || []) {
            const index = newMetrics.findIndex(
              (obj) => obj.metric === metricObj.metric
            );
            if (index > -1) {
              newMetrics.splice(index, 1);
            } else {
              newMetrics.push({ ...metricObj, axis, id: uuidv4() });
            }
          }
        } else {
          newMetrics = [
            ...(droppedItem?.value?.metrics?.map((metricObj) => ({
              ...metricObj,
              id: uuidv4()
            })) || [])
          ];
        }

        // console.log('newMetrics: ', newMetrics);
        copyOfWidgets[index] = {
          ...copyOfDroppedOnWidget,
          title: droppedItem.value.title,
          metrics: newMetrics
          //  [
          //   ...(copyOfWidgets[index]?.metrics || []),
          //   ...(droppedItem?.value?.metrics || [])
          // ].filter((item, i, ar) => ar.indexOf(item) === i)
        };
        setWidgets([...copyOfWidgets], selectedPageId, true);
        // console.log('copyOfWidgets111: ', copyOfWidgets);

        break;

      case droppedItem.value.category === 'dimensions' &&
        value.category === 'widgets':
        // console.log(
        //   "debug1456>droppedItem.value.category === 'dimensions' && value.category === 'widgets'"
        // );
        // console.log(
        //   'droppedItem.value.dimensions: ',
        //   droppedItem.value.dimensions
        // );
        if (
          ![
            'LINE_CHART_WIDGET',
            'COMBO_CHART_WIDGET'
            // 'TABLE_CHART_WIDGET',
            // 'NUMBER_WIDGET'
          ].includes(value.type)
        ) {
          copyOfWidgets[index] = {
            ...copyOfDroppedOnWidget,
            dimensions: droppedItem.value.dimensions
          };
          setWidgets([...copyOfWidgets]);
        }

        break;
      // case droppedItem.value.category === 'functions' &&
      //   value.category === 'widgets':
      //   if (
      //     ![
      //       'LINE_CHART_WIDGET',
      //       'COMBO_CHART_WIDGET'
      //       // 'TABLE_CHART_WIDGET',
      //       // 'NUMBER_WIDGET'
      //     ].includes(value.type)
      //   ) {
      //     enqueueSnackbar('Not supported', {
      //       variant: 'info',
      //       action
      //       // persist: true
      //     });
      //     return;
      //   }

      //   copyOfWidgets[index] = {
      //     ...copyOfDroppedOnWidget,
      //     function: droppedItem.value.function
      //   };
      //   setWidgets([...copyOfWidgets]);

      //   break;

      case droppedItem.value.category === 'intervals' &&
        value.category === 'widgets':
        if (
          ![
            'LINE_CHART_WIDGET',
            'COMBO_CHART_WIDGET'
            // 'TABLE_CHART_WIDGET',
            // 'NUMBER_WIDGET'
          ].includes(value.type)
        ) {
          enqueueSnackbar('Not supported', {
            variant: 'info',
            action
            // persist: true
          });
          return;
        }
        // console.log(
        //   "debug1456>droppedItem.value.category === 'dimensions' && value.category === 'widgets'"
        // );
        // console.log(
        //   'droppedItem.value.dimensions: ',
        //   droppedItem.value.dimensions
        // );
        copyOfWidgets[index] = {
          ...copyOfDroppedOnWidget,
          interval: droppedItem.value.interval
        };
        setWidgets([...copyOfWidgets]);

        break;

      default:
        return;
    }

    // copyOfWidgets.splice(indexToSplice, 0, droppedItem.value);

    // setWidgets(copyOfWidgets);
    // console.log('droppedItem1: ', droppedItem);

    const droppedItemElement = document.getElementById(droppedItem);
  };

  // #################################################################
  // ################# BACKGROUND IMAGE ##############################
  // #################################################################

  const inputRef = useRef(null);

  const [dragActive, setDragActive] = React.useState(false);

  const parseFile = async (e, file) => {
    let copyOfReportPage = { ...reportPage };

    var isFileTransfer = false;
    if (
      isFileTransfer &&
      !['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    ) {
      enqueueSnackbar('FILE TYPE NOT SUPPORTED. PLEASE CONTACT SUPPORT', {
        variant: 'error'
      });
      return;
    }
    if (file) {
      e.stopPropagation();

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async function () {
        // console.log('img.naturalHeight: ', img.naturalHeight);
        // setImgNaturalHeight(this.naturalHeight);
        // setImgNaturalWidth(this.naturalWidth);

        copyOfReportPage = {
          ...copyOfReportPage,
          imageNaturalHeight: img.naturalHeight,
          imageNaturalWidth: img.naturalWidth,
          imageNaturalAspectRatio: img.naturalWidth / img.naturalHeight
        };

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataUri = canvas.toDataURL('image/png');

        // console.log('dataURL: ', dataUri);

        copyOfReportPage.backgroundImageDataUri = dataUri;
        delete copyOfReportPage.imageAssetId;

        updateReportPage(copyOfReportPage);
      };

      setDragActive(false);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // handleFiles(e.target.files);

      parseFile(e, file);
    }
  };

  const onButtonClick = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    if (
      Boolean(inputImageTriggerCounter) &
      (String(reportPage?.id) === String(widgetIdThatTriggeredContextMenu))
    ) {
      onButtonClick();
      setInputImageTriggerCounter(0);
    }
  }, [inputImageTriggerCounter]);

  // #################################################################
  // #################################################################
  // #################################################################
  // console.log('widgets: ', widgets);

  // console.log('ReportBuilderDialog><zoom: ', zoom);

  // const dropOnDelete = (e) => {
  //   // e.preventDefault();
  //   // e.stopPropagation();
  //   console.log('dropOnDelete>1');

  //   console.log('dropOnDelete>3');
  //   setIsDraggingWidget(false);

  //   e.preventDefault();

  //   e.stopPropagation();

  //   const droppedItemString = e.dataTransfer.getData('droppedItem');

  //   const droppedItem = JSON.parse(droppedItemString);

  //   if (droppedItem.origin !== 'item_widgets') {
  //     console.log('dropOnDelete>2');
  //     return;
  //   }

  //   let copyOfWidgets = [...widgets];
  //   console.log('debug55>droppedItem.origin: ', droppedItem.origin);

  //   console.log('droppedItem.index: ', droppedItem.originIndex);
  //   copyOfWidgets.splice(droppedItem.originIndex, 1);

  //   console.log('copyOfWidgets: ', copyOfWidgets);
  //   // let indexToSplice = droppedItem.originIndex < index ? index - 1 : index;
  //   // copyOfWidgets.splice(indexToSplice, 0, droppedItem.value);

  //   setWidgets(copyOfWidgets);
  //   console.log('droppedItem1: ', droppedItem);
  // };

  // console.log(
  //   'test9944: ',
  //   reportBuilderFieldStyle.width.split('px') / 10
  // );

  const cssPatternColor = 'rgba(200,200,200,0.1)';

  let showSupportGrid = ['widgets'].includes(currentlyDraggingWidget?.category);

  const [filter, setFilter] = useState('');
  useEffect(() => {
    let _filter = '';

    const filters = [
      'backgroundImageGrayscaleFilter',
      'backgroundImageBlurFilter',
      'backgroundImageBrightnessFilter',
      'backgroundImageContrastFilter',
      'backgroundImageHueRotateFilter',
      'backgroundImageInvertFilter',
      'backgroundImageOpacityFilter',
      'backgroundImageSaturateFilter',
      'backgroundImageSepiaFilter'
    ];
    for (let filterKey of filters) {
      if (reportPage?.[filterKey] === undefined) {
        console.log('filterKey: ', filterKey);
        continue;
      }
      // console.log('filterKey: ', filterKey);
      _filter = _filter + ' ';
      switch (filterKey) {
        case 'backgroundImageGrayscaleFilter': // grayscale(50%)
          _filter = _filter + `grayscale(${reportPage?.[filterKey] * 100}%)`;
          break;

        case 'backgroundImageBlurFilter': // blur(5px)
          _filter = _filter + `blur(${reportPage?.[filterKey]}px)`;
          break;

        case 'backgroundImageBrightnessFilter': // brightness(0.4)
          _filter = _filter + `brightness(${reportPage?.[filterKey]})`;
          break;

        case 'backgroundImageContrastFilter': //contrast(200%)
          _filter = _filter + `contrast(${reportPage?.[filterKey] * 100}%)`;
          break;

        case 'backgroundImageHueRotateFilter': // hue-rotate(90deg)
          _filter = _filter + `hue-rotate(${reportPage?.[filterKey]}deg)`;
          break;

        case 'backgroundImageInvertFilter': // invert(75%)
          _filter = _filter + `invert(${reportPage?.[filterKey] * 100}%)`;
          break;

        case 'backgroundImageOpacityFilter': // opacity(25%)
          _filter = _filter + `opacity(${reportPage?.[filterKey] * 100}%)`;
          break;

        case 'backgroundImageSaturateFilter': // saturate(30%)
          _filter = _filter + `saturate(${reportPage?.[filterKey] * 100}%)`;
          break;

        case 'backgroundImageSepiaFilter': // sepia(60%)
          _filter = _filter + `sepia(${reportPage?.[filterKey] * 100}%)`;
          break;
      }
    }

    _filter.slice(1);
    setFilter(_filter);
    console.log('filter: ', filter);
  }, [
    reportPage?.['backgroundImageGrayscaleFilter'],
    reportPage?.['backgroundImageBlurFilter'],
    reportPage?.['backgroundImageBrightnessFilter'],
    reportPage?.['backgroundImageContrastFilter'],
    reportPage?.['backgroundImageHueRotateFilter'],
    reportPage?.['backgroundImageInvertFilter'],
    reportPage?.['backgroundImageOpacityFilter'],
    reportPage?.['backgroundImageSaturateFilter'],
    reportPage?.['backgroundImageSepiaFilter']
  ]);

  return (
    <div
      style={{
        overflow: 'scroll',
        height: '100%',
        width: '100%'
      }}
    >
      <div
        // id="report-builder-page-parent-div"
        id="report-builder-outside-page"
        onClick={(e) => {
          // console.log('e: ', e);
          if (e?.target?.id === 'report-builder-outside-page') {
            // e.stopPropagation();
            // e.preventDefault();
            setSelectedWidgetIds([]);
          }
        }}
        style={{
          zIndex: '1000',
          // background: 'red',
          minWidth: '100%',
          minHeight: '99%',

          width: reportPage?.width?.split('px')?.[0] * 1.1, // '100%',
          height: reportPage?.height?.split('px')?.[0] * 1.1
        }}
        onMouseUp={onMouseUpHandler}
        onMouseMove={updateMousePosition}
        // onDrop={(e) => dropOnPage(e, item, item)}
        onDragOver={(e) => dragOver(e)}
      >
        <div
          id="report-builder-page-parent-div"
          // onMouseUp={onMouseUpHandler}
          // onMouseMove={updateMousePosition}
          // // onDrop={(e) => dropOnPage(e, item, item)}
          // onDragOver={(e) => dragOver(e)}
          // draggable={true}
          // onDragStart={(e) => dragStart(e, row, 'taxonomy_table', rowIndex)}
          // onDragEnd={(e) => dragEnd(e, row, rowIndex)}
          style={{
            // zIndex: 5000,
            // position: 'absolute',
            // transform: `scale(${transformScale})`,
            // transformOrigin: '0% 0%',

            zoom: zoom,
            width: '100%',
            height: '100%',
            outline: '1px solid rgba(255,255,255,0.3)',
            // backgroundPosition: 'center center',
            // backgroundImage:
            //   'linear-gradient(90deg, rgba(120, 120, 120, 0.3) 1px, rgba(229, 229, 247,0) 1px)',

            backgroundColor: reportPage?.backgroundColor,
            // backgroundImage:
            //   (showSupportGrid ||
            //     isResizingWidget ||
            //     (isMouseDown && selectedWidgetIds.length > 0)) &&
            //   `linear-gradient(${cssPatternColor} 10px, transparent 10px), linear-gradient(90deg, ${cssPatternColor} 10px, transparent 10px)`,

            // // backgroundSize: 201 + 'px',

            // backgroundSize:
            //   (showSupportGrid ||
            //     isResizingWidget ||
            //     (isMouseDown && selectedWidgetIds.length > 0)) &&
            //   '210px 210px, 210px 210px',
            // backgroundPosition:
            //   (showSupportGrid ||
            //     isResizingWidget ||
            //     (isMouseDown && selectedWidgetIds.length > 0)) &&
            //   +reportPage?.width?.split('px')?.[0] / 2 +
            //     210 +
            //     'px ' +
            //     reportPage?.height?.split('px')?.[0] / 2 +
            //     210 +
            //     'px',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            // columnGap: '3px',

            alignItems: 'center',

            fontSize: '12px',

            height: reportPage?.height, // '800px',
            width: reportPage?.width, // '800px',
            // minWidth: '1200px',

            whiteSpace: 'nowrap',
            // background:
            //   theme?.palette?.mode === 'dark'
            //     ? 'linear-gradient(to top, rgba(50, 48, 58,1.0), rgba(65, 63, 77))' //'rgba(255,255,255,0.1)' // 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))' //'rgba(255,255,255,0.1)'
            //     : 'rgba(255,255,255,0.6)',
            // background:
            //   theme?.palette?.mode === 'light' && 'rgba(255,255,255,1)',
            background: 'white',
            // background: 'red',
            borderRadius: '5px',

            backdropFilter: 'blur(10px)',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
            marginTop: '10px',
            marginLeft: '10px'
          }}
        >
          <div
            id={'report-page-background-image-div-' + reportPage.id}
            style={{
              height: reportPage?.height, // '800px',
              width: reportPage?.width, // '800px',
              position: 'absolute',
              filter: filter,
              // opacity:
              //   showSupportGrid || isMouseDown
              //     ? 0.4
              //     : reportPage?.backgroundOpacity,
              backgroundImage: `url(${
                reportPage?.backgroundImageDataUri ||
                reportPage?.backgroundImageUrl
              })`,
              // backgroundColor: reportPage?.backgroundColor,
              // filter: reportPage?.isGrayscale && 'grayscale(1)',
              backgroundSize: 'cover',
              // border-radius: 50%;
              // border: solid 1px #e6e6e6;
              margin: '0 auto',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '5px'
              // position: 'relative'
            }}
          ></div>
          <div
            style={{
              height: reportPage?.height, // '800px',
              width: reportPage?.width, // '800px',
              position: 'absolute',
              // filter: filter,
              // opacity:
              //   showSupportGrid || isMouseDown
              //     ? 0.4
              //     : reportPage?.backgroundOpacity,
              // backgroundImage: `url(${
              //   reportPage?.backgroundImageDataUri ||
              //   reportPage?.backgroundImageUrl
              // })`,
              // backgroundColor: reportPage?.backgroundColor,
              // filter: reportPage?.isGrayscale && 'grayscale(1)',
              backgroundSize: 'cover',
              // border-radius: 50%;
              // border: solid 1px #e6e6e6;
              margin: '0 auto',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // borderRadius: '5px',
              backgroundImage:
                (showSupportGrid ||
                  isResizingWidget ||
                  (isMouseDown && selectedWidgetIds.length > 0)) &&
                `linear-gradient(${cssPatternColor} 10px, transparent 10px), linear-gradient(90deg, ${cssPatternColor} 10px, transparent 10px)`,

              // backgroundSize: 201 + 'px',

              backgroundSize:
                (showSupportGrid ||
                  isResizingWidget ||
                  (isMouseDown && selectedWidgetIds.length > 0)) &&
                '210px 210px, 210px 210px',
              backgroundPosition:
                (showSupportGrid ||
                  isResizingWidget ||
                  (isMouseDown && selectedWidgetIds.length > 0)) &&
                +reportPage?.width?.split('px')?.[0] / 2 +
                  210 +
                  'px ' +
                  reportPage?.height?.split('px')?.[0] / 2 +
                  210 +
                  'px'
            }}
          ></div>
          <div
            onContextMenu={(e) => {
              e.stopPropagation();
              e.preventDefault();

              setWidgetIdThatTriggeredContextMenu(reportPage.id);
              setWidgetElementThatTriggeredContextMenu('report-builder-page');
              setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
            }}
            onClick={(e) => {
              // console.log('e: ', e);
              if (e?.target?.id === 'report-builder-page') {
                // e.stopPropagation();
                // e.preventDefault();
                setSelectedWidgetIds([]);
              }
            }}
            id="report-builder-page"
            onDrop={(e) => dropOnPage(e)}
            // onDragOver={(e) => dragOver(e, item)}
            style={{
              zIndex: 30000,
              // background: 'red',
              height: '100%',
              // background: 'green',
              // display: 'contents',
              // flexDirection: 'row',
              // justifyContent: 'start',
              // background: 'red',
              width: '100%'
              // minWidth: '58vw'
              // padding: '12px 15px'

              // opacity: isMouseDown ? 0.2 : reportPage?.imageOpacity,
              // backgroundImage: `url(${
              //   reportPage?.backgroundImageDataUri ||
              //   reportPage?.backgroundImageUrl
              // })`,

              // backgroundSize: 'cover',
              // // border-radius: 50%;
              // // border: solid 1px #e6e6e6;
              // margin: '0 auto',
              // overflow: 'hidden',
              // display: 'flex',
              // alignItems: 'center',
              // justifyContent: 'center',
              // position: 'relative'
            }}
          >
            {(widgets || []).map((widget, index) => {
              let component = <div></div>;
              switch (widget?.type) {
                case 'IMAGE_WIDGET':
                  component = (
                    <ImageWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'MOCKUP_WIDGET':
                  component = (
                    <MockupWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'MANAGER_ACCOUNT_LOGO_WIDGET':
                  component = (
                    <LogoWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;

                case 'DATE_PICKER_WIDGET':
                  component = (
                    <DatePickerWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      reportPage={reportPage}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'TEXT_WIDGET':
                  component = (
                    <TextWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'NUMBER_WIDGET':
                  component = (
                    <NumberWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      reportPage={reportPage}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'LINE_CHART_WIDGET':
                  component = (
                    <LineChartWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      reportPage={reportPage}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'COMBO_CHART_WIDGET':
                  component = (
                    <ComboChartWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      reportPage={reportPage}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'PIE_CHART_WIDGET':
                  component = (
                    <PieChartWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      reportPage={reportPage}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'TABLE_CHART_WIDGET':
                  component = (
                    <TableChartWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      reportPage={reportPage}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
                case 'RECTANGLE_SHAPE_WIDGET':
                  component = (
                    <RectangleShapeWidget
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      reportPage={reportPage}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    />
                  );
                  break;
              }
              return (
                <WidgetWrapper
                  onMouseUpHandler={onMouseUpHandler}
                  mouseDownWidgetId={mouseDownWidgetId}
                  setMouseDownWidgetId={setMouseDownWidgetId}
                  mouseDownWidgetIndex={mouseDownWidgetIndex}
                  setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                  reportPage={reportPage}
                  widget={widget}
                  widgetIndex={index}
                  widgets={widgets}
                  setWidgets={setWidgets}
                  updateWidget={updateWidget}
                  // displayMode={displayMode}
                  // editMode={editMode}
                  dropOnWidget={dropOnWidget}
                  dragOver={dragOver}
                  dragStart={dragStart}
                  dragEnd={dragEnd}
                  setIsDraggingWidget={setIsDraggingWidget}
                  isDraggingWidget={isDraggingWidget}
                  isResizingWidget={isResizingWidget}
                  setIsResizingWidget={setIsResizingWidget}
                  onMouseDownHandler={onMouseDownHandler}
                  zoom={zoom}
                >
                  {component}
                </WidgetWrapper>
              );
              switch (widget?.type) {
                case 'TEXT':
                  return (
                    <WidgetWrapper
                      onMouseUpHandler={onMouseUpHandler}
                      mouseDownWidgetId={mouseDownWidgetId}
                      setMouseDownWidgetId={setMouseDownWidgetId}
                      mouseDownWidgetIndex={mouseDownWidgetIndex}
                      setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                      widget={widget}
                      widgetIndex={index}
                      widgets={widgets}
                      setWidgets={setWidgets}
                      updateWidget={updateWidget}
                      // displayMode={displayMode}
                      // editMode={editMode}
                      dropOnWidget={dropOnWidget}
                      dragOver={dragOver}
                      dragStart={dragStart}
                      dragEnd={dragEnd}
                      setIsDraggingWidget={setIsDraggingWidget}
                      isDraggingWidget={isDraggingWidget}
                      onMouseDownHandler={onMouseDownHandler}
                    >
                      <span>test</span>
                    </WidgetWrapper>
                  );
                // case 'STATIC_TEXT':
                //   return (
                //     <TextWidget
                //       widget={widget}
                //       widgetIndex={index}
                //       widgets={widgets}
                //       setWidgets={setWidgets}
                //       updateWidget={updateWidget}
                //       displayMode={displayMode}
                //       editMode={editMode}
                //       dropOnWidget={dropOnWidget}
                //       dragOver={dragOver}
                //       dragStart={dragStart}
                //       dragEnd={dragEnd}
                //       setIsDraggingWidget={setIsDraggingWidget}
                //       isDraggingWidget={isDraggingWidget}
                //     />
                //   );

                // case 'YEAR_YYYY':
                // case 'YEAR_YY':
                // case 'MONTH_MM':
                // case 'DAY_DD':
                //   return (
                //     <DateWidget
                //       widget={widget}
                //       widgetIndex={index}
                //       widgets={widgets}
                //       setWidgets={setWidgets}
                //       updateWidget={updateWidget}
                //       displayMode={displayMode}
                //       editMode={editMode}
                //       dropOnWidget={dropOnWidget}
                //       dragOver={dragOver}
                //       dragStart={dragStart}
                //       dragEnd={dragEnd}
                //       setIsDraggingWidget={setIsDraggingWidget}
                //       isDraggingWidget={isDraggingWidget}
                //     />
                //   );

                // case 'LIST':
                //   return (
                //     // <div
                //     //   onDrop={(e) => dropOnWidget(e, widget, index)}
                //     //   onDragOver={(e) => dragOver(e, widget, index)}
                //     //   draggable={true}
                //     //   onDragStart={(e) =>
                //     //     dragStart(e, widget, 'item_widgets', index)
                //     //   }
                //     //   onDragEnd={(e) => dragEnd(e, widget, index)}
                //     //   style={{ paddingLeft: '3px' }}
                //     // >
                //     //   <div
                //     //     style={{
                //     //       padding: '3px',
                //     //       color: 'black',
                //     //       background: 'white',
                //     //       // border: '1px solid lightgrey',
                //     //       display: 'inline-block',
                //     //       borderRadius: '5px',
                //     //       margin: '0px 0.5px',
                //     //       transform: 'translate(0, 0)',
                //     //       boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
                //     //     }}
                //     //   >
                //     <ListWidget
                //       numberOfReset={numberOfReset}
                //       widget={widget}
                //       widgetIndex={index}
                //       widgets={widgets}
                //       setWidgets={setWidgets}
                //       updateWidget={updateWidget}
                //       displayMode={displayMode}
                //       editMode={editMode}
                //       dropOnWidget={dropOnWidget}
                //       dragOver={dragOver}
                //       dragStart={dragStart}
                //       dragEnd={dragEnd}
                //       setIsDraggingWidget={setIsDraggingWidget}
                //       isDraggingWidget={isDraggingWidget}
                //     />
                //     //   </div>

                //     // </div>
                //   );
              }
            })}

            {reportPage?.layout === 'CUSTOM' && (
              <div
                className={
                  theme?.palette?.mode === 'dark'
                    ? 'timebox-resize-border-dark'
                    : 'timebox-resize-border-light'
                }
                style={{
                  width: '15px',
                  height: '15px',
                  // background: 'green',
                  zIndex: 50000,
                  // cursor:
                  //   widget?.isMutable &&
                  //   widget?.type !== 'outlookCalendarEvent' &&
                  //   'ns-resize',
                  cursor: 'nwse-resize',
                  position: 'absolute',
                  bottom: '0px',
                  right: '0px'
                }}
                onMouseDown={(e) => {
                  // setMouseDownWidgetIndex(widgetIndex);
                  // if (!timebox.isMutable) {
                  //   return;
                  // }
                  //   setOriginalTimebox({ ...timebox });
                  e.stopPropagation();
                  e.preventDefault();
                  // if (timebox.type === 'outlookCalendarEvent' || altHeld) {
                  //   return;
                  // }
                  onMouseDownHandler(e, null, 'resize_page');
                }}
                // onMouseUp={(e) => {
                //   e.stopPropagation();
                //   console.log('debug30>Timebox>timebox._id: ', timebox._id);
                //   onMouseUpHandler(e, 'timebox', timebox._id);
                // }}
                // onMouseMove={updateMousePosition}
              ></div>
            )}
          </div>

          {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '12px 15px'
          }}
        > */}
          {/* {editMode ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title="Location">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  // onClick={() => {
                  //   console.log('reset1234');
                  //   setTaxonomyRowObj({ ...item });
                  // }}
                >
                  <EditLocationIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Drop widget here to delete">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'default' }}
                  onDrop={(e) => dropOnDelete(e)}
                >
                  <DeleteIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  style={{
                    width: '40px',
                    minWidth: '40px',
                    color: 'red',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    // setTaxonomyRowObj({ ...item });
                    closeEditMode();
                    // setEditMode(false);
                    // setIsEditSettingsMode(false);
                  }}
                >
                  <CancelIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Save settings">
                <Button
                  style={{
                    width: '40px',
                    minWidth: '40px',
                    color: 'green',
                    cursor: 'pointer'
                  }}
                  onClick={onSaveHandler}
                >
                  <SaveIcon />
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title="Copy taxonomy string">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'copy' }}
                  onClick={onContentCopyHandler}
                >
                  <ContentCopyIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Reset">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  onClick={() => {
                    console.log('reset1234');
                    setNumberOfReset(numberOfReset + 1);
                    // setTaxonomyRowObj({ ...item });
                  }}
                >
                  <RestartAltIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="History">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'zoom-in' }}
                  // onClick={() => {
                  //   console.log('reset1234');
                  //   setTaxonomyRowObj({ ...item });
                  // }}
                >
                  <WorkIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Location">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  // onClick={() => {
                  //   console.log('reset1234');
                  //   setTaxonomyRowObj({ ...item });
                  // }}
                >
                  <LocationOnIcon style={{}} />
                </Button>
              </Tooltip>

              <Tooltip title="Settings">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  onClick={() => {
                    onTaxonomyEditHandler();
                  }}
                >
                  <SettingsIcon style={{}} />
                </Button>
              </Tooltip>
            </div>
          )} */}
          {/* </div> */}
        </div>
        {displayError && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end'
            }}
          >
            <div
              style={{
                background: 'red',
                height: '20px',
                width: '50px',
                borderRadius: '0px 0px 5px 5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '10px',
                marginRight: '20px',
                color: 'white'
              }}
            >
              Error!
            </div>
          </div>
        )}

        {/* <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '10px',
            marginTop: '15px'
          }}
        >
          <Button
            disableRipple
            size="small"
            style={{
              // width: '100%',
              fontSize: '10px',
              position: 'inherit'
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disableRipple
            size="small"
            style={{
              // width: '100%',
              background: 'rgba(0,255,0,0.7)',
              fontSize: '10px',
              position: 'inherit',
              color: 'black'
            }}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </div> */}
      </div>
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleChange(e);
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
}

export default ReportBuilderPage;
