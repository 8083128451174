import React, { useState, useEffect, useContext, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { AccountContext } from '../../../../../context/AccountContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import { SnackbarProvider, useSnackbar } from 'notistack';

function ImageWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler,
  // widgetType,
  dropOnWidget
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { currentManagerAccount } = useContext(AccountContext);
  const theme = useTheme();

  const {
    // widgets,
    // setWidgets,
    widgetIdThatTriggeredContextMenu,
    inputImageTriggerCounter,
    setInputImageTriggerCounter
    // updateWidget,
    // isAxisMenuOpen,
    // toggleIsAxisSettingsOpen
  } = useContext(ReportBuilderContext);

  const inputRef = useRef(null);

  // const logoUrl =
  //   currentManagerAccount?.logo ||
  //   'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

  // console.log('logoUrl: ', logoUrl);

  // const [imageUrl, setImageUrl] = useState();

  const [dragActive, setDragActive] = React.useState(false);

  // useEffect(() => {
  //   console.log(`widgetId: ${widget.id}, imageUrl: ', ${imageUrl}`);
  // }, [imageUrl]);

  const [imageNaturalHeight, setImgNaturalHeight] = useState();
  const [imageNaturalWidth, setImgNaturalWidth] = useState();
  // useEffect(() => {
  //   // if (!imageUrl && widget?.imageUrl) {
  //   //   // setImageUrl(widget.imageUrl);
  //   // }
  //   setImageUrl(widget.imageUrl);
  // }, [widget.imageUrl]);

  const parseFile = async (e, file) => {
    let copyOfWidget = { ...widget };

    var isFileTransfer = false;
    if (
      isFileTransfer &&
      !['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    ) {
      enqueueSnackbar('FILE TYPE NOT SUPPORTED. PLEASE CONTACT SUPPORT', {
        variant: 'error'
      });
      return;
    }
    if (file) {
      e.stopPropagation();

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async function () {
        // console.log('img.naturalHeight: ', img.naturalHeight);
        setImgNaturalHeight(this.naturalHeight);
        setImgNaturalWidth(this.naturalWidth);

        copyOfWidget = {
          ...copyOfWidget,
          imageNaturalHeight: img.naturalHeight,
          imageNaturalWidth: img.naturalWidth,
          imageNaturalAspectRatio: img.naturalWidth / img.naturalHeight
        };

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataUri = canvas.toDataURL('image/png');

        // console.log('dataURL: ', dataUri);

        copyOfWidget.imageDataUri = dataUri;
        delete copyOfWidget.imageAssetId;

        updateWidget(copyOfWidget, widgetIndex);
      };

      setDragActive(false);
    }
  };

  const onDropHandler = async (e) => {
    e.preventDefault();

    // var isFileTransfer = false;
    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    const file = e?.dataTransfer?.files[0];
    console.log('file: ', file);
    parseFile(e, file);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    console.log('e12333:', e);

    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    if (!isFileTransfer) {
      return;
    }
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // handleFiles(e.target.files);

      parseFile(e, file);
    }
  };

  const onButtonClick = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    if (
      Boolean(inputImageTriggerCounter) &
      (String(widget?.id) === String(widgetIdThatTriggeredContextMenu))
    ) {
      onButtonClick();
      setInputImageTriggerCounter(0);
    }
  }, [inputImageTriggerCounter]);

  let cssFilter = '';

  if (widget.isDropShadowOn) {
    cssFilter = 'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2))';
  }

  // if (widget.isGrayscale) {
  //   cssFilter = cssFilter + (widget.isDropShadowOn ? ' ' : '') + 'grayscale(1)';
  // }

  const [filter, setFilter] = useState('');
  useEffect(() => {
    let _filter = '';

    const filters = [
      'imageGrayscaleFilter',
      'imageBlurFilter',
      'imageBrightnessFilter',
      'imageContrastFilter',
      'imageHueRotateFilter',
      'imageInvertFilter',
      'imageOpacityFilter',
      'imageSaturateFilter',
      'imageSepiaFilter'
    ];
    for (let filterKey of filters) {
      if (widget?.[filterKey] === undefined) {
        console.log('filterKey: ', filterKey);
        continue;
      }
      // console.log('filterKey: ', filterKey);
      _filter = _filter + ' ';
      switch (filterKey) {
        case 'imageGrayscaleFilter': // grayscale(50%)
          _filter = _filter + `grayscale(${widget?.[filterKey] * 100}%)`;
          break;

        case 'imageBlurFilter': // blur(5px)
          _filter = _filter + `blur(${widget?.[filterKey]}px)`;
          break;

        case 'imageBrightnessFilter': // brightness(0.4)
          _filter = _filter + `brightness(${widget?.[filterKey]})`;
          break;

        case 'imageContrastFilter': //contrast(200%)
          _filter = _filter + `contrast(${widget?.[filterKey] * 100}%)`;
          break;

        case 'imageHueRotateFilter': // hue-rotate(90deg)
          _filter = _filter + `hue-rotate(${widget?.[filterKey]}deg)`;
          break;

        case 'imageInvertFilter': // invert(75%)
          _filter = _filter + `invert(${widget?.[filterKey] * 100}%)`;
          break;

        case 'imageOpacityFilter': // opacity(25%)
          _filter = _filter + `opacity(${widget?.[filterKey] * 100}%)`;
          break;

        case 'imageSaturateFilter': // saturate(30%)
          _filter = _filter + `saturate(${widget?.[filterKey] * 100}%)`;
          break;

        case 'imageSepiaFilter': // sepia(60%)
          _filter = _filter + `sepia(${widget?.[filterKey] * 100}%)`;
          break;
      }
    }

    if (widget.isDropShadowOn) {
      _filter = _filter + ' drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2))';
    }

    _filter.slice(1);
    setFilter(_filter);
    console.log('filter: ', filter);
  }, [
    widget?.['imageGrayscaleFilter'],
    widget?.['imageBlurFilter'],
    widget?.['imageBrightnessFilter'],
    widget?.['imageContrastFilter'],
    widget?.['imageHueRotateFilter'],
    widget?.['imageInvertFilter'],
    widget?.['imageOpacityFilter'],
    widget?.['imageSaturateFilter'],
    widget?.['imageSepiaFilter']
  ]);

  // console.log('widget: ', widget);
  return (
    <div
      key={widget.id}
      onDrop={(e) => {
        // console.log('widget12312312: ', widget);
        e.preventDefault();
        // e.stopPropagation();
        // dropOnWidget(e, widget, widgetIndex);
      }}
      onMouseUp={onMouseUpHandler}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {dragActive || !widget?.imageDataUri ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={(e) => onDropHandler(e)}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              onClick={(e) => {
                onButtonClick(e);
              }}
              style={{
                color: 'black',
                cursor: 'pointer',
                // background: 'red',
                height: '70%',
                width: '70%',
                minWidth: '100px',
                minHeight: '50px',
                border: '1px dashed lightgrey',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '5px'
              }}
            >
              DROP IMAGE HERE <FileUploadIcon />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id={`imageWidget-background-color-div-${widget.id}`}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: widget.imageBackgroundColor,
              filter: `opacity(${widget.imageBackgroundColorOpacity * 100}%)`
            }}
          ></div>
          <div
            id={`imageWidget-${widget.id}`}
            // onClick={(e) => {
            //   onButtonClick(e);
            // }}
            onDragEnter={handleDrag}
            onDrop={(e) => onDropHandler(e)}
            draggable={false}
            style={{
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              // border-radius: 50%;
              // border: solid 1px #e6e6e6;
              margin: '0 auto',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              // img: {
              //   height: '100%',
              //   width: '100%'
              // }
              height: '100%',
              opacity: widget.imageOpacity,

              backgroundImage: `url(${widget.imageDataUri || widget.imageUrl})`,
              filter: filter
            }}
          >
            {/* <img
            onDragEnter={handleDrag}
            onDrop={(e) => onDropHandler(e)}
            draggable={false}
            src={imageUrl}
            alt="Logo"
            style={{
              borderRadius: '5px',
              // display: 'block'
              // height: '100%',
              height: imageNaturalHeight,
              width: imageNaturalWidth
              // objectFit: 'cover',
              // overflow: 'hidden'
              // opacity: 0.5
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          /> */}
          </div>
        </>
      )}
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleChange(e);
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
}

export default React.memo(ImageWidget);
