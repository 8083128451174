import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

function DatePickerWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const {
    selectedDateStart,
    setSelectedDateStart,
    selectedDateStop,
    setSelectedDateStop
  } = useContext(InterfaceContext);
  const [textInput, setTextInput] = useState('');

  const [valueIsEdited, setValueIsEdited] = useState(false);
  const [edit, toggleEdit] = useState(false);

  useEffect(() => {
    if (textInput) {
      setValueIsEdited(true);
    }
    setTextInput(widget.value);
  }, [widget.value]);
  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  return (
    <div
      key={widget.id}
      // onMouseUpHandler={onMouseUpHandler}
      // onClick={() => {
      //   toggleEdit(true);
      // }}
      // onBlur={(e) => onSaveHandler(e)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      {edit ? (
        <textarea
          autoFocus
          style={{
            // padding: '10px',
            fontFamily: 'Roboto, Raleway, sans-serif',
            lineHeight: '1.5',
            fontSize: '12px',
            letterSpacing: '0.2px',
            color: 'black',
            // width: textInput.length * 1 + 'ch',
            // width:
            //   numUpper * 0.35 + numLarge * 1 + textInput.length * 0.925 + 'ch',
            // minWidth: '1ch',
            width: widget?.width,
            height: widget?.height,
            outline: 'none',
            border: 'none'
            // fontSize: '11.5px'
          }}
          value={textInput}
          // onChange={onChangeHandler}
          // onKeyDown={handleKeyDown}
        />
      ) : (
        // <Tooltip
        //   title={
        //     <div
        //       style={{
        //         display: 'flex',
        //         flexDirection: 'column',
        //         rowGap: '5px',
        //         maxWidth: '200px'
        //         // padding: '2px'
        //       }}
        //     >
        //       {/* <span style={{ width: '100%', textAlign: 'center' }}>
        //         {widget.type === 'FREETEXT'
        //           ? '* Free text *'
        //           : '* Static text *'}
        //       </span> */}

        //       <span
        //         style={{
        //           maxWidth: '190px',
        //           whiteSpace: 'pre-line'
        //           // paddingRight: '10px'
        //           // padding: '5px'
        //         }}
        //       >
        //         {widget.description}
        //       </span>
        //     </div>
        //   }
        // >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            // fontWeight: 'bold',
            // fontSize: '14px',

            fontFamily: widget.textFontFamily,
            fontSize: widget.textFontSize,
            fontStyle: widget.isTextFontItalic && 'italic',
            fontWeight: widget.isTextFontBold && 'bold',
            color: widget.textFontColor,
            // padding: '10px',
            // width: '100%',
            // lineBreak: 'anywhere',
            width: widget?.width,
            height: widget?.height,
            // maxWidth: widget?.width,
            maxHeight: widget?.height,
            // width: '100%',
            //   background: 'red',
            // top: timebox?.duration <= 5 && '-5px',
            // position: timebox?.duration <= 5 && 'relative',
            // lineHeight: timebox?.duration <= 5 && '3px',
            // padding:
            //   timebox?.duration <= 10 ? '3px 9px 0px 9px' : '9px 9px 3px 9px',
            whiteSpace: 'pre-wrap'
            // overflowY: 'scroll'
          }}
          // onMouseDown={() => setIsDraggingWidget(true)}
          // onMouseUp={() => setIsDraggingWidget(false)}
        >
          {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
          <span>{widget?.value}</span>
        </div>
        // </Tooltip>
      )}
    </div>
  );
}

export default DatePickerWidget;
