import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import Icon from '../Icon';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function SliderSizes({ widget, styleKey, min, max, step }) {
  // console.log('debug22>widget?.[styleKey]: ', widget?.[styleKey]);
  const [isUsingBigMaxNumber, setIsUsingBigMaxNumber] = useState();
  useEffect(() => {
    switch (true) {
      // case ['legendFontSize', 'outerBorderRadius', 'textFontSize'].includes(
      //   styleKey
      // ) || styleKey?.includes('FontSize'):
      case styleKey.includes('FontSize'):
      // case styleKey.includes('Opacity'):
      case styleKey.includes('Radius'):
      case styleKey.includes('Width'):
        // newValue = newValue + 'px';

        setIsUsingBigMaxNumber(
          Boolean(+widget?.[styleKey]?.split('px')?.[0] > max)
        );
        break;
      default:
        setIsUsingBigMaxNumber(Boolean(+widget?.[styleKey] > max));
      // newValue = newValue;
    }
  }, []);

  // const [value, setValue] = React.useState([20, 37]);

  const {
    widgets,
    setWidgets,
    widgetIdThatTriggeredContextMenu,
    updateWidget,
    selectedWidgetIds: selectedWidgetIds_
  } = useContext(ReportBuilderContext);

  let selectedWidgetIds =
    selectedWidgetIds_.length && selectedWidgetIds_.includes(widget?.id)
      ? selectedWidgetIds_
      : [widget?.id];

  const handleChange = (event, newValue_) => {
    let newValue = newValue_;

    switch (true) {
      // case ['legendFontSize', 'outerBorderRadius', 'textFontSize'].includes(
      //   styleKey
      // ) || styleKey?.includes('FontSize'):
      case styleKey.includes('FontSize'):
      // case styleKey.includes('Opacity'):
      case styleKey.includes('Radius'):
      case styleKey.includes('Width'):
        newValue = newValue + 'px';
        break;
      default:
        newValue = newValue;
    }

    const copyOfWidgets = [...widgets];
    for (let copyOfWidget of copyOfWidgets) {
      if (selectedWidgetIds.includes(copyOfWidget.id)) {
        copyOfWidget[styleKey] = newValue;
      }
    }

    setWidgets(copyOfWidgets);
  };

  let value = widget?.[styleKey];

  switch (true) {
    // case ['legendFontSize', 'outerBorderRadius', 'textFontSize'].includes(
    //   styleKey
    // ) || styleKey.includes('FontSize'):

    case styleKey.includes('FontSize'):
    // case styleKey.includes('Opacity'):
    case styleKey.includes('Radius'):
    case styleKey.includes('Width'):
      value = String(widget?.[styleKey])?.split('px')?.[0];
      break;
    default:
  }

  return (
    <Box
      sx={{
        width: 260,
        padding: '0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 16px'
      }}
    >
      <Icon
        valueKey={styleKey}
        style={{ fontSize: '18px', marginRight: '0px' }}
      />
      <Slider
        style={{
          width: '160px',
          maxWidth: '160px',
          minWidth: '160px',
          // padding: '0px',
          margin: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        size="small"
        // defaultValue={widget[styleKey]}
        // aria-label="Small"
        // valueLabelDisplay="auto"

        step={step}
        marks
        min={min}
        max={isUsingBigMaxNumber ? max * 20 : max}
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(val) => {
          switch (true) {
            case styleKey.includes('Opacity'):
            case styleKey.includes('Brightness'):
            case styleKey.includes('Contrast'):
            case styleKey.includes('Saturate'):
            case styleKey.includes('Sepia'):
            case styleKey.includes('Invert'):
            case styleKey.includes('Grayscale'):
              return Math.round(val * 100) + '%';
            case styleKey.includes('Rotate'):
              return val + 'deg';
            default:
              return val;
          }
        }}
        // getAriaValueText={valuetext}
      />
      <div
        onClick={() => {
          setIsUsingBigMaxNumber(!isUsingBigMaxNumber);
        }}
      >
        {isUsingBigMaxNumber ? (
          <CheckBoxIcon style={{ fontSize: '16px' }} />
        ) : (
          <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
        )}
      </div>
    </Box>
  );
}
