import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { DataContext } from '../../../../../context/DataContext';

import { DialogContext } from '../../../../../context/DialogContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import { prepDataObject } from './prepDataObject';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../../fields';

function RectangleShapeWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const theme = useTheme();

  const { listOfData, arrayOfData, listOfFilteredDataSourceRows } =
    useContext(DataContext);

  const { isReportBuilderOpen } = useContext(DialogContext);

  const { isExportMode } = useContext(ReportBuilderContext);

  // let value = 1000;

  // const [value, setValue] = useState(null);

  let shapeFillOpacity = Math.round(widget.shapeFillOpacity * 255).toString(16);
  if (shapeFillOpacity.length === 1) {
    shapeFillOpacity = '0' + shapeFillOpacity;
  }
  let shapeFillColor = widget.shapeFillColor + shapeFillOpacity;

  return (
    <div
      key={widget.id}
      onDrop={(e) => {
        e.preventDefault();
        // e.stopPropagation();
      }}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center',
        background: shapeFillColor, // widget.shapeFillColor,
        // border: `${widget.outerBorderWidth} ${widget.outerBorderStyle} ${widget.outerBorderColor}`,
        boxShadow:
          !isExportMode && widget.isDropShadowOn
            ? 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
            : 'unset'
      }}
    >
      {/* <div
        onDrop={(e) => {
          e.preventDefault();
          // e.stopPropagation();
        }}
        style={{
          position: 'absolute',
          top: '-1px',
          right: '-1px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {widget.title}
      </div> */}
      {/* <div
        onDrop={(e) => {
          e.preventDefault();
          // e.stopPropagation();
        }}
        style={{
          background: widget.background,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: '16px'
        }}
      >

      </div> */}
    </div>
  );
}

export default RectangleShapeWidget;
