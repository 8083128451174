import React, { useContext, useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import { DataContext } from '../../../../../context/DataContext';

import { DialogContext } from '../../../../../context/DialogContext';

import { prepDataObject } from './prepDataObject';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../../fields';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict,
  dimensionDict,
  chartFillColors
} from './../dictionary';

function TableChartWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const key = widget.metrics?.[0] || 'cost';
  const {
    listOfData,
    arrayOfData,

    listOfFilteredDataSourceRows
  } = useContext(DataContext);

  const { isReportBuilderOpen } = useContext(DialogContext);

  // console.log('debug99>arrayOfData: ', arrayOfData);

  // console.log('PieChartWidget>listOfData: ', listOfData);

  const [seriesData, setSeriesData] = useState([]);
  const [arrayOfDataInCronologicalOrder, setArrayOfDataInCronologicalOrder] =
    useState([]);
  const [sortedColumn, setSortedColumn] = useState({
    column: null,
    order: null
  });

  function compareValues(key, order = 'asc') {
    // console.log('order: ', order);
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      // let varA = a[key];

      // switch(true){
      //   case typeof a[key] === 'string':
      //   varA =a[key].toUpperCase();
      //   break;
      //   case typeof a[key] === 'number':
      //     break;

      // }

      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      switch (true) {
        case (varA === undefined ||
          varA === null ||
          // isNaN(varA) ||
          varA === '') &&
          (varB === undefined ||
            varB === null ||
            //  isNaN(varB) ||
            varB === ''):
          break;

        case varA === undefined ||
          varA === null ||
          // isNaN(varA) ||
          varA === '':
          comparison = order === 'desc' ? -1 : 1;
          break;

        case varB === undefined ||
          varB === null ||
          // isNaN(varB) ||
          varB === '':
          comparison = order === 'desc' ? 1 : -1;
          break;

        default:
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
      }

      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  const sortColumnHandler = (e, itemKey) => {
    var orderDict = {
      null: 'asc',
      asc: 'desc',
      desc: null
    };

    var order =
      itemKey === sortedColumn.column || !sortedColumn?.column
        ? orderDict[sortedColumn?.order]
        : sortedColumn.order;

    let orderedData = [];
    switch (true) {
      case !Boolean(orderDict[sortedColumn?.order]):
        orderedData = [...arrayOfDataInCronologicalOrder];
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
        break;

      case itemKey === 'name' && itemKey !== sortedColumn.column:
        order = orderDict[null];
        orderedData = [...seriesData.sort(compareValues(itemKey, order))];
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
        break;

      case itemKey === 'dateRange':
        orderedData = [...seriesData.sort(compareValues('startDate', order))];
        setSortedColumn({
          column: order ? 'startDate' : null,
          order: order
        });
        break;

      case itemKey !== sortedColumn.column:
        orderedData = [...seriesData.sort(compareValues(itemKey, order))];
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
        break;

      default:
        orderedData = [...seriesData.sort(compareValues(itemKey, order))];
        setSortedColumn({
          column: order ? itemKey : null,
          order: order
        });
    }
    setSeriesData(orderedData);
    let copyOfWidget = { ...widget };
    copyOfWidget = {
      ...copyOfWidget,
      data: orderedData
    };
    // console.log('orderedData: ', orderedData);

    updateWidget(copyOfWidget, widgetIndex);
  };

  const valueFormatter = (item) =>
    `${key}: ${(
      Math.round((item.value + Number.EPSILON) * 100) / 100
    ).toLocaleString()} ( ${item.share}% )`;

  // useEffect(() => {
  //   return;
  //   if (
  //     !isReportBuilderOpen ||
  //     reportPage?.isDataFrozen ||
  //     widget?.isDataFrozen
  //   ) {
  //     return;
  //   }

  //   let widgetGroupByKey = '';

  //   switch (widget.dimensions[0]) {
  //     case 'documentType':
  //       widgetGroupByKey = 'documentType';
  //       break;
  //     case 'name':
  //       widgetGroupByKey = 'name';
  //       break;
  //   }
  //   const testGroupBy = Object.groupBy(
  //     listOfFilteredDataSourceRows,
  //     (row) => row[widgetGroupByKey]
  //   );

  //   // console.log('PieChartWidget>testGroupBy: ', testGroupBy);

  //   const dimensionKeys = Object.keys(testGroupBy);

  //   const newSeriesData = [];
  //   for (let dimensionKey of dimensionKeys) {
  //     let dimensionsValueObject = {};

  //     for (let row of testGroupBy[dimensionKey]) {
  //       for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
  //         if (nonSumEvalFields.includes(metric)) {
  //           continue;
  //         }

  //         dimensionsValueObject[metric] =
  //           (dimensionsValueObject[metric] || null) + (row[metric] || null);
  //       }
  //     }
  //     let seriesData = {
  //       // label: documentTypeToNameDict[dimensionKey],
  //       ...dimensionsValueObject
  //     };

  //     switch (widgetGroupByKey) {
  //       case 'documentType':
  //         seriesData.label = documentTypeToShortNameDict?.[dimensionKey];
  //         break;

  //       case 'name':
  //         seriesData.label = dimensionKey;
  //         break;
  //     }

  //     let addSeries = false;
  //     for (let metric of [...sumEvalFields, ...plannedEvalFields] || []) {
  //       if (seriesData[metric]) {
  //         addSeries = true;
  //       }
  //       // if (!nonSumEvalFields.includes(metric)) {
  //       //   continue;
  //       // }
  //       // switch (true) {
  //       //   case ['cpc', 'cpv', 'cpa', 'roas'].includes(metric):
  //       //     seriesData[metric] = Math.round(seriesData[metric]);
  //       //     break;
  //       //   // case ['ctr'].includes(metric):
  //       //   //   break;
  //       //   default:
  //       //     seriesData[metric] = Math.round(seriesData[metric]);
  //       // }
  //     }
  //     if (addSeries) {
  //       // seriesData.cpm =
  //       //   seriesData.impressions &&
  //       //   (seriesData.cost || 0) / (seriesData.impressions / 1000);

  //       // seriesData.cpc =
  //       //   seriesData.clicks && (seriesData.cost || 0) / seriesData.clicks;

  //       seriesData = prepDataObject(seriesData);

  //       newSeriesData.push(seriesData);
  //     }
  //   }
  //   // console.log('newSeriesData: ', newSeriesData);
  //   // let totalValueObject = 0;
  //   // for (let row of newSeriesData) {
  //   //   for (let metric of widget.metrics) {
  //   //     totalValueObject[metric] += row?.[metric] || null;
  //   //   }
  //   // }

  //   // for (let row of newSeriesData) {
  //   //   // row.share = Math.round((row.value / totalValue) * 100);
  //   //   row.value = Math.round(row.value);
  //   // }
  //   // console.log('totalValueObject: ', totalValueObject);

  //   let copyOfWidget = { ...widget };
  //   copyOfWidget = {
  //     ...copyOfWidget,
  //     data: newSeriesData
  //   };

  //   updateWidget(copyOfWidget, widgetIndex);

  //   // console.log('PieChartWidget>widget: ', widget);
  //   setSeriesData([...newSeriesData]);
  //   setArrayOfDataInCronologicalOrder([...newSeriesData]);
  // }, [
  //   listOfData,
  //   listOfFilteredDataSourceRows,
  //   widget.id,
  //   widget.metrics,
  //   widget.dimensions,
  //   // reportPage?.id, // dont use
  //   reportPage?.isDataFrozen,
  //   widget?.isDataFrozen,
  //   isReportBuilderOpen
  // ]);

  useEffect(() => {
    // console.log('widget?.data: ', widget?.data);
    setSeriesData([...widget?.data]);
    setArrayOfDataInCronologicalOrder([...widget?.data]);
  }, [widget]);

  // useEffect(() => {
  //   let copyOfWidget = { ...widget };
  //   copyOfWidget = {
  //     ...copyOfWidget,
  //     data: [...seriesData]
  //   };

  //   updateWidget(copyOfWidget, widgetIndex);
  // }, [seriesData]);

  const size = {
    // width: 400,
    // height: 200
    width: widget?.width?.split('px')?.[0],
    height: widget?.height?.split('px')?.[0] * 0.9
  };

  // console.log('PieChartWidget>widget: ', widget);
  // const data = {
  //   data: seriesData,
  //   valueFormatter
  // };

  let keys = [...(widget.metrics.map((metric) => metric.metric) || [])];

  if (widget.dimensions.length) {
    keys.unshift('label');
  }

  // keys.splice(keys.indexOf('label'), 1);

  // keys.unshift('label');

  return (
    <div
      style={{
        // fontSize: '14px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // fontFamily: widget.fontFamily
        //   ? widget.fontFamily + ', Roboto, Raleway, sans-serif'
        //   : 'Roboto, Raleway, sans-serif'

        fontFamily: widget.textFontFamily,
        fontSize: widget.textFontSize,
        fontStyle: widget.isTextFontItalic && 'italic',
        fontWeight: widget.isTextFontBold && 'bold',
        color: widget.textFontColor
        // position: 'relative',
        // width: widget.width.split('px')[0] * 0.9,
        // height: widget.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '12px',
          // borderRadius: '0px 5px 0px 12px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {widget.title}
      </div> */}
      <table
        style={{
          // width: widget.width,
          // maxWidth: widget.width,
          // minWidth: widget.width,
          // overflow: 'clip',
          width: '100%',
          height: '100%',
          borderCollapse: 'collapse'
          // borderRadius: '5px',
          // borderStyle: 'hidden' /* hide standard table (collapsed) border */,
          // boxShadow: ' 0 0 0 1px #666' /* this draws the table border  */
        }}
        // style={{ userSelect: 'none' }}
        draggable={false}
        onDrag={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onDragStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <tr
          style={
            {
              // width: widget.width,
              // maxWidth: widget.width,
              // minWidth: widget.width
              // height: '100%'
              // border: '1px solid grey'
              // borderCollapse: 'collapse'
            }
          }
        >
          {keys.map((hkey, index) => {
            if (hkey === 'label') {
              return (
                <th
                  onClick={(e) => sortColumnHandler(e, hkey)}
                  style={{
                    minWidth: '50px',
                    width: '100%',
                    // background: 'green',
                    // // width: '100%',
                    cursor: 'pointer',
                    // // fontWeight: 'bold',
                    // height: '15%',
                    textAlign: hkey === 'label' ? 'left' : 'right',
                    padding:
                      hkey === 'label' ? '7px 12px' : '7px 12px 7px 20px',
                    // borderLeftColor: 'rgba(150,150,150,0.3)',
                    // borderLeftStyle: 'solid',
                    // borderLeftWidth: Boolean(index) ? '2px' : '0px',

                    // whiteSpace: 'nowrap',
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis'

                    maxWidth: 0,
                    overflow: 'hidden',
                    // padding: '5px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'

                    // borderCollapse: 'collapse'
                  }}
                >
                  <span>
                    {hkey === 'label'
                      ? dimensionDict[widget.dimensions[0]]
                      : hkey[0].toUpperCase() + hkey.slice(1)}
                  </span>
                </th>
              );
            }
            // console.log('index123: ', index);
            return (
              <th
                onClick={(e) => sortColumnHandler(e, hkey)}
                style={{
                  minHeight: '100px',
                  width: 'fit-content',
                  cursor: 'pointer',
                  // fontWeight: 'bold',
                  height: '15%',
                  textAlign: hkey === 'label' ? 'left' : 'right',
                  padding: hkey === 'label' ? '7px 12px' : '7px 12px 7px 20px',
                  borderLeftColor: 'rgba(150,150,150,0.3)',
                  borderLeftStyle: 'solid',
                  borderLeftWidth: Boolean(index) ? '2px' : '0px'

                  // borderCollapse: 'collapse'
                }}
              >
                {hkey === 'label'
                  ? dimensionDict[widget.dimensions[0]]
                  : hkey[0].toUpperCase() + hkey.slice(1)}
              </th>
            );
          })}
        </tr>
        {(widget?.data || []).map((dataObj, dataObjIndex) => {
          // console.log('dataObjIndex: ', dataObjIndex);
          return (
            <tr
              style={{
                width: widget?.width,
                minWidth: widget?.width
                // height: '100%'
                // borderTopColor: 'red',
                // borderTopStyle: 'solid',
                // borderTopWidth: Boolean(index) ? '1px' : '0px',
                // border: '1px solid grey',
                // borderWidth: index ? '1x' : '0px'
                // borderCollapse: 'collapse'
              }}
            >
              {keys.map((hkey, index) => {
                let numberOfDecimals = [
                  'cpm',
                  'cpc',
                  'cpv',
                  'cpcv',
                  'action01Cpa',
                  'action01Roas',
                  'vtr'
                ].includes(hkey)
                  ? 2
                  : 0;

                if (hkey === 'label') {
                  return (
                    <td
                      style={{
                        textAlign: hkey === 'label' ? 'left' : 'right',
                        padding:
                          hkey === 'label' ? '5px 12px' : '5px 12px 5px 20px',

                        // border: '1px solid grey',
                        // borderWidth: index ? '1x' : '0px'
                        borderLeftColor: 'rgba(150,150,150,0.3)',
                        borderLeftStyle: 'solid',
                        // borderWidth: !index && '0px',
                        borderLeftWidth: index !== 0 ? '2px' : '0px',

                        borderTopColor: 'rgba(150,150,150,0.3)',
                        borderTopStyle: 'solid',
                        borderTopWidth: '2px',
                        // width: '100%'
                        // height: '100%'
                        // borderCollapse: 'collapse',

                        maxWidth: 0,
                        overflow: 'hidden',
                        // padding: '5px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      <span
                        style={
                          {
                            // whiteSpace: 'nowrap',
                            // overflow: 'hidden',
                            // textOverflow: 'ellipsis'
                          }
                        }
                      >
                        {hkey === 'label'
                          ? dataObj[hkey]
                          : (
                              Math.round(
                                (dataObj[hkey] + Number.EPSILON) * 100
                              ) /
                              ([
                                'cpm',
                                'cpc',
                                'cpv',
                                'cpcv',
                                'action01Cpa',
                                'action01Roas',
                                'vtr'
                              ].includes(hkey)
                                ? 100
                                : 1)
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: numberOfDecimals,
                              maximumFractionDigits: numberOfDecimals
                            })}
                      </span>
                    </td>
                  );
                }
                return (
                  <td
                    style={{
                      textAlign: hkey === 'label' ? 'left' : 'right',
                      padding:
                        hkey === 'label' ? '5px 12px' : '5px 12px 5px 20px',

                      // border: '1px solid grey',
                      // borderWidth: index ? '1x' : '0px'
                      borderLeftColor: 'rgba(150,150,150,0.3)',
                      borderLeftStyle: 'solid',
                      // borderWidth: !index && '0px',
                      borderLeftWidth: index !== 0 ? '2px' : '0px',

                      borderTopColor: 'rgba(150,150,150,0.3)',
                      borderTopStyle: 'solid',
                      borderTopWidth: '2px'
                      // width: '100%'
                      // height: '100%'
                      // borderCollapse: 'collapse'
                    }}
                  >
                    {hkey === 'label'
                      ? dataObj[hkey]
                      : (
                          Math.round((dataObj[hkey] + Number.EPSILON) * 100) /
                          ([
                            'cpm',
                            'cpc',
                            'cpv',
                            'cpcv',
                            'action01Cpa',
                            'action01Roas',
                            'vtr'
                          ].includes(hkey)
                            ? 100
                            : 100)
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: numberOfDecimals,
                          maximumFractionDigits: numberOfDecimals
                        })}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default TableChartWidget;
