export const MONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const lineChartWidget = async (widget, pres, slide, chartFillColors) => {
  const pptx = pres;

  if (widget.isShowingCard) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        color: 'FFFFFF',
        transparency: (widget.opacity - 1) * -100 //5
      },
      border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      w: +widget.width.split('px')[0] / 72 / 2,
      h: +widget.height.split('px')[0] / 72 / 2,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,

      rectRadius: 0.05,
      shadow: {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    });
  }

  let dataChartObj = {
    name: widget.title,
    labels: [], // widget.data.map((obj) => obj.label),
    values: [] // widget.data.map((obj) => obj.value)
  };

  // let totalValue = 0;

  // for (let obj of widget.data) {
  //   totalValue = totalValue + (obj.value || null);
  // }

  // for (let obj of widget.data) {
  //   if (obj.value / totalValue >= 0.005) {
  //     dataChartObj.labels.push(obj.label);
  //     dataChartObj.values.push(obj.value);
  //   }
  // }
  // let dataChartPieStat = [dataChartObj];

  // console.log('dataChartPieStat: ', dataChartPieStat);
  // {
  //   name: 'Project Status',
  //   labels: ['Red', 'Yellow', 'Green', 'Complete', 'Cancelled', 'Unknown'],
  //   values: [25, 5, 5, 5, 5, 5]
  // }

  // ##################################################################################################

  let MAXVAL = 20000;
  // console.log('widget.data: ', widget.data);

  let arrDataTimeline = [];

  let highestValue = 0;
  for (let dataObj of widget.data) {
    let totalValue = 0;
    for (let value of dataObj.data || []) {
      totalValue = totalValue + value;
    }
    if (highestValue < totalValue) {
      highestValue = totalValue;
    }

    arrDataTimeline.push({
      name: dataObj.label || widget.metrics[0],
      labels: widget.dates.map((date) => date.slice(5)),
      values: dataObj.data.map((val) => val),
      totalValue
    });
  }

  // for (let idx = 0; idx < 15; idx++) {
  //   let tmpObj = {
  //     name: `Series ${idx}`,
  //     labels: MONS,
  //     values: []
  //   };

  //   for (let idy = 0; idy < MONS.length; idy++) {
  //     tmpObj.values.push(Math.floor(Math.random() * MAXVAL) + 1);
  //   }

  //   // arrDataTimeline.push(tmpObj);
  // }
  let showLegend = false;

  switch (true) {
    case widget?.dimensions?.length > 0 &&
      widget?.dimensions[0] === 'documentType':
    case widget?.dimensions?.length > 0 && widget?.dimensions?.length < 3:
      showLegend = true;
      break;
  }

  let averageValuePerDay = highestValue / widget.dates.length;

  let valAxisMajorUnit = undefined;
  let valAxisDisplayUnit = undefined;
  let valAxisLabelFormatCode = undefined;
  switch (true) {
    case averageValuePerDay >= 10000:
      valAxisDisplayUnit = 'thousands';
      valAxisLabelFormatCode = '#,##0 k';
      break;

    case averageValuePerDay < 0.5:
      valAxisMajorUnit = 0.01;
      break;
    case averageValuePerDay < 1:
      valAxisMajorUnit = 0.1;
      break;

    default:
      valAxisLabelFormatCode = '#,##0';
  }
  let showKFormatting = averageValuePerDay >= 10000;
  // console.log(
  //   'highestValue / widget.dates.length: ',
  //   highestValue / widget.dates.length
  // );
  // FULL SLIDE:
  let optsChartLine1 = {
    chartArea: {
      fill: {
        color: 'FFFFFF',
        transparency: 100
      },
      // border: { pt: '1', color: 'f1f1f1', transparency: 100 },
      roundedCorners: true

      // shadow: {
      //   type: 'outer',
      //   blur: 10,
      //   offset: 5,
      //   angle: 45,
      //   color: '000000',
      //   opacity: 1
      // }
    },

    plotArea: {
      fill: { color: '00FFFF', transparency: 100 },
      border: { pt: '1', color: 'f1f1f1' }
    },
    // x: 0.5,
    // y: 0.6,
    // w: '95%',
    // h: '85%',
    chartColors: chartFillColors, //COLORS_RYGU,
    w: +widget.width.split('px')[0] / 72 / 2 + 0 / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,
    // plotArea: { fill: { color: 'F2F9FC' } },
    // valAxisMaxVal: MAXVAL,
    showLegend: showLegend,
    legendPos: 'r',
    catAxisLabelFontSize: 7,
    valAxisLabelFontSize: 7,
    valAxisMajorUnit: valAxisMajorUnit && valAxisMajorUnit,
    valAxisDisplayUnit: valAxisDisplayUnit && valAxisDisplayUnit, //showKFormatting && 'thousands',
    valAxisLabelFormatCode: valAxisLabelFormatCode, // showKFormatting ? '#,##0 k' : '#,##0',
    legendFontSize: 8,
    legendFontFace: 'Roboto',

    // layout: { x: 0.03, y: 0.35, w: 0.8, h: 0.65 },
    // layout: showLegend && { x: 0.01, y: 0, w: 0.54, h: 1 },
    // layout: showLegend && { x: 0.01, y: 0, w: '10%', h: 1 },
    catAxisLineSize: 1,
    valAxisLineSize: 1,
    // valAxisMajorTickMark: 'none',
    // valAxisMinorTickMark: 'none',
    // catAxisMajorTickMark: 'none',
    // catAxisMinorTickMark: 'none',
    radarStyle: 'filled',
    catAxisBaseTimeUnit: 'days',
    catAxisMajorTimeUnit: 'days',
    catAxisLabelRotate: '90',
    // catAxisLabelFrequency: 5,
    // catAxisLineStyle: 'solid',
    lineDataSymbol: 'none',
    valAxisLineShow: true,
    catGridLine: { style: 'none' },
    valGridLine: {
      // style: 'none',
      color: 'F5F5F5'
    },
    lineSize: 1.5
    // lineDash: 'dot',
    // lineCap: 'round'
    // lineDataSymbol: 'dot'
  };
  slide.addChart(pres.charts.LINE, arrDataTimeline, optsChartLine1);

  // ##################################################################################################

  let titleWidth = Math.round(widget.title.length * 9 + 10);
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
    fill: {
      color: 'ababab',
      transparency: 90
    },
    border: { pt: '1', color: 'f1f1f1', transparency: 10 },
    w: (titleWidth + 20) / 72 / 2,
    h: 25 / 72 / 2,
    x:
      +widget.left.split('px')[0] / 72 / 2 +
      widget.width.split('px')[0] / 72 / 2 -
      (titleWidth + 20) / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2 + 0 / 72 / 2,

    rectRadius: 0.01
    // shadow: {
    //   type: 'outer',
    //   blur: 6,
    //   offset: 2,
    //   angle: 45,
    //   color: 'bcbcbc',
    //   opacity: 0.2
    // }
  });

  slide.addText(widget.title, {
    w: titleWidth / 72 / 2,
    h: 20 / 72 / 2,
    x:
      +widget.left.split('px')[0] / 72 / 2 +
      widget.width.split('px')[0] / 72 / 2 -
      (titleWidth + 10) / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2 + 2 / 72 / 2,

    margin: 0,
    fontFace: 'Roboto', // 'Arial',
    fontSize: 8,
    color: '000000',
    bold: true,
    // underline: true,
    isTextBox: true,
    align: 'left'
    // fill: { color: 'F1F1F1' }
    // highlight: '#bcbcbc'
  });
};

export default lineChartWidget;
