import React, { useContext } from 'react';
import { Button } from '@mui/material';

import ScheduleIcon from '@mui/icons-material/Schedule';

import { InterfaceContext } from '../../../context/InterfaceContext';

import { DialogContext } from '../../../context/DialogContext';

import { useTheme } from '@emotion/react';

import Tooltip from '@mui/material/Tooltip';

function TimeboxerButton() {
  const theme = useTheme();
  const { isTimeboxerOpen, setIsTimeboxerOpen } = useContext(DialogContext);

  const buttonSize = '60px';
  const size = '40px';
  return (
    <>
      <Tooltip
        // followCursor
        // PopperProps={{
        //   style: {
        //     visibility: isDragging ? 'hidden' : 'visible'
        //   }
        // }}
        title={'Timeboxer'}
        placement="top"
        arrow
        // enterDelay={200}
        // leaveDelay={0}
      >
        <Button
          style={{
            borderRadius: '30px',
            width: buttonSize,
            minWidth: buttonSize,
            height: buttonSize,
            minHeight: buttonSize
            // backdropFilter: 'blur(5px)'
            // margin: '10px'
          }}
          onClick={() => setIsTimeboxerOpen(!isTimeboxerOpen)}
        >
          <div
            style={{
              borderRadius: '30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: size,
              minWidth: size,
              height: size,
              minHeight: size,
              backdropFilter: theme?.palette?.mode !== 'dark' && 'blur(5px)',
              filter:
                theme?.palette?.mode === 'dark' &&
                'drop-shadow(2px 4px 6px #2c0fa5)'
              // margin: '10px'
            }}
          >
            <ScheduleIcon
              style={{
                fontSize: '40px',
                color: theme?.palette?.mode === 'dark' ? 'white' : '#a72683'
              }}
            />
          </div>
        </Button>
      </Tooltip>
    </>
  );
}

export default TimeboxerButton;
