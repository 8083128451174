import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@emotion/react';

import { useInView } from 'react-intersection-observer';

function ReportAsset({
  reportAsset,
  selectedReportAsset,
  setSelectedReportAsset
}) {
  const theme = useTheme();
  // const { ref, inView, entry } = useInView({
  //   /* Optional options */
  //   threshold: 0
  // });
  const { ref, inView, entry } = useInView({
    /* Optional options */
    // threshold: 0
    triggerOnce: true,
    rootMargin: '200px 0px'
  });
  // console.log(
  //   "reportAsset?.height?.split('px')[0]): ",
  //   reportAsset?.height?.split('px')[0]
  // );
  // const aspectRatio =
  //   reportAsset?.width?.split('px')[0] / reportAsset?.height?.split('px')[0];
  return (
    <div ref={ref} style={{ display: 'inline' }}>
      {inView ? (
        <Button
          draggable={false}
          style={{
            padding: '0px',
            margin: '10px 10px',
            height: '200px',
            // aspectRatio: aspectRatio,
            boxShadow:
              theme?.palette?.mode === 'dark'
                ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' //'0px 3px 1px -2px rgb(75 75 150 / 20%), 0px 2px 2px 0px rgb(75 75 150 / 14%), 0px 1px 5px 0px rgb(75 75 150 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
                : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
            // gridRowStart: 2,
            // gridRowEnd: 2,
            // height: '40px',
            // justifyContent: 'flex-start'
            // background:
            //   (selectedReport.type === report.type ||
            //     selectedReport._id === report._id) &&
            //   stylesStoredInReduxManagerAccountSettings.selectedListButton
          }}
          onClick={() => setSelectedReportAsset(reportAsset)}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // margin: '5px',
              borderRadius: '5px',

              // height: '200px',
              // aspectRatio: reportAsset.imageNaturalAspectRatio,
              // width: '200px',
              // maxHeight: '200px',
              // maxWidth: '200px',
              // // width: '400px',
              // backgroundSize: 'cover',
              // // width: '170px',

              // backgroundImage: `url(${reportAsset.imageDataUri})`,
              // backgroundRepeat: 'no-repeat',
              outline:
                reportAsset?._id === selectedReportAsset?._id
                  ? theme?.palette?.mode === 'dark'
                    ? '1px solid rgba(255,255,255,1)'
                    : 'red'
                  : '1px solid rgba(255,255,255,0)'
            }}
          >
            <img
              draggable={false}
              src={reportAsset?.url}
              style={{
                objectFit: 'contain',
                // backgroundImage: `url(${reportAsset.imageDataUri})`,
                // backgroundRepeat: 'no-repeat',
                //   aspectRatio: reportAsset.imageNaturalAspectRatio,
                width: '198px',
                maxHeight: '198px',
                maxWidth: '198px'
                //   //   height: '300px'
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                padding: '1px 7px',
                background: '#cdb9d4', // '#a7268344',
                borderRadius: '5px 5px 0px 0px',
                fontSize: '10px',
                fontWeight: 'bold'
              }}
            >
              {reportAsset?.name}
            </div>
          </div>
          {/* <img src={`${reportAsset.thumbnailImageDataUri}`} /> */}
          {/* {reportAsset?.name} */}
        </Button>
      ) : (
        <div style={{ height: '200px', width: '355px' }}></div>
      )}
    </div>
  );
}

export default ReportAsset;
