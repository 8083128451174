import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { LineChart } from '@mui/x-charts/LineChart';

import { DataContext } from '../../../../../context/DataContext';

import { DialogContext } from '../../../../../context/DialogContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../../fields';

import { prepDataObject } from './prepDataObject';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict,
  chartFillColors
} from './../dictionary';

import formatDateYYYYMMDD from './../../../roitable_functions/formatDateYYYYMMDD';
import { InterfaceContext } from '../../../../../context/InterfaceContext';

function LineChartWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  // const { updateWidget } = useContext(ReportBuilderContext);

  const key = widget.metrics?.[0] || 'cost';

  // console.log('lineChartWidget>widget: ', widget);

  const {
    selectedDateStart,
    setSelectedDateStart,
    selectedDateStop,
    setSelectedDateStop
  } = useContext(InterfaceContext);
  const { listOfData, arrayOfData, listOfFilteredDataSourceRows } =
    useContext(DataContext);
  const { aggregatedDateRange, startDateForLastDaysData } =
    useContext(InterfaceContext);

  const { isReportBuilderOpen } = useContext(DialogContext);

  // console.log('debug99>arrayOfData: ', arrayOfData);

  // console.log('PieChartWidget>listOfData: ', listOfData);

  const [seriesData, setSeriesData] = useState([]);

  const valueFormatter = (item) =>
    `${key}: ${(
      Math.round((item.value + Number.EPSILON) * 100) / 100
    ).toLocaleString()} ( ${item.share}% )`;

  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [dates, setDates] = useState([new Date()]);

  const [intervalRange, setIntervalRange] = useState([]);

  // const data = {
  //   data: seriesData
  //   // valueFormatter
  // };

  let xAxis = {};

  switch (widget.interval) {
    case 'month':
      // const monthNames = [
      //   'January',
      //   'February',
      //   'March',
      //   'April',
      //   'May',
      //   'June',
      //   'July',
      //   'August',
      //   'September',
      //   'October',
      //   'November',
      //   'December'
      // ];

      xAxis = [
        {
          label: 'Month',
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget.dates || [], // xAxisData,
          scaleType: 'point' // 'band'
          // valueFormatter: (month) => {
          //   return month;
          // }
          // valueFormatter: (date) => {
          //   const formattedDate = formatDateYYYYMMDD(date);
          //   // console.log('formattedDate: ', formattedDate);
          //   return formattedDate;
          // } //(date) => dayjs(date).format('MMM D')
        }
      ];
      break;
    default:
      let dates_ = widget.dates?.map((date) => new Date(date));
      // console.log('widget.dates: ', widget.dates);
      xAxis = [
        {
          label: 'Date',
          data: dates_ || [], // xAxisData,
          tickInterval: dates_ || [], // xAxisData,
          scaleType: 'time',
          valueFormatter: (date) => {
            // console.log('date: ', date);
            const formattedDate = formatDateYYYYMMDD(date);
            // console.log('formattedDate: ', formattedDate);
            return formattedDate;
          } //(date) => dayjs(date).format('MMM D')
        }
      ];
  }

  // console.log('widget?.data: ', widget?.data);

  if (widget?.data?.[0]?.data?.length !== widget?.dates?.length) {
    // console.log('fail: widget?.data?.length: ', widget?.data?.data?.length);
    // console.log('fail: widget?.dates?.length: ', widget?.dates?.length);
    return <></>;
  }

  // if (isLoading) {
  //   return <div></div>;
  // }
  return (
    // <div onMouseUpHandler={onMouseUpHandler}>

    // <div
    //   style={{
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     // justifyContent: 'space-between',
    //     justifyContent: 'space-between',
    //     alignItems: 'center'
    //     // position: 'relative',
    //     // width: widget.width.split('px')[0] * 0.9,
    //     // height: widget.height.split('px')[0] * 0.8,
    //     // padding: '0px'
    //   }}
    // >
    //   <div
    //     style={{
    //       width: '100%',
    //       display: 'flex',
    //       flexDirection: 'row',
    //       justifyContent: 'center',
    //       alignContent: 'center',
    //       fontSize: '14px',
    //       fontWeight: 'bold'
    //     }}
    //   >
    <div
      key={widget.id}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
        // position: 'relative',
        // width: widget.width.split('px')[0] * 0.9,
        // height: widget.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '-1px',
          right: '-1px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {/* <div
          style={{
            borderRadius: '0px 0px 5px 5px',
            background: 'rgba(150,150,150,0.1)',
            padding: '0px 7px 2px 7px'
          }}
        > */}
        {widget.title}
        {/* </div> */}
      </div>
      <div
        style={{
          display: 'flex'
          // padding: '0px 3px'
        }}
      >
        <LineChart
          colors={[...(chartFillColors || [])]} // Use palette
          // xAxis={[
          //   new Date('2024-08-01'),
          //   new Date('2024-08-02'),
          //   new Date('2024-08-03'),
          //   new Date('2024-08-04'),
          //   new Date('2024-08-05'),
          //   new Date('2024-08-06'),
          //   new Date('2024-08-07')
          // ]}

          xAxis={xAxis}
          // {[
          //   {
          //     label: 'Date',
          //     data: dates_, // xAxisData,
          //     tickInterval: dates_, // xAxisData,
          //     scaleType: 'time',
          //     valueFormatter: (date) => {
          //       const formattedDate = formatDateYYYYMMDD(date);
          //       // console.log('formattedDate: ', formattedDate);
          //       return formattedDate;
          //     } //(date) => dayjs(date).format('MMM D')
          //   }
          // ]}
          series={
            // [
            //   {
            //     data: [1, 2, 3, 4, 5, 6, 7]
            //   }
            // ]
            // widget?.data || []
            widget?.data?.[0]?.data?.length === widget?.dates?.length
              ? widget?.data
              : []

            // [
            //   {
            //     // arcLabel: (item) => `${item.share}%`,
            //     // arcLabelMinAngle: 35,
            //     // arcLabelRadius: '60%',
            //     ...data
            //   }
            // ]
          }
          margin={{
            left: 70
            // right: 60
            // top: 80,
            // bottom: 80,
          }}
          slotProps={{
            legend: {
              hidden: (widget?.data || [])?.length > 10,
              // direction: 'column',
              // position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              padding: { top: 0, left: 10, right: 10 },
              labelStyle: {
                fontSize: 12
                //fill: 'blue'
              }
            }
          }}
          width={widget.width.split('px')[0]}
          height={
            widget.height.split('px')[0] - (widget?.dimensions?.length ? 20 : 0)
          }
        />
      </div>
    </div>
  );
}

export default LineChartWidget;
