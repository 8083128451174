import React, { useState, useEffect, useContext, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

function TextWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const ref = useRef();
  const theme = useTheme();
  const [textInput, setTextInput] = useState('');

  const [textIsEdited, setTextIsEdited] = useState(false);
  const [edit, toggleEdit] = useState(false);

  const { selectedWidgetIds, setSelectedWidgetIds } =
    useContext(ReportBuilderContext);

  useEffect(() => {
    if (textInput) {
      setTextIsEdited(true);
    }
    setTextInput(widget.text);
    // ref.textContent = widget.text;
    // document.getElementById('divA').textContent = 'This text is different!';
  }, [widget.text]);

  // useEffect(() => {
  //   ref.textContent = widget.text;
  // });
  // const onChangeHandler = (e) => {
  //   setTextInput(e.target.innerText);
  // };
  // console.log('widget.text: ', widget.text);

  const [content, setContent] = useState('Edit me!');
  let lastHtml = content;

  const handleInput = (event) => {
    let html = event.target.textContent;
    if (html !== lastHtml) {
      setTextInput(html);
    }
    lastHtml = html;
  };

  useEffect(() => {
    // Additional logic to manage caret position or other concerns
  }, [content]);

  // const onClickHandler = (e) => {
  //   var isRightMB;
  //   var isLeftMB;
  //   e = e || window.event;

  //   if ('which' in e) {
  //     // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
  //     isRightMB = e.which == 3;
  //     isLeftMB = e.which == 1;
  //   } else if ('button' in e) {
  //     // IE, Opera
  //     isRightMB = e.button == 2;
  //     isLeftMB = e.button == 1;
  //   }
  //   console.log('isRightMB: ', isRightMB);
  // };

  const onSaveHandler = () => {
    updateWidget({ ...widget, text: textInput }, widgetIndex);
    // setTextInput('');

    if (!textInput) {
      setTextIsEdited(false);
    }
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setTextInput(widget.text);
      toggleEdit(false);
      return;
    }

    if (event.key === 'Enter') {
      console.log('do validate');
      updateWidget(
        {
          ...widget,
          text: textInput
          // || originalWidgetCopy.value
        },
        widgetIndex
      );
      // setTextInput('');

      if (!textInput) {
        setTextIsEdited(false);
      }

      toggleEdit(false);
      return;
    }
  };

  let verticalTextAlign = 'center';

  // console.log(' widget.verticalTextAlign: ', widget.verticalTextAlign);
  switch (true) {
    case widget.verticalTextAlign === 'top':
      verticalTextAlign = 'start';
      break;
    case widget.verticalTextAlign === 'middle':
      verticalTextAlign = 'center';
      break;
    case widget.verticalTextAlign === 'bottom':
      verticalTextAlign = 'end';
      break;
  }

  let textFontOpacity = Math.round(widget.textFontOpacity * 255).toString(16);
  if (textFontOpacity.length === 1) {
    textFontOpacity = '0' + textFontOpacity;
  }
  let textFontColor = widget.textFontColor + textFontOpacity;

  return (
    <div
      // className="text-widget-surface"
      onMouseUpHandler={onMouseUpHandler}
      onClick={() => {
        toggleEdit(true);
      }}
      onBlur={(e) => onSaveHandler(e)}
    >
      <div
        ref={ref}
        // contenteditable={edit ? 'true' : 'false'}
        contenteditable="true"
        autoFocus
        style={{
          // width: +widget?.width.split('px')[0] - 2 + 'px',
          // height: +widget?.height.split('px')[0] - 2 + 'px',
          width: +widget?.width.split('px')[0] - 2 + 'px',
          height: +widget?.height.split('px')[0] - 2 + 'px',
          fontFamily: widget.textFontFamily,
          fontSize: widget.textFontSize,
          fontStyle: widget.isTextFontItalic && 'italic',
          fontWeight: widget.isTextFontBold && 'bold',
          color: textFontColor, //widget.textFontColor,
          padding: '5px',

          // outline: 'none',
          // border: 'none',
          display: 'flex',
          flexDirection: 'column',
          // alignItems: 'center',
          justifyContent: verticalTextAlign, //'center',
          textAlign: widget.horizontalTextAlign, //'center',
          lineHeight: '1.2',
          // minHeight: 'calc(1.2em * 4)',
          lineBreak: 'anywhere',
          // '-webkit-line-break': 'after-white-space',
          overflowWrap: 'break-word',
          webkitLineBreak: 'after-white-space',
          whiteSpace: 'normal',

          // verticalAlign: 'middle',
          // textAlign: 'center',
          // border: '1px solid rgba(255,255,255,0)',
          // resize: 'none',
          // padding: '10px',
          // // fontFamily: 'Roboto, Raleway, sans-serif',
          // lineHeight: '1.5',
          // // fontSize: '12px',
          // // color: widget.bodyTextColor || '#000000',
          // // fontWeight: widget.isBodyTextFontWeightBold && 'bold',
          // // fontStyle: widget.isBodyTextFontStyleItalic && 'italic',
          // // fontSize: widget.bodyTextFontSize || '14px',
          // // fontFamily: widget.fontFamily
          // //   ? widget.fontFamily + ', Roboto, Raleway, sans-serif'
          // //   : 'Roboto, Raleway, sans-serif',

          // fontFamily: widget.textFontFamily,
          // fontSize: widget.textFontSize,
          // fontStyle: widget.isTextFontItalic && 'italic',
          // fontWeight: widget.isTextFontBold && 'bold',
          // color: widget.textFontColor,
          // background: 'none',

          // letterSpacing: '0.2px',
          // // color: 'black',
          // // width: textInput.length * 1 + 'ch',
          // // width:
          // //   numUpper * 0.35 + numLarge * 1 + textInput.length * 0.925 + 'ch',
          // // minWidth: '1ch',
          // width: +widget?.width.split('px')[0] - 2 + 'px',
          // lineBreak: 'anywhere',
          // // height: +widget?.height.split('px')[0] - 2 + 'px',
          outline: 'none',
          border: 'none'
          // overflow: 'hidden'
          // border: selectedWidgetIds.includes(widget.id) && 'none'
          // fontSize: '11.5px'
        }}
        // value={textInput}
        // onInput={onChangeHandler}
        onKeyDown={handleKeyDown}
        // contentEditable={true}
        onInput={handleInput}
        // dangerouslySetInnerHTML={{ __html: widget.text }}
        // textContent={widget.text}
      >
        {widget.text}
      </div>
    </div>
  );
}

export default TextWidget;
