import { documentTypeToShortNameDict } from './../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import formatDateYYYYMMDD from '../../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

import { phoneMockupImage } from '../../components/tableV4/report-builder-2/report-builder-content/assets/phoneMockupImage';

export const mockupWidget = ({ widget }) => {
  let copyOfWidget = { ...widget };
  // copyOfWidget = {
  //   ...copyOfWidget,

  // };

  if (!copyOfWidget.mockupImageDataUri) {
    switch (copyOfWidget.mockupType) {
      case 'PHONE':
        copyOfWidget.mockupImageDataUri = phoneMockupImage;
        break;
    }
  }
  return copyOfWidget;
};
