export default function formatImage({
  imgObj,
  backgroundColor,
  backgroundColorOpacity,
  grayScale,
  blur,
  brightness,
  contrast,
  hueRotate,
  invert,
  opacity,
  saturate,
  sepia,
  height,
  width,
  isBackground
}) {
  let _filter = '';

  const filters = [
    'grayScale',
    'blur',
    'brightness',
    'contrast',
    'hueRotate',
    'invert',
    'opacity',
    'saturate',
    'sepia'
  ];
  console.log('debug24>blur: ', blur);

  for (let filterKey of filters) {
    // if (reportPage?.[filterKey] === undefined) {
    //   console.log('filterKey: ', filterKey);
    //   continue;
    // }
    // console.log('filterKey: ', filterKey);
    _filter = _filter + ' ';
    switch (filterKey) {
      case 'grayScale': // grayscale(50%)
        _filter = _filter + `grayscale(${grayScale * 100}%)`;
        break;

      case 'blur': // blur(5px)
        _filter = _filter + `blur(${blur * 4}px)`;
        break;

      case 'brightness': // brightness(0.4)
        _filter = _filter + `brightness(${brightness})`;
        break;

      case 'contrast': //contrast(200%)
        _filter = _filter + `contrast(${contrast * 100}%)`;
        break;

      case 'hueRotate': // hue-rotate(90deg)
        _filter = _filter + `hue-rotate(${hueRotate}deg)`;
        break;

      case 'invert': // invert(75%)
        _filter = _filter + `invert(${invert * 100}%)`;
        break;

      case 'opacity': // opacity(25%)
        _filter = _filter + `opacity(${opacity * 100}%)`;
        break;

      case 'saturate': // saturate(30%)
        _filter = _filter + `saturate(${saturate * 100}%)`;
        break;

      case 'sepia': // sepia(60%)
        _filter = _filter + `sepia(${sepia * 100}%)`;
        break;
    }
  }

  _filter.slice(1);
  // console.log('debug31>_filter: ', _filter);
  // console.log('debug31>height: ', height);
  // console.log('debug31>width: ', width);

  const applyOpacity = Boolean(opacity !== 1);
  const _opacity = Math.round(opacity * 255);
  var canvas = document.createElement('canvas');
  var canvasContext = canvas.getContext('2d');

  var imgW = imgObj.width;
  var imgH = imgObj.height;
  canvas.width = imgW;
  canvas.height = imgH;

  // canvas.width = width;
  // canvas.height = height;
  // canvas.clearRect(0, 0, 128, 128);

  let backgroundColorOpacityHex = Math.round(
    backgroundColorOpacity * 255
  ).toString(16);
  if (backgroundColorOpacityHex.length === 1) {
    backgroundColorOpacityHex = '0' + backgroundColorOpacityHex;
  }
  // console.log('backgroundColorOpacityHex: ', backgroundColorOpacityHex);
  if (backgroundColor) {
    // canvasContext.globalCompositeOperation = 'color';
    canvasContext.fillStyle = backgroundColor + backgroundColorOpacityHex;
    canvasContext.fillRect(0, 0, canvas.width, canvas.height);
  }
  canvasContext.filter = _filter; //'grayscale(50%) blur(10px)';

  // crop from 0,0, to 250,150
  var cropX = 0;
  var cropY = 0;
  var cropWidth = imgW;
  var cropHeight = imgW * (height / width);

  //resize our canvas to match the size of the cropped area
  canvas.style.width = cropWidth;
  canvas.style.height = cropHeight;

  // canvasContext.fillStyle = 'black';
  //fill canvas with cropped image
  canvasContext.drawImage(
    imgObj,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    0,
    0,
    isBackground ? canvas.width : cropWidth,
    isBackground ? canvas.height : cropHeight //canvas.height
  );
  // canvasContext.drawImage(imgObj, 0, 0);
  // canvasContext.filter = 'blur(10px)';

  var imgPixels = canvasContext.getImageData(0, 0, imgW, imgH);

  // for (var y = 0; y < imgPixels.height; y++) {
  //   for (var x = 0; x < imgPixels.width; x++) {
  //     var i = y * 4 * imgPixels.width + x * 4;
  //     if (isGrayscale) {
  //       var avg =
  //         (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) /
  //         3;
  //       imgPixels.data[i] = avg;
  //       imgPixels.data[i + 1] = avg;
  //       imgPixels.data[i + 2] = avg;
  //     }
  //     if (applyOpacity) {
  //       imgPixels.data[i + 3] = _opacity;
  //     }
  //   }
  // }

  canvasContext.putImageData(
    imgPixels,
    0,
    0,
    0,
    0,
    imgPixels.width,
    imgPixels.height
  );
  return canvas.toDataURL();
}
