import html2canvas from 'html2canvas';

import formatImage from '../export/formatImage';

export const createThumbnail = async (reportPage, scale = 0.2) => {
  if (!reportPage?.id) {
    return;
  }

  // ##############################################################

  let originalImageDataUris = {};
  let originalBackgroundColors = {};
  let originalBoxShadow = {};
  let originalFilters = {};

  const reportPageParentDivElement = document.getElementById(
    'report-builder-page-parent-div'
  );
  if (!reportPageParentDivElement) {
    return;
  }

  originalBackgroundColors[reportPage.id] =
    reportPageParentDivElement.style.backgroundColor;
  reportPageParentDivElement.style.backgroundColor = 'none';

  const reportPageImageDivElement = await document.getElementById(
    `report-page-background-image-div-${reportPage.id}`
  );
  if (!reportPageImageDivElement) {
    return;
  }

  originalFilters[reportPage.id] = reportPageImageDivElement.style.filter;
  reportPageImageDivElement.style.filter = 'none';

  originalImageDataUris[reportPage.id] = reportPage.backgroundImageDataUri;

  originalBoxShadow[reportPage.id] = reportPageParentDivElement.style.boxShadow;

  reportPageParentDivElement.style.boxShadow = 'none';

  var reportPageBackgroundImgObj = document.createElement('img');
  reportPageBackgroundImgObj.src = await reportPage.backgroundImageDataUri;
  reportPageImageDivElement.style.backgroundImage = `url(${
    reportPage.backgroundImageDataUri &&
    (await formatImage({
      imgObj: reportPageBackgroundImgObj,
      backgroundColor: reportPage.backgroundColor,
      backgroundColorOpacity: 1,
      // isGrayscale: Boolean(reportPage.isGrayscale),
      grayScale: reportPage.backgroundImageGrayscaleFilter,
      blur: reportPage.backgroundImageBlurFilter,
      brightness: reportPage.backgroundImageBrightnessFilter,
      contrast: reportPage.backgroundImageContrastFilter,
      hueRotate: reportPage.backgroundImageHueRotateFilter,
      invert: reportPage.backgroundImageInvertFilter,
      opacity: reportPage.backgroundImageOpacityFilter,
      saturate: reportPage.backgroundImageSaturateFilter,
      sepia: reportPage.backgroundImageSepiaFilter,
      width: reportPage.width.split('px')[0],
      height: reportPage.height.split('px')[0],
      isBackground: true
    }))
  }`;

  for (let widget of reportPage.widgets) {
    if (widget.type === 'IMAGE_WIDGET') {
      if (['IMAGE_WIDGET'].includes(widget.type) && !widget.imageDataUri) {
        continue;
      }
      // background div
      var backgroundColorDivElement = document.getElementById(
        `imageWidget-background-color-div-${widget.id}`
      );
      console.log('backgroundColorDivElement: ', backgroundColorDivElement);
      backgroundColorDivElement.style.visibility = 'hidden';
      // backgroundColorDivElement.style.backgroundColor = undefined;
      // originalBackgroundColors[widget.id] = widget.backgroundColor;

      // console.log('widget.imageDataUri: ', widget.imageDataUri);
      // backgroundColorDivElement.style.backgroundColor = 'none';

      // image div
      const imageDivElement = document.getElementById(
        `imageWidget-${widget.id}`
      );
      originalImageDataUris[widget.id] = widget.imageDataUri;
      originalFilters[widget.id] = imageDivElement.style.filter;

      // console.log('widget.imageDataUri: ', widget.imageDataUri);

      var imgObj = document.createElement('img');

      imgObj.src = await widget.imageDataUri;

      imageDivElement.style.backgroundImage = `url(${
        formatImage({
          imgObj,
          backgroundColor: widget.imageBackgroundColor,
          backgroundColorOpacity: widget.imageBackgroundColorOpacity,
          grayScale: widget.imageGrayscaleFilter,
          blur: widget.imageBlurFilter,
          brightness: widget.imageBrightnessFilter,
          contrast: widget.imageContrastFilter,
          hueRotate: widget.imageHueRotateFilter,
          invert: widget.imageInvertFilter,
          opacity: widget.imageOpacityFilter,
          saturate: widget.imageSaturateFilter,
          sepia: widget.imageSepiaFilter,
          width: widget.width.split('px')[0],
          height: widget.height.split('px')[0],
          isBackground: false
        })
        // gray(imgObj)
        // widget.imageDataUri || widget.imageUrl
      })`;
    }
  }

  // ##############################################################

  let thumbnail = '';

  await html2canvas(reportPageParentDivElement, {
    scale: scale,
    // scale: window.devicePixelRatio,
    logging: true,
    // letterRendering: true, //scale,
    allowTaint: false,
    useCORS: true
  }).then((canvas) => {
    // var image = canvas.toDataURL('image/png', 1.0);
    var wid;
    var hgt;
    var image = canvas.toDataURL(
      'image/png',
      (wid = canvas.width),
      (hgt = canvas.height)
    );

    const fileName = 'NexusTable_export.pdf';

    thumbnail = image;
  });

  reportPageParentDivElement.style.boxShadow = originalBoxShadow[reportPage.id];

  reportPageParentDivElement.style.backgroundColor =
    originalBackgroundColors[reportPage.id];

  reportPageImageDivElement.style.filter = originalFilters[reportPage.id];

  // const reportPageImageDivElement_ = await document.getElementById(
  //   `report-page-background-image-div-${reportPage.id}`
  // );

  reportPageImageDivElement.style.backgroundImage = `url(${
    originalImageDataUris[reportPage.id]
  })`;

  for (let widget of reportPage.widgets) {
    if (widget.type === 'IMAGE_WIDGET') {
      if (['IMAGE_WIDGET'].includes(widget.type) && !widget.imageDataUri) {
        continue;
      }
      var backgroundColorDivElement = document.getElementById(
        `imageWidget-background-color-div-${widget.id}`
      );
      console.log('backgroundColorDivElement: ', backgroundColorDivElement);
      backgroundColorDivElement.style.visibility = 'visible';

      const divElement = await document.getElementById(
        `imageWidget-${widget.id}`
      );
      divElement.style.backgroundColor = originalBackgroundColors[widget.id];
      divElement.style.filter = originalFilters[widget.id];

      divElement.style.backgroundImage = `url(${
        originalImageDataUris[widget.id]
      })`;
    }
  }

  originalImageDataUris = {};

  return thumbnail;
};
