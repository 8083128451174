import { documentTypeToShortNameDict } from './../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

export const pieChart = ({
  reportPage,
  widget,
  listOfFilteredDataSourceRows
}) => {
  if (reportPage.isDataFrozen || widget.isDataFrozen) {
    return widget;
  }

  const metricKey = widget.metrics?.[0]?.metric;
  let widgetGroupByKey = '';

  switch (widget.dimensions[0]) {
    case 'documentType':
      widgetGroupByKey = 'documentType';
      break;
    case 'name':
      widgetGroupByKey = 'name';
      break;
  }
  const testGroupBy = Object.groupBy(
    listOfFilteredDataSourceRows,
    (row) => row[widgetGroupByKey]
  );

  // console.log('PieChartWidget>testGroupBy: ', testGroupBy);

  const dimensionKeys = Object.keys(testGroupBy);

  const newSeriesData = [];
  for (let dimensionKey of dimensionKeys) {
    let dimensionsValue = {};

    for (let row of testGroupBy[dimensionKey]) {
      // console.log('row123: ', row);
      for (let key of [...sumEvalFields, ...plannedEvalFields]) {
        dimensionsValue[key] =
          (dimensionsValue[key] || null) + (row[key] || null);
      }
    }
    // console.log('dimensionsValue: ', dimensionsValue);

    dimensionsValue = prepDataObject(dimensionsValue);
    let seriesData = {
      // label: documentTypeToNameDict[dimensionKey],
      value: dimensionsValue
    };

    switch (widgetGroupByKey) {
      case 'documentType':
        seriesData.label =
          documentTypeToShortNameDict?.[dimensionKey]?.slice(0, 15) +
          (documentTypeToShortNameDict?.[dimensionKey]?.length > 15
            ? '...'
            : '');
        //   +
        // (documentTypeToShortNameDict?.[dimensionKey]?.length < 15
        //   ? '   '
        //   : '');
        break;

      case 'name':
        seriesData.label =
          dimensionKey?.slice(0, 20) + (dimensionKey?.length > 20 ? '...' : '');
        break;
    }
    // console.log('seriesData.label: ', seriesData.label);
    newSeriesData.push(seriesData);
  }

  let totalValue = {};

  for (let row of newSeriesData) {
    for (let key of [...sumEvalFields, ...plannedEvalFields]) {
      totalValue[key] = (totalValue[key] || null) + (row.value[key] || null);
    }
  }

  totalValue = prepDataObject(totalValue);

  for (let row of newSeriesData) {
    row.share = Math.round(
      (row.value[metricKey] / totalValue[metricKey]) * 100
    );
    row.value = Math.round(row.value[metricKey]);
  }
  // console.log('totalValue: ', totalValue);

  let copyOfWidget = { ...widget };
  copyOfWidget = {
    ...copyOfWidget,
    data: newSeriesData
  };

  return copyOfWidget;
};
