import React, { useContext, useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

import { MutationContext } from '../../../../context/MutationContext';

import { AccountContext } from '../../../../context/AccountContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { DialogContext } from '../../../../context/DialogContext';

import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';

import DeleteIcon from '@mui/icons-material/Delete';

import CropDinIcon from '@mui/icons-material/CropDin';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Crop75Icon from '@mui/icons-material/Crop75';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

import DateTimePicker from './DateTimePicker';

import WidgetSliderMenuItem from './widget-menu-items/SliderMenuItem';
import ReportPageSliderMenuItem from './report-page-menu-items/SliderMenuItem';

import DividerWidthTitle from './Divider';

import ColorPickerPopper2 from './widget-menu-items/ColorPickerPopper2';
import ColorPickerPopper3 from './report-page-menu-items/ColorPickerPopper3';

import WidgetBooleanButton from './widget-menu-items/BooleanButton';
import ReportPageBooleanButton from './report-page-menu-items/BooleanButton';

import ColorPaletteMenu from './color-palette-menu/ColorPaletteMenu';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import SelectAllIcon from '@mui/icons-material/SelectAll';

const widgetOptionDictionary = {
  titleFontColor: 'Title Font Color',
  dataLabelFontColor: 'Data Label Font Color',
  textFontColor: 'Text Font Color',
  valueFontColor: 'Value Font Color',
  legendFontColor: 'Legend Font Color',
  leftAxisFontColor: 'Left Axis Font Color',
  bottomAxisFontColor: 'Bottom Axis Font Color',
  rightAxisFontColor: 'Right Axis Font Color',

  outerBorderColor: 'Outer Border Color',
  leftAxisLineColor: 'Left Axis Line Color',
  bottomAxisLineColor: 'Bottom Axis Line Color',
  rightAxisLineColor: 'Right Axis Line Color',
  leftValGridLineColor: 'Left Value Grid Line Color',
  rightValGridLineColor: 'Right Value Grid Line Color',
  chartBorderColor: 'Chart Border Color',

  cardFillColor: 'Card Fill Color',
  titleFillColor: 'Title Fill Color',
  shapeFillColor: 'Shape Fill Color',

  titleFontSize: 'Title Font Size',
  textFontSize: 'Text Font Size',
  valueFontSize: 'Value Font Size',
  dataLabelFontSize: 'Data Label Font Size',
  legendFontSize: 'Legend Font Size',
  leftAxisFontSize: 'Left Axis Font Size',
  bottomAxisFontSize: 'Bottom Axis Font Size',
  rightAxisFontSize: ' Right Axis Font Size',

  isTitleFontBold: 'Title Font Bold',
  isTextFontBold: 'Text Font Bold',
  isValueFontBold: 'Value Font Bold',
  isDataLabelFontBold: 'Data Label Font Bold',
  isLegendFontBold: 'Legend Font Bold',
  isLeftAxisFontBold: 'Left Axis Font Bold',
  isBottomAxisFontBold: 'Bottom Axis Font Bold',
  isRightAxisFontBold: 'Right Axis Font Bold',

  isTitleFontItalic: 'Title Font Italic',
  isTextFontItalic: 'Text Font Italic',
  isValueFontItalic: 'Value Font Italic',
  isDataLabelFontItalic: 'Data Label Font Italic',
  isLegendFontItalic: 'Legend Font Italic',
  isLeftAxisFontItalic: 'Left Axis Font Italic',
  isBottomAxisFontItalic: 'Bottom Axis Font Italic',
  isRightAxisFontItalic: 'Right Axis Font Italic',

  titleFontFamily: 'Title Font Family',
  textFontFamily: 'Text Font Family',
  valueFontFamily: 'Value Font Family',
  dataLabelFontFamily: 'Data Label Font Family',
  legendFontFamily: 'Legend Font Family',
  leftAxisFontFamily: 'Left Axis Font Family',
  bottomAxisFontFamily: 'Bottom Axis Font Family',
  rightAxisFontFamily: 'Right Axis Font Family',

  horizontalTextAlign: 'Horizontal Text Align',
  verticalTextAlign: 'Vertical Text Align',

  outerBorderStyle: 'Outer Border Style',

  outerBorderWidth: 'Outer Border Width',
  chartBorderWidth: 'Chart Border Width',

  leftAxisLineWidth: 'Left Axis Line Width',
  bottomAxisLineWidth: 'Bottom Axis Line Width',
  rightAxisLineWidth: 'Right Axis Line Width',

  leftValGridLineWidth: 'Left Value Grid Line Size',
  rightValGridLineWidth: 'Right Calue Grid Line Size',

  outerBorderRadius: 'Outer Border Radius',

  textFontOpacity: 'Text Font Opacity',
  cardFillOpacity: 'Card Fill Opacity',
  shapeFillOpacity: 'Shape Fill Opacity',
  // titleFillOpacity: 'Title Fill Opacity',
  chartFillOpacity: 'Chart Fill Opacity',
  outerBorderOpacity: 'Outer Border Opacity',
  leftAxisLineOpacity: 'Left Axis Line Opacity',
  bottomAxisLineOpacity: 'Bottom Axis Line Opacity',
  rightAxisLineOpacity: 'Right Axis Line Opacity',
  // imageOpacity: 'Image Opacity',

  backgroundImageGrayscaleFilter: 'Gray Scale',
  backgroundImageBlurFilter: 'Blur',
  backgroundImageBrightnessFilter: 'Brightness',
  backgroundImageContrastFilter: 'Contrast',
  backgroundImageHueRotateFilter: 'Hue-Rotation',
  backgroundImageInvertFilter: 'Invert',
  backgroundImageOpacityFilter: 'Opacity',
  backgroundImageSaturateFilter: 'Saturation',
  backgroundImageSepiaFilter: 'Sepia',

  imageBackgroundColor: 'Background Color',
  imageBackgroundColorOpacity: 'Background color opacity',
  imageGrayscaleFilter: 'Image Gray Scale',
  imageBlurFilter: 'Blur',
  imageBrightnessFilter: 'Brightness',
  imageContrastFilter: 'Contrast',
  imageHueRotateFilter: 'Hue Rotation',
  imageInvertFilter: 'Invert',
  imageOpacityFilter: 'Opacity',
  imageSaturateFilter: 'Saturation',
  imageSepiaFilter: 'Sepia',

  mockupImageGrayscaleFilter: 'Image Gray Scale',
  mockupImageBlurFilter: 'Blur',
  mockupImageBrightnessFilter: 'Brightness',
  mockupImageContrastFilter: 'Contrast',
  mockupImageHueRotateFilter: 'Hue Rotation',
  mockupImageInvertFilter: 'Invert',
  mockupImageOpacityFilter: 'Opacity',
  mockupImageSaturateFilter: 'Saturation',
  mockupImageSepiaFilter: 'Sepia',

  // isGrayscale: 'Gray Scale',
  isCardShadowOn: 'Card Shadow',
  isDropShadowOn: 'Drop Shadow',
  isShowingCard: 'Show Card',
  isShowingTitle: 'Show Title',
  isShowingComparison: 'Show Comparison',
  isShowingLegend: 'Show Legend'
};

export default function DenseMenu({
  mouseDownWidgetIds,
  // selectedUser,
  // widgetIdThatTriggeredContextMenu,
  // setWidgetIdThatTriggeredContextMenu,
  // widgets,
  setContextMenuAnchorPosition,
  handleClose
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    selectedPageId,
    widgets,
    setWidgets,
    widgetIdThatTriggeredContextMenu,
    widgetElementThatTriggeredContextMenu,
    setWidgetElementThatTriggeredContextMenu,
    updateWidget,
    isAxisMenuOpen,
    toggleIsAxisSettingsOpen,
    inputImageTriggerCounter,
    setInputImageTriggerCounter,
    copiedWidgets,
    setCopiedWidgets,
    selectedWidgetIds,
    setSelectedWidgetIds
  } = useContext(ReportBuilderContext);

  const { reportBuilderAssetDialogOpen, setReportBuilderAssetDialogOpen } =
    useContext(DialogContext);

  // console.log('copiedWidgets: ', copiedWidgets);

  // console.log('test4467>widgets: ', widgets);
  // console.log(
  //   'debug85>widgetIdThatTriggeredContextMenu: ',
  //   widgetIdThatTriggeredContextMenu
  // );

  const [widget, setWidget] = useState({});
  // console.log('widget: ', widget);

  let BooleanButton = <></>;
  let SliderMenuItem = <></>;
  switch (true) {
    case widgetElementThatTriggeredContextMenu.includes('report-builder-page'):
      BooleanButton = ReportPageBooleanButton;
      SliderMenuItem = ReportPageSliderMenuItem;
      break;
    default:
      BooleanButton = WidgetBooleanButton;
      SliderMenuItem = WidgetSliderMenuItem;
  }

  useEffect(() => {
    const widget = widgets.filter(
      (widget) => widget.id === widgetIdThatTriggeredContextMenu
    )[0];
    setWidget(widget);
  }, [widgets, widgetIdThatTriggeredContextMenu]);
  // console.log('ReportBuilderContextMenuContent>widget: ', widget);
  const {
    addWidgets,
    updateWidgets,
    refetchWidgetsCounter,
    setRefetchWidgetsCounter
  } = useContext(MutationContext);
  let statuses = [];

  switch (true) {
    case widget?.status === 'TIMEBOX_LIST':
      statuses = [{ name: 'Removed', value: 'REMOVED' }];
      break;
    case [
      'UNDEFINED',
      'DRAFT',
      'ACTIVE',
      'COMPLETED',
      'NOT_COMPLETED'
    ].includes(widget?.status):
      statuses = [
        { name: 'Undefined', value: 'UNDEFINED' },
        // { name: 'WidgetList', value: 'TIMEBOX_LIST' },
        // { name: 'Draft', value: 'DRAFT' },
        { name: 'Active', value: 'ACTIVE' },
        { name: 'Completed', value: 'COMPLETED' },
        // { name: 'Not completed', value: 'NOT_COMPLETED' },
        { name: 'Removed', value: 'REMOVED' }
      ];
      break;
  }

  const onClickMenuItem = async (e, action, value) => {
    var selectedWidgetIds_ = selectedWidgetIds.includes(
      widgetIdThatTriggeredContextMenu
    )
      ? selectedWidgetIds
      : [widgetIdThatTriggeredContextMenu];
    var copyOfWidgets = (widgets || []).map((obj) =>
      JSON.parse(JSON.stringify(obj))
    );
    switch (action) {
      // case 'CHANGE_STATUS':
      //   var input = {
      //     managerAccountId: currentManagerAccount._id,
      //     // selectedUserId: selectedUser?._id,
      //     updateKeys: ['status'],
      //     // widgets: [{ _id: widgetIdThatTriggeredContextMenu, status: value }]
      //     widgets: mouseDownWidgetIds.length
      //       ? mouseDownWidgetIds.map((widgetId) => ({
      //           _id: widgetId,
      //           status: value
      //         }))
      //       : [{ _id: widgetIdThatTriggeredContextMenu, status: value }]
      //   };

      //   console.log('update!!!');

      //   await updateWidgets({ variables: { input } });
      //   setContextMenuAnchorPosition(null);
      //   break;

      // case 'DELETE':
      // var input = {
      //   managerAccountId: currentManagerAccount._id,
      //   // selectedUserId: selectedUser?._id,
      //   updateKeys: ['nexusDocumentIds', 'accountId'],

      //   widgets: mouseDownWidgetIds.length
      //     ? mouseDownWidgetIds.map((widgetId) => ({
      //         _id: widgetId,
      //         accountId: null,
      //         accountIds: [],
      //         nexusDocumentIds: []
      //       }))
      //     : [
      //         {
      //           _id: widgetIdThatTriggeredContextMenu,
      //           accountId: null,
      //           accountIds: [],
      //           nexusDocumentIds: []
      //         }
      //       ]
      // };

      // console.log('update!!!');

      // await updateWidgets({ variables: { input } });
      // setContextMenuAnchorPosition(null);

      case 'SELECT_ALL_WIDGETS':
        setSelectedWidgetIds(widgets.map((wid) => wid.id));
        handleClose();
        break;

      case 'COPY':
        // var selectedWidgetIds_ = selectedWidgetIds.includes(
        //   widgetIdThatTriggeredContextMenu
        // )
        //   ? selectedWidgetIds
        //   : [widgetIdThatTriggeredContextMenu];
        var copyOfWidgets = (widgets || []).map((obj) =>
          JSON.parse(JSON.stringify(obj))
        );

        var widgetsToCopy = copyOfWidgets.filter((wid) =>
          selectedWidgetIds_.includes(wid.id)
        );
        // let newWidgets = [];
        // for (let widgetToCopy of widgetsToCopy) {
        //   widgetToCopy.id = uuidv4();
        //   delete widgetToCopy._id;
        // }

        setCopiedWidgets(widgetsToCopy);
        handleClose();
        break;

      case 'PASTE':
        var widgetsToCopy = (copiedWidgets || []).map((obj) =>
          JSON.parse(JSON.stringify(obj))
        );
        // let newWidgets = [];
        for (let widgetToCopy of widgetsToCopy) {
          widgetToCopy.id = uuidv4();
          delete widgetToCopy._id;
          widgetToCopy.metrics = (widgetToCopy.metrics || []).map(
            (metricObj) => {
              let newMetricObj = { ...metricObj };
              newMetricObj.id = uuidv4();
              delete newMetricObj._id;
              return newMetricObj;
            }
          );
        }

        setWidgets([...widgets, ...widgetsToCopy]);
        setSelectedWidgetIds(widgetsToCopy.map((wid) => wid.id));
        handleClose();
        break;

      case 'DUPLICATE':
        // var selectedWidgetIds_ = selectedWidgetIds.includes(
        //   widgetIdThatTriggeredContextMenu
        // )
        //   ? selectedWidgetIds
        //   : [widgetIdThatTriggeredContextMenu];
        var copyOfWidgets = (widgets || []).map((obj) =>
          JSON.parse(JSON.stringify(obj))
        );

        var widgetsToCopy = copyOfWidgets.filter((wid) =>
          selectedWidgetIds_.includes(wid.id)
        );

        // let newWidgets = [];
        for (let widgetToCopy of widgetsToCopy) {
          widgetToCopy.id = uuidv4();
          delete widgetToCopy._id;
          widgetToCopy.metrics = (widgetToCopy.metrics || []).map(
            (metricObj) => {
              let newMetricObj = { ...metricObj };
              newMetricObj.id = uuidv4();
              delete newMetricObj._id;
              return newMetricObj;
            }
          );
        }

        setWidgets([...widgets, ...widgetsToCopy]);
        setSelectedWidgetIds(widgetsToCopy.map((wid) => wid.id));

        handleClose();
        break;

      case 'UPDATE_WIDGET':
        var copyOfWidgets = [...widgets];
        var index = copyOfWidgets.findIndex(
          (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        );
        updateWidget({ ...widget, [value]: !widget?.[value] }, index);
        handleClose();
        break;

      case 'ASSET_LIBRARY':
        setReportBuilderAssetDialogOpen(true);
        handleClose();
        break;

      case 'UPLOAD_IMAGE':
        setInputImageTriggerCounter(inputImageTriggerCounter + 1);

        handleClose();
        break;

      // case 'TOGGLE_TITLE':
      //   // var copyOfWidgets = [...widgets];
      //   // var index = copyOfWidgets.findIndex(
      //   //   (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
      //   // );
      //   // updateWidget(
      //   //   { ...widget, isShowingTitle: !widget.isShowingTitle },
      //   //   index
      //   // );

      //   for (let copyOfWidget of copyOfWidgets) {
      //     if (selectedWidgetIds_.includes(copyOfWidget.id)) {
      //       copyOfWidget.isShowingTitle = !widget.isShowingTitle;
      //     }
      //   }
      //   setWidgets(copyOfWidgets);

      //   handleClose();
      //   break;

      // case 'TOGGLE_CARD_SHADOW':
      //   // var copyOfWidgets = [...widgets];
      //   // var index = copyOfWidgets.findIndex(
      //   //   (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
      //   // );
      //   // updateWidget(
      //   //   { ...widget, isCardShadowOn: !widget.isCardShadowOn },
      //   //   index
      //   // );

      //   for (let copyOfWidget of copyOfWidgets) {
      //     if (selectedWidgetIds_.includes(copyOfWidget.id)) {
      //       copyOfWidget.isCardShadowOn = !widget.isCardShadowOn;
      //     }
      //   }
      //   setWidgets(copyOfWidgets);
      //   handleClose();
      //   break;

      // case 'TOGGLE_SHADOW':
      //   // var copyOfWidgets = [...widgets];
      //   // var index = copyOfWidgets.findIndex(
      //   //   (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
      //   // );
      //   // updateWidget(
      //   //   { ...widget, isDropShadowOn: !widget.isDropShadowOn },
      //   //   index
      //   // );
      //   for (let copyOfWidget of copyOfWidgets) {
      //     if (selectedWidgetIds_.includes(copyOfWidget.id)) {
      //       copyOfWidget.isDropShadowOn = !widget.isDropShadowOn;
      //     }
      //   }
      //   setWidgets(copyOfWidgets);
      //   handleClose();
      //   break;

      // case 'TOGGLE_CARD':
      //   // var copyOfWidgets = [...widgets];
      //   // var index = copyOfWidgets.findIndex(
      //   //   (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
      //   // );
      //   // updateWidget(
      //   //   { ...widget, isShowingCard: !widget.isShowingCard },
      //   //   index
      //   // );

      //   for (let copyOfWidget of copyOfWidgets) {
      //     if (selectedWidgetIds_.includes(copyOfWidget.id)) {
      //       copyOfWidget.isShowingCard = !widget.isShowingCard;
      //     }
      //   }
      //   setWidgets(copyOfWidgets);
      //   handleClose();
      //   break;

      // case 'TOGGLE_GRAY_SCALE':
      //   // var copyOfWidgets = [...widgets];
      //   // var index = copyOfWidgets.findIndex(
      //   //   (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
      //   // );
      //   // updateWidget({ ...widget, isGrayscale: !widget.isGrayscale }, index);

      //   for (let copyOfWidget of copyOfWidgets) {
      //     if (selectedWidgetIds_.includes(copyOfWidget.id)) {
      //       copyOfWidget.isGrayscale = !widget.isGrayscale;
      //     }
      //   }
      //   setWidgets(copyOfWidgets);
      //   handleClose();
      //   break;

      case 'TOGGLE_FREEZE_DATA':
        for (let copyOfWidget of copyOfWidgets) {
          if (selectedWidgetIds_.includes(copyOfWidget.id)) {
            copyOfWidget.isDataFrozen = !widget.isDataFrozen;
          }
        }
        setWidgets(copyOfWidgets);

        handleClose();
        break;

      case 'TOGGLE_FREEZE_COMPARISON_DATA':
        for (let copyOfWidget of copyOfWidgets) {
          if (selectedWidgetIds_.includes(copyOfWidget.id)) {
            copyOfWidget.isComparisonDataFrozen =
              !widget.isComparisonDataFrozen;
          }
        }
        setWidgets(copyOfWidgets);

        handleClose();
        break;
      case 'TOGGLE_AXIS_SETTINGS':
        toggleIsAxisSettingsOpen(widget.id);
        handleClose();
        break;

      case 'BRING_TO_FRONT':
        // var copyOfWidgets = [...widgets];

        let widgetsToBringToFront = copyOfWidgets.filter((wid) =>
          selectedWidgetIds_.includes(wid.id)
        );
        copyOfWidgets = copyOfWidgets.filter(
          (wid) => !selectedWidgetIds_.includes(wid.id)
        );

        copyOfWidgets = [...copyOfWidgets, ...widgetsToBringToFront];

        // var index = copyOfWidgets.findIndex(
        //   (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        // );

        // console.log('index: ', index);
        // copyOfWidgets.splice(index, 1);
        // copyOfWidgets.push(widget);

        setWidgets(copyOfWidgets);
        handleClose();
        break;
      case 'SEND_TO_BACK':
        // var copyOfWidgets = [...widgets];

        let widgetsToSendToBack = copyOfWidgets.filter((wid) =>
          selectedWidgetIds_.includes(wid.id)
        );
        copyOfWidgets = copyOfWidgets.filter(
          (wid) => !selectedWidgetIds_.includes(wid.id)
        );

        copyOfWidgets = [...widgetsToSendToBack, ...copyOfWidgets];
        // var index = copyOfWidgets.findIndex(
        //   (widget_) => widget_.id === widgetIdThatTriggeredContextMenu
        // );

        // console.log('index: ', index);
        // copyOfWidgets.splice(index, 1);
        // copyOfWidgets.unshift(widget);
        setWidgets(copyOfWidgets);
        handleClose();
        break;
      case 'DELETE':
        var copyOfWidgets = [...widgets];
        copyOfWidgets = copyOfWidgets.filter(
          (wid) => !selectedWidgetIds_.includes(wid.id)
        );

        // console.log('index: ', index);
        // copyOfWidgets.splice(index, 1);
        setWidgets(copyOfWidgets);
        handleClose();
        break;
    }
  };

  const [arrayMenuItems, setArrayOfMenuItems] = useState([]);
  useEffect(() => {
    let newArrayOfMenuIems = [];
    console.log(
      'widgetElementThatTriggeredContextMenu: ',
      widgetElementThatTriggeredContextMenu
    );
    switch (true) {
      case widgetElementThatTriggeredContextMenu
        ?.toLowerCase()
        ?.includes('legend'):
        setArrayOfMenuItems([
          'isShowingLegend',
          'legendFontSize',
          'legendFontColor'
          // 'isLegendFontItalic',
          // 'isLegendFontBold'
        ]);
        break;

      case widgetElementThatTriggeredContextMenu?.includes(
        'MuiChartsSurface-root'
      ) &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          'TEXT_WIDGET'
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          // 'COMBO_CHART_WIDGET',
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        setArrayOfMenuItems([
          // 'isShowingTitle',
          'isShowingCard',
          // 'isDropShadowOn',
          'isCardShadowOn',
          'outerBorderRadius'
        ]);
        break;

      case widgetElementThatTriggeredContextMenu?.includes('widget-value') &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          'NUMBER_WIDGET'
          // 'LINE_CHART_WIDGET',
          // 'COMBO_CHART_WIDGET',
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        setArrayOfMenuItems([
          // 'isShowingTitle',
          // 'isShowingCard',
          // 'isDropShadowOn',
          // 'isCardShadowOn',
          // 'outerBorderRadius',
          // 'cardFillColor',
          // 'cardFillOpacity',
          // 'outerBorderColor',
          // 'outerBorderWidth',
          // 'outerBorderStyle',
          // 'outerBorderOpacity'
          'valueFontSize',
          'valueFontColor',
          'isValueFontItalic',
          'isValueFontBold',
          'valueFontFamily'
        ]);
        break;

      case widgetElementThatTriggeredContextMenu?.includes(
        'number-widget-surface'
      ) &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          'NUMBER_WIDGET'
          // 'LINE_CHART_WIDGET',
          // 'COMBO_CHART_WIDGET',
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        setArrayOfMenuItems([
          'isShowingTitle',
          'isShowingComparison',
          'isShowingCard',
          // 'isDropShadowOn',
          'isCardShadowOn',
          'outerBorderRadius',
          'cardFillColor',
          // 'textFontOpacity',
          'cardFillOpacity',
          'outerBorderColor',
          'outerBorderWidth',
          'outerBorderStyle',
          'outerBorderOpacity'
        ]);
        break;

      case widgetElementThatTriggeredContextMenu?.includes('MuiPieArc-root') &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          // 'COMBO_CHART_WIDGET',
          'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        setArrayOfMenuItems([
          // 'isShowingTitle',
          // 'isShowingLegend',
          // 'isShowingCard',
          'dataLabelFontSize',
          'isDataLabelFontBold',
          'isDataLabelFontItalic',
          'dataLabelFontColor',
          'dataLabelFontFamily',
          'isDropShadowOn',
          'chartFillColors',
          'chartBorderColor',

          'chartBorderWidth'

          // 'isCardShadowOn',
          // 'outerBorderRadius'
        ]);
        break;

      // case (widgetElementThatTriggeredContextMenu?.includes('MuiBarElement') ||
      //   widgetElementThatTriggeredContextMenu?.includes('MuiChartsAxis-line') ||
      //   widgetElementThatTriggeredContextMenu?.includes('tspan')) &&
      //   [
      //     // 'IMAGE_WIDGET',
      //     // 'MOCKUP_WIDGET',
      //     // 'MANAGER_ACCOUNT_LOGO_WIDGET',
      //     // 'TEXT_WIDGET',
      //     // 'NUMBER_WIDGET',
      //     // 'LINE_CHART_WIDGET',
      //     'COMBO_CHART_WIDGET'
      //     // 'PIE_CHART_WIDGET'
      //   ].includes(widget?.type):
      //   setArrayOfMenuItems([
      //     // 'isShowingTitle',
      //     // 'isShowingLegend',
      //     // 'isShowingCard',
      //     // 'dataLabelFontSize',
      //     // 'isDataLabelBold',
      //     // 'isDataLabelItalic',
      //     // 'dataLabelFontColor',
      //     // 'dataLabelFontFamily',
      //     'leftAxisFontFamily',
      //     'bottomAxisFontFamily',
      //     'rightAxisFontFamily',
      //     'leftAxisFontSize',
      //     'bottomAxisFontSize',
      //     'rightAxisFontSize',

      //     'isLeftAxisFontBold',
      //     'isBottomAxisFontBold',
      //     'isRightAxisFontBold',

      //     'leftAxisLineColor',
      //     'bottomAxisLineColor',
      //     'rightAxisLineColor',
      //     'leftValGridLineColor',
      //     'rightValGridLineColor',
      //     'chartBorderColor',

      //     'chartBorderWidth',

      //     'leftAxisLineWidth',
      //     'bottomAxisLineWidth',
      //     'rightAxisLineWidth',

      //     'isDropShadowOn',
      //     'chartFillColors'

      //     // 'isCardShadowOn',
      //     // 'outerBorderRadius'
      //   ]);
      //   break;

      case widgetElementThatTriggeredContextMenu === 'leftAxis' &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        setArrayOfMenuItems([
          // 'isShowingTitle',
          // 'isShowingLegend',
          // 'isShowingCard',
          // 'dataLabelFontSize',
          // 'isDataLabelBold',
          // 'isDataLabelItalic',
          // 'dataLabelFontColor',
          // 'dataLabelFontFamily',
          'leftAxisFontFamily',
          // 'bottomAxisFontFamily',
          // 'rightAxisFontFamily',
          'leftAxisFontSize',
          // 'bottomAxisFontSize',
          // 'rightAxisFontSize',

          'isLeftAxisFontBold',
          // 'isBottomAxisFontBold',
          // 'isRightAxisFontBold',

          'isLeftAxisFontItalic',

          'leftAxisLineColor',
          // 'bottomAxisLineColor',
          // 'rightAxisLineColor',
          'leftValGridLineColor',
          // 'rightValGridLineColor',
          // 'chartBorderColor',

          // 'chartBorderWidth',

          'leftAxisLineWidth'
          // 'bottomAxisLineWidth',
          // 'rightAxisLineWidth',

          // 'isDropShadowOn',
          // 'chartFillColors'

          // 'isCardShadowOn',
          // 'outerBorderRadius'
        ]);
        break;

      case widgetElementThatTriggeredContextMenu === 'bottomAxis' &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        console.log(
          'widgetElementThatTriggeredContextMenu: ',
          widgetElementThatTriggeredContextMenu
        );
        setArrayOfMenuItems([
          // 'isShowingTitle',
          // 'isShowingLegend',
          // 'isShowingCard',
          // 'dataLabelFontSize',
          // 'isDataLabelBold',
          // 'isDataLabelItalic',
          // 'dataLabelFontColor',
          // 'dataLabelFontFamily',
          // 'leftAxisFontFamily',
          'bottomAxisFontFamily',
          // 'rightAxisFontFamily',
          // 'leftAxisFontSize',
          'bottomAxisFontSize',
          // 'rightAxisFontSize',

          // 'isLeftAxisFontBold',
          'isBottomAxisFontBold',
          // 'isRightAxisFontBold',

          // 'isLeftAxisFontItalic',
          'isBottomAxisFontItalic',
          // 'isRightAxisFontItalic',

          // 'leftAxisLineColor',
          'bottomAxisLineColor',
          // 'rightAxisLineColor',
          // 'leftValGridLineColor',
          // 'rightValGridLineColor',
          // 'chartBorderColor',

          // 'chartBorderWidth',

          // 'leftAxisLineWidth'
          'bottomAxisLineWidth'
          // 'rightAxisLineWidth',

          // 'isDropShadowOn',
          // 'chartFillColors'

          // 'isCardShadowOn',
          // 'outerBorderRadius'
        ]);
        break;

      case widgetElementThatTriggeredContextMenu === 'rightAxis' &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        setArrayOfMenuItems([
          // 'isShowingTitle',
          // 'isShowingLegend',
          // 'isShowingCard',
          // 'dataLabelFontSize',
          // 'isDataLabelBold',
          // 'isDataLabelItalic',
          // 'dataLabelFontColor',
          // 'dataLabelFontFamily',
          // 'leftAxisFontFamily',
          // 'bottomAxisFontFamily',
          'rightAxisFontFamily',
          // 'leftAxisFontSize',
          // 'bottomAxisFontSize',
          'rightAxisFontSize',

          // 'isLeftAxisFontBold',
          // 'isBottomAxisFontBold',
          'isRightAxisFontBold',

          // 'isLeftAxisFontItalic',
          // 'isBottomAxisFontItalic',
          'isRightAxisFontItalic',

          // 'leftAxisLineColor',
          // 'bottomAxisLineColor',
          'rightAxisLineColor',
          // 'leftValGridLineColor',
          // 'rightValGridLineColor',
          // 'chartBorderColor',

          // 'chartBorderWidth',

          // 'leftAxisLineWidth'
          // 'bottomAxisLineWidth'
          'rightAxisLineWidth'

          // 'isDropShadowOn',
          // 'chartFillColors'

          // 'isCardShadowOn',
          // 'outerBorderRadius',
        ]);
        break;

      case widgetElementThatTriggeredContextMenu.includes(
        'MuiBarElement-root'
      ) &&
        [
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type):
        setArrayOfMenuItems([
          // 'isShowingTitle',
          // 'isShowingLegend',
          // 'isShowingCard',
          // 'dataLabelFontSize',
          // 'isDataLabelBold',
          // 'isDataLabelItalic',
          // 'dataLabelFontColor',
          // 'dataLabelFontFamily',
          // 'leftAxisFontFamily',
          // 'bottomAxisFontFamily',
          // 'rightAxisFontFamily',
          // 'leftAxisFontSize',
          // 'bottomAxisFontSize',
          // 'rightAxisFontSize',
          // 'isLeftAxisFontBold',
          // 'isBottomAxisFontBold',
          // 'isRightAxisFontBold',
          // 'isLeftAxisFontItalic',
          // 'isBottomAxisFontItalic',
          // 'isRightAxisFontItalic',
          // 'leftAxisLineColor',
          // 'bottomAxisLineColor',
          // 'rightAxisLineColor',
          // 'leftValGridLineColor',
          // 'rightValGridLineColor',
          'chartBorderColor',
          'chartBorderWidth',
          // 'leftAxisLineWidth'
          // 'bottomAxisLineWidth'
          // 'rightAxisLineWidth'
          // 'isDropShadowOn',
          'chartFillColors'
          // 'isCardShadowOn',
          // 'outerBorderRadius'
        ]);
        break;

      case ['IMAGE_WIDGET'].includes(widgetElementThatTriggeredContextMenu):
        setArrayOfMenuItems([
          'imageBackgroundColor',
          'imageBackgroundColorOpacity',
          'imageGrayscaleFilter',
          'imageBlurFilter',
          'imageBrightnessFilter',
          'imageContrastFilter',
          'imageHueRotateFilter',
          'imageInvertFilter',
          'imageOpacityFilter',
          'imageSaturateFilter',
          'imageSepiaFilter',

          'isDropShadowOn',
          'outerBorderRadius'
        ]);
        break;
      case ['MOCKUP_WIDGET'].includes(widgetElementThatTriggeredContextMenu):
        setArrayOfMenuItems([
          'imageBackgroundColor',
          'imageBackgroundColorOpacity',
          'imageGrayscaleFilter',
          'imageBlurFilter',
          'imageBrightnessFilter',
          'imageContrastFilter',
          'imageHueRotateFilter',
          'imageInvertFilter',
          'imageOpacityFilter',
          'imageSaturateFilter',
          'imageSepiaFilter',

          'mockupImageBackgroundColor',
          'mockupImageBackgroundOpacity',
          'mockupImageGrayscaleFilter',
          'mockupImageBlurFilter',
          'mockupImageBrightnessFilter',
          'mockupImageContrastFilter',
          'mockupImageHueRotateFilter',
          'mockupImageInvertFilter',
          'mockupImageOpacityFilter',
          'mockupImageSaturateFilter',
          'mockupImageSepiaFilter',

          'isDropShadowOn'
        ]);
        break;
      case ['TEXT_WIDGET'].includes(widgetElementThatTriggeredContextMenu):
        setArrayOfMenuItems([
          'isShowingCard',
          'isCardShadowOn',
          'textFontSize',
          'textFontFamily',
          'isTextFontBold',
          'isTextFontItalic',
          'textFontColor',

          'outerBorderRadius',
          'cardFillColor',
          'textFontOpacity',
          'cardFillOpacity',
          'outerBorderColor',
          'outerBorderWidth',
          'outerBorderStyle',
          'outerBorderOpacity'
        ]);
        break;

      case ['RECTANGLE_SHAPE_WIDGET'].includes(
        widgetElementThatTriggeredContextMenu
      ):
        setArrayOfMenuItems([
          'shapeFillColor',
          'shapeFillOpacity',
          'outerBorderRadius',
          'isDropShadowOn',
          // 'isShowingCard',
          // 'isCardShadowOn',
          // 'textFontSize',
          // 'textFontFamily',
          // 'isTextFontBold',
          // 'isTextFontItalic',
          // 'textFontColor',
          'outerBorderColor',
          'outerBorderWidth',
          'outerBorderStyle',
          'outerBorderOpacity'
        ]);
        break;

      case ['widget-title'].includes(widgetElementThatTriggeredContextMenu):
        setArrayOfMenuItems([
          'isShowingTitle',
          'titleFontSize',
          'titleFontFamily',
          'titleFontColor',
          'isTitleFontBold',
          'isTitleFontItalic'
          // 'titleFillOpacity',
          // 'titleFillColor'
        ]);
        break;

      case [
        // 'IMAGE_WIDGET',
        // 'MOCKUP_WIDGET',
        // 'MANAGER_ACCOUNT_LOGO_WIDGET',
        // 'TEXT_WIDGET',
        // 'NUMBER_WIDGET',
        // 'LINE_CHART_WIDGET',
        'COMBO_CHART_WIDGET',
        'PIE_CHART_WIDGET'
      ].includes(widget?.type):
        setArrayOfMenuItems([
          'isShowingTitle',
          'isShowingComparison',
          'isShowingLegend',
          'isShowingCard',
          // 'isDropShadowOn',
          'isCardShadowOn',
          'outerBorderRadius',
          'cardFillColor',
          'cardFillOpacity',
          'outerBorderWidth',
          'outerBorderStyle',
          'outerBorderColor'
        ]);
        break;
      case widgetElementThatTriggeredContextMenu === 'report-builder-page':
        setArrayOfMenuItems([
          // 'isGrayscale',

          'backgroundImageGrayscaleFilter',
          'backgroundImageBlurFilter',
          'backgroundImageBrightnessFilter',
          'backgroundImageContrastFilter',
          'backgroundImageHueRotateFilter',
          'backgroundImageInvertFilter',
          'backgroundImageOpacityFilter',
          'backgroundImageSaturateFilter',
          'backgroundImageSepiaFilter'
        ]);
        break;
    }
  }, [
    widgetElementThatTriggeredContextMenu,
    widgetIdThatTriggeredContextMenu,
    widget?.type
  ]);

  console.log(
    'widgetElementThatTriggeredContextMenu: ',
    widgetElementThatTriggeredContextMenu
  );

  // if (widgetElementThatTriggeredContextMenu === 'report-builder-page') {
  //   return (
  //     <Paper sx={{ width: 200 }}>
  //       <MenuList dense>
  //         <>
  //           <MenuItem onClick={(e) => onClickMenuItem(e, 'SELECT_ALL_WIDGETS')}>
  //             <ListItemIcon>
  //               <SelectAllIcon />
  //             </ListItemIcon>
  //             <ListItemText>Select all widgets</ListItemText>
  //           </MenuItem>
  //           <Divider />
  //         </>
  //         {copiedWidgets?.length ? (
  //           <>
  //             <MenuItem onClick={(e) => onClickMenuItem(e, 'PASTE')}>
  //               <ListItemIcon>
  //                 <ContentPasteIcon />
  //               </ListItemIcon>
  //               <ListItemText>Paste</ListItemText>
  //             </MenuItem>
  //             <Divider />
  //           </>
  //         ) : (
  //           <></>
  //         )}
  //         <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
  //           {/* <Button>test</Button> */}
  //           <ColorPickerPopper3 // colorpicker for report page background
  //             // widget={widget}
  //             valueKey={'backgroundColor'}
  //             reportPageId={selectedPageId}
  //             handleClose={handleClose}
  //           />
  //         </MenuItem>
  //       </MenuList>
  //     </Paper>
  //   );
  // }
  // if (!widget) {
  //   return <></>;
  // }
  return (
    <Paper sx={{ width: 255 }}>
      <MenuList dense>
        <>
          <MenuItem onClick={(e) => onClickMenuItem(e, 'SELECT_ALL_WIDGETS')}>
            <ListItemIcon>
              <SelectAllIcon />
            </ListItemIcon>
            <ListItemText>Select all widgets</ListItemText>
          </MenuItem>
          {/* <Divider /> */}
        </>
        {widgetElementThatTriggeredContextMenu === 'report-builder-page' && (
          <>
            <MenuItem
              onClick={handleClose}
              style={{
                padding: '0px'
              }}
            >
              {/* <ListItemIcon>
                <FormatColorFillIcon />
              </ListItemIcon> */}
              <ColorPickerPopper3 // colorpicker for report page background
                // widget={widget}
                valueKey={'backgroundColor'}
                reportPageId={selectedPageId}
                handleClose={handleClose}
              />
            </MenuItem>
          </>
        )}
        {([
          'IMAGE_WIDGET',
          'MOCKUP_WIDGET'
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          // 'LINE_CHART_WIDGET',
          // 'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type) ||
          widgetElementThatTriggeredContextMenu === 'report-builder-page') && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  marginRight: '5px',
                  height: '1px',
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.12)'
                }}
              ></div>
              <span style={{ fontSize: '12px' }}>Assets</span>
              <div
                style={{
                  marginLeft: '5px',
                  height: '1px',
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0.12)'
                }}
              ></div>
              {/* <Divider /> */}
            </div>
            {/* <Divider /> */}
            <MenuItem onClick={(e) => onClickMenuItem(e, 'ASSET_LIBRARY')}>
              <ListItemIcon>
                <PhotoLibraryIcon />
              </ListItemIcon>
              <ListItemText>Asset Library</ListItemText>
            </MenuItem>
            <MenuItem onClick={(e) => onClickMenuItem(e, 'UPLOAD_IMAGE')}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText>Upload Image</ListItemText>
            </MenuItem>
          </>
        )}
        {widget?.type && (
          <>
            <DividerWidthTitle title={'Position'} />
            <MenuItem onClick={(e) => onClickMenuItem(e, 'BRING_TO_FRONT')}>
              <ListItemIcon>
                <FlipToFrontIcon />
              </ListItemIcon>
              <ListItemText>Bring to front</ListItemText>
            </MenuItem>
            <MenuItem onClick={(e) => onClickMenuItem(e, 'SEND_TO_BACK')}>
              <ListItemIcon>
                <FlipToBackIcon />
              </ListItemIcon>
              <ListItemText>Send to back</ListItemText>
            </MenuItem>
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <MenuItem
                style={{ width: '100%' }}
                onClick={(e) => onClickMenuItem(e, 'COPY')}
              >
                <ListItemIcon>
                  <ContentCopyIcon />
                </ListItemIcon>
                <ListItemText>Copy</ListItemText>
              </MenuItem>

              {copiedWidgets?.length ? (
                <MenuItem
                  style={{ width: '100%' }}
                  onClick={(e) => onClickMenuItem(e, 'PASTE')}
                >
                  <ListItemIcon>
                    <ContentPasteIcon />
                  </ListItemIcon>
                  <ListItemText>Paste</ListItemText>
                </MenuItem>
              ) : (
                <></>
              )}
            </div>
            <MenuItem onClick={(e) => onClickMenuItem(e, 'DUPLICATE')}>
              <ListItemIcon>
                <ContentCopyIcon />
              </ListItemIcon>
              <ListItemText>Duplicate</ListItemText>
            </MenuItem>

            <Divider />
            <MenuItem onClick={(e) => onClickMenuItem(e, 'DELETE')}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          </>
        )}
        {widgetElementThatTriggeredContextMenu.includes(
          'report-builder-page'
        ) && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {/* <MenuItem
              style={{ width: '100%' }}
              onClick={(e) => onClickMenuItem(e, 'COPY')}
            >
              <ListItemIcon>
                <ContentCopyIcon />
              </ListItemIcon>
              <ListItemText>Copy</ListItemText>
            </MenuItem> */}

            {copiedWidgets?.length ? (
              <MenuItem
                style={{ width: '100%' }}
                onClick={(e) => onClickMenuItem(e, 'PASTE')}
              >
                <ListItemIcon>
                  <ContentPasteIcon />
                </ListItemIcon>
                <ListItemText>Paste</ListItemText>
              </MenuItem>
            ) : (
              <></>
            )}
          </div>
        )}

        {/* {[
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          'MANAGER_ACCOUNT_LOGO_WIDGET',
          'TEXT_WIDGET',
          'NUMBER_WIDGET',
          'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET',
          'PIE_CHART_WIDGET'
        ].includes(widget?.type) && <Divider />} */}

        {/* {[
          'IMAGE_WIDGET',
          'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          'RECTANGLE_SHAPE_WIDGET',
          'NUMBER_WIDGET',
          'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET',
          'PIE_CHART_WIDGET'
        ].includes(widget?.type) && (
          <>
            <Divider />
            <MenuItem
              onClick={(e) => onClickMenuItem(e, 'TOGGLE_SHADOW')}
              style={
                {
                  // background: widget.isShowingCard && 'rgba(150,150,150,0.2)'
                }
              }
            >
              <ListItemIcon>
                <Crop75Icon />
              </ListItemIcon>
              <ListItemText>Shadow</ListItemText>

              {widget.isDropShadowOn ? (
                <CheckBoxIcon style={{ fontSize: '16px' }} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
              )}
            </MenuItem>
          </>
        )} */}

        {[
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          'NUMBER_WIDGET',
          'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET',
          'PIE_CHART_WIDGET'
        ].includes(widget?.type) && (
          <>
            <Divider />
            <MenuItem onClick={(e) => onClickMenuItem(e, 'TOGGLE_FREEZE_DATA')}>
              <ListItemIcon>
                <AcUnitIcon />
              </ListItemIcon>
              <ListItemText>Freeze Data</ListItemText>
              {/* <ListItemIcon> */}
              {widget.isDataFrozen ? (
                <CheckBoxIcon style={{ fontSize: '16px' }} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
              )}
              {/* </ListItemIcon> */}
            </MenuItem>
            <MenuItem
              onClick={(e) =>
                onClickMenuItem(e, 'TOGGLE_FREEZE_COMPARISON_DATA')
              }
            >
              <ListItemIcon>
                <AcUnitIcon />
              </ListItemIcon>
              <ListItemText>Freeze Comparison Data</ListItemText>
              {/* <ListItemIcon> */}
              {widget.isComparisonDataFrozen ? (
                <CheckBoxIcon style={{ fontSize: '16px' }} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
              )}
              {/* </ListItemIcon> */}
            </MenuItem>
          </>
        )}

        {[
          // 'IMAGE_WIDGET',
          // 'MOCKUP_WIDGET',
          // 'MANAGER_ACCOUNT_LOGO_WIDGET',
          // 'TEXT_WIDGET',
          // 'NUMBER_WIDGET',
          'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET'
          // 'PIE_CHART_WIDGET'
        ].includes(widget?.type) && (
          <>
            <Divider />
            <MenuItem
              onClick={(e) => onClickMenuItem(e, 'TOGGLE_AXIS_SETTINGS')}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>Axis settings</ListItemText>
            </MenuItem>
          </>
        )}

        {/* {[
          'IMAGE_WIDGET',
          'MOCKUP_WIDGET',
          'MANAGER_ACCOUNT_LOGO_WIDGET',
          'TEXT_WIDGET',
          'NUMBER_WIDGET',
          'LINE_CHART_WIDGET',
          'COMBO_CHART_WIDGET',
          'PIE_CHART_WIDGET',
          'RECTANGLE_SHAPE_WIDGET'
        ].includes(widget?.type) && (
          <>
            <DividerWidthTitle title={'Opacity'} />
            <MenuItem>
              <SliderMenuItem
                widget={widget}
                styleKey={'opacity'}
                min={0}
                max={1}
                step={0.1}
              />
            </MenuItem>
          </>
        )} */}

        {Boolean(arrayMenuItems.length) && (
          <div
            style={{
              background: 'rgba(150,150,150,0.1)',
              marginTop: '5px',
              marginBottom: '5px'
              // borderTop: '1px solid white'
            }}
          >
            <DividerWidthTitle
              title={
                widgetElementThatTriggeredContextMenu.includes(
                  'report-builder-page'
                )
                  ? 'Background Style'
                  : 'Style'
              }
            />
          </div>
        )}

        {arrayMenuItems?.sort()?.map((val) => {
          let icon = <></>;
          let min = 4;
          let max = 100;
          let step = 1;
          switch (true) {
            case val.includes('FontSize'):
            case val.includes('Opacity'):
            case val.includes('Radius'):
            case val.includes('Width'):
            case val.includes('Filter'):
              // case val.includes('Opacity'):
              switch (true) {
                case val.includes('FontSize'):
                  min = 4;
                  max = 100;
                  step = 1;
                  break;
                case val.includes('Width'):
                  min = 0;
                  max = 10;
                  step = 1;
                  break;
                case val.includes('outerBorderRadius'):
                  min = 0;
                  max = widget?.height?.split('px')?.[0];
                  step = 5;
                  break;
                // case val.includes('legendFillOpacity'):
                // case val.includes('titleFillOpacity'):
                // case val.includes('Opacity'):
                //   min = 0;
                //   max = 1;
                //   step = 0.1;
                //   break;

                case val.includes('Blur'):
                  min = 0;
                  max = 10;
                  step = 0.5;
                  break;

                case val.includes('Brightness'):
                  min = 0;
                  max = 5;
                  step = 0.1;
                  break;

                case val.includes('Contrast'):
                  min = 0;
                  max = 3;
                  step = 0.1;
                  break;

                case val.includes('HueRotate'):
                  min = 0;
                  max = 360;
                  step = 1;
                  break;

                case val.includes('Saturate'):
                  min = 0;
                  max = 3;
                  step = 0.1;
                  break;

                case val.includes('Sepia'):
                case val.includes('Filter'):
                case val.includes('Opacity'):
                case val.includes('Invert'):
                case val.includes('Grayscale'):
                  min = 0;
                  max = 1;
                  step = 0.01;
                  break;
              }

              // 'backgroundImageGrayscaleFilter',
              // 'backgroundImageBlurFilter',
              // 'backgroundImageBrightnessFilter',
              // 'backgroundImageContrastFilter',
              // 'backgroundImageHueRotateFilter',
              // 'backgroundImageInvertFilter',
              // 'backgroundImageOpacityFilter',
              // 'backgroundImageSaturateFilter',
              // 'backgroundImageSepiaFilter'

              return (
                <>
                  <DividerWidthTitle
                    title={widgetOptionDictionary[val] || val}
                  />
                  <MenuItem style={{ padding: '0px' }}>
                    <SliderMenuItem
                      widget={widget}
                      styleKey={val}
                      min={min}
                      max={max}
                      step={step}
                    />
                  </MenuItem>
                  {/* <DividerWidthTitle title={''} /> */}
                </>
              );

            case val.includes('chartFillColors'):
              return (
                <MenuItem
                  onClick={handleClose}
                  style={{
                    padding: '0px'
                  }}
                >
                  <ColorPaletteMenu
                    widget={widget}
                    valueKey={val}
                    handleClose={handleClose}
                  />
                </MenuItem>
              );

            case [
              // 'legendFontSize',
              // 'outerBorderRadius',
              // 'titleFontSize',
              // 'textFontSize',
              // 'dataLabelFontSize',
              // 'legendFillOpacity',
              // 'titleFillOpacity'
            ].includes(val):

            case val.includes('Color'):
              return (
                <MenuItem
                  onClick={handleClose}
                  style={{
                    padding: '0px'
                  }}
                >
                  <ColorPickerPopper2
                    widget={widget}
                    valueKey={val}
                    handleClose={handleClose}
                  />
                </MenuItem>
              );
            // case [
            //   'isShowingTitle'
            //   // 'isShowingCard',
            //   // 'isDropShadowOn',
            //   // 'isCardShadowOn'
            // ].includes(val):

            // case [
            //   'isShowingTitle',
            //   'isShowingLegend',
            //   'isShowingCard',
            //   'isDropShadowOn',
            //   'isCardShadowOn',
            //   'isTextFontBold',
            //   'isTextFontItalic'
            // ].includes(val):
            case val.slice(0, 2) === 'is':
              // case ['isGrayscale', 'isDropShadowOn'].includes(val):
              return (
                <>
                  <MenuItem
                    style={{
                      padding: '0px'
                    }}
                  >
                    <BooleanButton
                      name={widgetOptionDictionary[val]}
                      widget={widget}
                      valueKey={val}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                  {/* <DividerWidthTitle title={val} /> */}
                  {/* <MenuItem
                    onClick={(e) => onClickMenuItem(e, 'UPDATE_WIDGET', val)}
                    style={
                      {
                        // background: widget.isShowingCard && 'rgba(150,150,150,0.2)'
                      }
                    }
                  >
                    <ListItemText>
                      {widgetOptionDictionary[val] || val}
                    </ListItemText>

                    {widget?.[val] ? (
                      <CheckBoxIcon style={{ fontSize: '16px' }} />
                    ) : (
                      <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
                    )}
                  </MenuItem> */}
                </>
              );
          }
        })}

        {/* <MenuItem>
          <ListItemText>Changelog</ListItemText>
        </MenuItem> */}
        {/* <MenuItem>
          <ListItemText>Add space after paragraph</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText>Custom spacing...</ListItemText>
        </MenuItem> */}
      </MenuList>
    </Paper>
  );
}
