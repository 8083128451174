import { documentTypeToShortNameDict } from '../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import formatDateYYYYMMDD from '../../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

export const datePickerWidget = ({
  widget,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData,
  selectedDateStart,
  selectedDateStop
}) => {
  // setValue(formattedValue);
  let copyOfWidget = { ...widget };
  copyOfWidget = {
    ...copyOfWidget,
    value: selectedDateStart + ' - ' + selectedDateStop
  };

  return copyOfWidget;
};
