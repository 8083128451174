export const defaultWidgetObject = {
  titleFontColor: '#000000',
  dataLabelFontColor: '#FFFFFF',
  textFontColor: '#000000',
  valueFontColor: '#000000',
  legendFontColor: '#000000',
  leftAxisFontColor: '#000000',
  bottomAxisFontColor: '#000000',
  rightAxisFontColor: '#000000',

  outerBorderColor: '#000000',
  leftAxisLineColor: '#000000',
  bottomAxisLineColor: '#000000',
  rightAxisLineColor: '#000000',
  leftValGridLineColor: '#f5f5f5',
  rightValGridLineColor: '#f5f5f5',
  chartBorderColor: '#ffffff',

  cardFillColor: '#ffffff',
  titleFillColor: '#bcbcbc',
  shapeFillColor: '#bcbcbc',

  titleFontSize: '16px',
  textFontSize: '14px',
  valueFontSize: '14px',
  dataLabelFontSize: '12px',
  legendFontSize: '12px',
  leftAxisFontSize: '12px',
  bottomAxisFontSize: '12px',
  rightAxisFontSize: '12px',

  isTitleFontBold: true,
  isTextFontBold: true,
  isValueFontBold: true,
  isDataLabelFontBold: true,
  isLegendFontBold: false,
  isLeftAxisFontBold: false,
  isBottomAxisFontBold: false,
  isRightAxisFontBold: false,

  isTitleFontItalic: false,
  isTextFontItalic: false,
  isValueFontItalic: false,
  isDataLabelFontItalic: false,
  isLegendFontItalic: false,
  isLeftAxisFontItalic: false,
  isBottomAxisFontItalic: false,
  isRightAxisFontItalic: false,

  titleFontFamily: 'Roboto',
  textFontFamily: 'Roboto',
  valueFontFamily: 'Roboto',
  dataLabelFontFamily: 'Roboto',
  legendFontFamily: 'Roboto',
  leftAxisFontFamily: 'Roboto',
  bottomAxisFontFamily: 'Roboto',
  rightAxisFontFamily: 'Roboto',

  horizontalTextAlign: 'center',
  verticalTextAlign: 'center',

  outerBorderStyle: 'solid', // ??

  outerBorderWidth: '0px',
  chartBorderWidth: '1px',

  leftAxisLineWidth: '1px', //1,
  bottomAxisLineWidth: '1px', //1,
  rightAxisLineWidth: '1px', // 1,

  leftValGridLineWidth: '1px',
  rightValGridLineWidth: '1px',

  outerBorderRadius: '5px',

  textFontOpacity: 1,
  cardFillOpacity: 1,
  shapeFillOpacity: 1,
  titleFillOpacity: 0.7,
  chartFillOpacity: 1,
  outerBorderOpacity: 0.7,
  leftAxisLineOpacity: 1,
  bottomAxisLineOpacity: 1,
  rightAxisLineOpacity: 1,
  // imageOpacity: 1,

  // isGrayscale: false,
  isCardShadowOn: true,
  isDropShadowOn: true,
  isShowingCard: true,
  isShowingTitle: true,
  isShowingComparison: false,
  isShowingLegend: true,

  imageBackgroundColor: '#ffffff',
  imageBackgroundColorOpacity: 0,
  // backgroundOpacity: 1,

  imageGrayscaleFilter: 0,
  imageBlurFilter: 0,
  imageBrightnessFilter: 1,
  imageContrastFilter: 1,
  imageHueRotateFilter: 0,
  imageInvertFilter: 0,
  imageOpacityFilter: 1,
  imageSaturateFilter: 1,
  imageSepiaFilter: 0,

  mockupImageGrayscaleFilter: 0,
  mockupImageBlurFilter: 0,
  mockupImageBrightnessFilter: 1,
  mockupImageContrastFilter: 1,
  mockupImageHueRotateFilter: 0,
  mockupImageInvertFilter: 0,
  mockupImageOpacityFilter: 1,
  mockupImageSaturateFilter: 1,
  mockupImageSepiaFilter: 0
};

export const widgetStyleKeys = [
  'titleFontColor',
  'dataLabelFontColor',
  'textFontColor',
  'valueFontColor',
  'legendFontColor',
  'leftAxisFontColor',
  'bottomAxisFontColor',
  'rightAxisFontColor',

  'outerBorderColor',
  'leftAxisLineColor',
  'bottomAxisLineColor',
  'rightAxisLineColor',
  'leftValGridLineColor',
  'rightValGridLineColor',
  'chartBorderColor',

  'cardFillColor',
  'titleFillColor',
  'shapeFillColor',

  'titleFontSize',
  'textFontSize',
  'valueFontSize',
  'dataLabelFontSize',
  'legendFontSize',
  'leftAxisFontSize',
  'bottomAxisFontSize',
  'rightAxisFontSize',

  'isTitleFontBold',
  'isTextFontBold',
  'isValueFontBold',
  'isDataLabelFontBold',
  'isLegendFontBold',
  'isLeftAxisFontBold',
  'isBottomAxisFontBold',
  'isRightAxisFontBold',

  'isTitleFontItalic',
  'isTextFontItalic',
  'isValueFontItalic',
  'isDataLabelFontItalic',
  'isLegendFontItalic',
  'isLeftAxisFontItalic',
  'isBottomAxisFontItalic',
  'isRightAxisFontItalic',

  'titleFontFamily',
  'textFontFamily',
  'valueFontFamily',
  'dataLabelFontFamily',
  'legendFontFamily',
  'leftAxisFontFamily',
  'bottomAxisFontFamily',
  'rightAxisFontFamily',

  'outerBorderWidth',
  'chartBorderWidth',

  'leftAxisLineWidth',
  'bottomAxisLineWidth',
  'rightAxisLineWidth',

  'leftValGridLineWidth',
  'rightValGridLineWidth',

  'outerBorderRadius',

  'cardFillOpacity',
  'shapeFillOpacity',
  'titleFillOpacity',
  'chartFillOpacity',
  'outerBorderOpacity',
  'leftAxisLineOpacity',
  'bottomAxisLineOpacity',
  'rightAxisLineOpacity',

  'imageBackgroundColor',
  'imageBackgroundColorOpacity',
  'imageGrayscaleFilter',
  'imageBlurFilter',
  'imageBrightnessFilter',
  'imageContrastFilter',
  'imageHueRotateFilter',
  'imageInvertFilter',
  'imageOpacityFilter',
  'imageSaturateFilter',
  'imageSepiaFilter',

  'mockupImageBackgroundColor',
  'mockupImageBackgroundOpacity',
  'mockupImageGrayscaleFilter',
  'mockupImageBlurFilter',
  'mockupImageBrightnessFilter',
  'mockupImageContrastFilter',
  'mockupImageHueRotateFilter',
  'mockupImageInvertFilter',
  'mockupImageOpacityFilter',
  'mockupImageSaturateFilter',
  'mockupImageSepiaFilter'
];
