import React, { useContext, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SpeedIcon from '@mui/icons-material/Speed';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import Slider from '@mui/material/Slider';

import Tooltip from '@mui/material/Tooltip';

import { TextField } from '@mui/material';

import FormatSizeIcon from '@mui/icons-material/FormatSize';

import { widgetStyleKeys } from './../defaultWidgetObject';

export default function BasicPopover({
  valueKey,
  handleClose: handleMenuClose
}) {
  const { widgets, setWidgets, selectedWidgetIds, presetColors } =
    useContext(ReportBuilderContext);
  // console.log('daysToGraph: ', daysToGraph);

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const defaultFontSize =
      widgets.filter((wid) => selectedWidgetIds.includes(wid.id))?.[0]?.[
        valueKey
      ] || 14;
    setValue(defaultFontSize);
  }, [anchorEl, selectedWidgetIds]);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    handleMenuClose();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [value, setValue] = React.useState(10);

  //   useEffect(() => {
  //     setValue(daysToGraph);
  //   }, [daysToGraph, open]);

  const handleChange = (event, newValue_) => {
    if (typeof newValue_ === 'number') {
      const copyOfWidgets = [...widgets];
      for (let copyOfWidget of copyOfWidgets) {
        if (selectedWidgetIds.includes(copyOfWidget.id)) {
          let newValue = newValue_;
          switch (true) {
            case [
              'leftAxisLineWidth',
              'bottomAxisLineWidth',
              'rightAxisLineWidth'
            ].includes(valueKey):
              // copyOfWidget[valueKey] = newValue;
              break;

            default:
              newValue = newValue + 'px';
          }

          switch (true) {
            case valueKey.includes('all_'):
              const keyPart = valueKey.split('all_')[1];

              const keysToUpdate = widgetStyleKeys.filter((str) =>
                str.includes(keyPart)
              );

              for (let k of keysToUpdate) {
                copyOfWidget[k] = newValue;
              }
              break;

            default:
              copyOfWidget[valueKey] = newValue;
          }
        }
      }
      // console.log('copyOfWidgets: ', copyOfWidgets);
      setWidgets(copyOfWidgets);
    }
    if (typeof newValue_ === 'number') {
      setValue(newValue_);
    }
  };

  let name = '';
  switch (valueKey) {
    case 'all_FontSize':
      name = 'All Font Sizes';
      break;
    case 'all_LineWidth':
      name = 'All Line Widths';
      break;
    case 'all_BorderWidth':
      name = 'All Border Widths';
      break;
    case 'titleFontSize':
      name = 'Title Font Size';
      break;
    case 'textFontSize':
      name = 'Text Font Size';
      break;

    case 'valueFontSize':
      name = 'Value Font Size';
      break;

    case 'dataLabelFontSize':
      name = 'Data Label Font Size';
      break;

    case 'legendFontSize':
      name = 'Legend Font Size';
      break;
    case 'leftAxisFontSize':
      name = 'Left Axis Font Size';
      break;
    case 'bottomAxisFontSize':
      name = 'Bottom Axis Font Size';
      break;
    case 'rightAxisFontSize':
      name = 'Right Axis Font Size';
      break;

    case 'outerBorderRadius':
      name = 'Outer Border Radius';
      break;
    case 'outerBorderWidth':
      name = 'Outer Border Width';
      break;

    case 'leftAxisLineWidth':
      name = 'Left Axis Line Width';
      break;
    case 'bottomAxisLineWidth':
      name = 'Bottom Axis Line Width';
      break;

    case 'rightAxisLineWidth':
      name = 'Right Axis Line Width';
      break;

    case 'leftValGridLineWidth':
      name = 'Left Value Grid Line Size';
      break;
    case 'rightValGridLineWidth':
      name = 'Right Value Grid Line Size';
      break;

    case 'chartBorderWidth':
      name = 'Chart Border Width';
      break;

    case 'textFontOpacity':
      name = 'Text Font Opacity';
      break;

    case 'cardFillOpacity':
      name = 'Card Fill Opacity';
      break;

    case 'shapeFillOpacity':
      name = 'Shape Fill Opacity';
      break;

    case 'titleFillOpacity':
      name = 'Title Fill Opacity';
      break;
    case 'chartFillOpacity':
      name = 'Chart Fill Opacity';
      break;
    case 'outerBorderOpacity':
      name = 'Outer Border Opacity';
      break;
    case 'leftAxisLineOpacity':
      name = 'Left Axis Line Opacity';
      break;
    case 'bottomAxisLineOpacity':
      name = 'Bottom Axis Line Opacity';
      break;
    case 'rightAxisLineOpacity':
      name = 'Right Axis Line Opacity';
      break;
    case 'imageOpacity':
      name = 'Image Opacity';
      break;
  }

  let minValue = 0;
  let maxValue = 50;
  let defaultValue = 1;
  let step = 1;

  switch (true) {
    case [
      'leftAxisLineWidth',
      'bottomAxisLineWidth',
      'rightAxisLineWidth'
    ].includes(valueKey):
      minValue = 0;
      maxValue = 5;
      defaultValue = 0.5;
      step = 0.5;
      break;
    case ['outerBorderRadius'].includes(valueKey):
      minValue = 0;
      maxValue = 1000;
      defaultValue = 5;
      step = 1;
      break;
  }

  return (
    <>
      {/* <Tooltip title="Font-size"> */}
      <Button
        aria-describedby={id}
        style={{
          width: '100%',

          textAlign: 'start',
          textTransform: 'none',
          width: '100%',
          fontSize: '12px',
          padding: '10px 10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start'
        }}
        onClick={handleClick}
      >
        <span>{name}</span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '10px',
            zoom: 0.8
          }}
        >
          {/* <div>{value}</div> */}
          <TextField
            size="small"
            style={{ width: '80px', margin: '10px 0px' }}
            type="number"
            id="outlined-controlled"
            // label="Controlled"
            value={value}
            onChange={(event) => {
              let newValue = event.target.value;
              // if (newValue < 4) {
              //   newValue = 4;
              // }
              // if (newValue > 370) {
              //   newValue = 370;
              // }
              handleChange(event, Math.round(newValue));
            }}
          />
          <div style={{ height: '150px' }}>
            <Slider
              //   style={{ zoom: 0.8 }}
              orientation="vertical"
              value={value}
              min={minValue}
              step={step}
              max={maxValue}
              // scale={calculateValue}
              // getAriaValueText={valueLabelFormat}
              // valueLabelFormat={valueLabelFormat}
              onChange={handleChange}
            />
          </div>

          <div
            style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}
          >
            {/* <Button
              size="small"
              onClick={() => {
                setDaysToGraph(daysToGraph);
                setAnchorEl(null);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={() => {
                setDaysToGraph(value);
                setAnchorEl(null);
              }}
              variant="contained"
            >
              Save
            </Button> */}
          </div>
        </div>
      </Popover>
    </>
  );
}
