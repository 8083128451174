import React from 'react';

import { useTheme } from '@emotion/react';

function Divider({ title }) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          marginRight: Boolean(title.length) && '5px',
          height: '1px',
          width: '100%',
          background:
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.7)'
              : 'rgba(0, 0, 0, 0.12)'
        }}
      ></div>
      {Boolean(title.length) && (
        <span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>{title}</span>
      )}
      <div
        style={{
          marginLeft: Boolean(title.length) && '5px',
          height: '1px',
          width: '100%',
          background:
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.7)'
              : 'rgba(0, 0, 0, 0.12)'
        }}
      ></div>
      {/* <Divider /> */}
    </div>
  );
}

export default Divider;
