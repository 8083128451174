import React, { useContext, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';

import { InterfaceContext } from '../../../context/InterfaceContext';
import { AccountContext } from '../../../context/AccountContext';

import Tooltip from '@mui/material/Tooltip';

import ZoomInIcon from '@mui/icons-material/ZoomIn';

import SaveAsIcon from '@mui/icons-material/SaveAs';

import TextField from '@mui/material/TextField';

import {
  generatePresignedUrlsForUploadMutation,
  createReportPageTemplatesMutation
} from './../../../graphql/queries';

import { DataContext } from '../../../context/DataContext';

import { useMutation } from '@apollo/client';

import { useSnackbar } from 'notistack';

import { ReportBuilderContext } from '../../../context/ReportBuilderContext';

import { createThumbnail } from './report-builder-list/createThumbnail';

import SelectAccountMenu from './utils/SelectAccountMenu';

import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

import SpinnerSmall from '../../layout/SpinnerSmall';

import Resizer from 'react-image-file-resizer';

import formatImage from './export/formatImage';

function gray(imgObj) {
  var canvas = document.createElement('canvas');
  var canvasContext = canvas.getContext('2d');

  var imgW = imgObj.width;
  var imgH = imgObj.height;
  canvas.width = imgW;
  canvas.height = imgH;

  canvasContext.drawImage(imgObj, 0, 0);
  var imgPixels = canvasContext.getImageData(0, 0, imgW, imgH);

  for (var y = 0; y < imgPixels.height; y++) {
    for (var x = 0; x < imgPixels.width; x++) {
      var i = y * 4 * imgPixels.width + x * 4;
      var avg =
        (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
      imgPixels.data[i] = avg;
      imgPixels.data[i + 1] = avg;
      imgPixels.data[i + 2] = avg;
    }
  }

  canvasContext.putImageData(
    imgPixels,
    0,
    0,
    0,
    0,
    imgPixels.width,
    imgPixels.height
  );
  return canvas.toDataURL();
}

// drag drop file component
const resizeFile = (
  file, // Is the file of the image which will resized.
  maxWidth, // Is the maxWidth of the resized new image.
  maxHeight, // Is the maxHeight of the resized new image.
  compressFormat, // Is the compressFormat of the resized new image.
  quality, // Is the quality of the resized new image.
  rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  responseUriFunc, // Is the callBack function of the resized new image URI.
  outputType, // Is the output type of the resized new image.
  minWidth, // Is the minWidth of the resized new image.
  minHeight // Is the minHeight of the resized new image.
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      maxWidth, // Is the maxWidth of the resized new image.
      maxHeight, // Is the maxHeight of the resized new image.
      compressFormat, // Is the compressFormat of the resized new image.
      quality, // Is the quality of the resized new image.
      rotation, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {
        resolve(uri);
      }, // Is the callBack function of the resized new image URI.
      outputType, // Is the output type of the resized new image.
      minWidth, // Is the minWidth of the resized new image.
      minHeight // Is the minHeight of the resized new image.
    );
  });

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default function BasicPopover({}) {
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [isAllowingManagerAccountAccess, setIsAllowingManagerAccountAccess] =
    useState(false);

  const [isAllowingAccountAccess, setIsAllowingAccountAccess] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const { currentManagerAccount } = useContext(AccountContext);
  const { listOfData } = useContext(DataContext);

  const {
    reportPage,
    zoom,
    setZoom,
    setSelectedWidgetIds,
    isExportMode,
    setIsExportMode
  } = useContext(ReportBuilderContext);

  const [name, setName] = React.useState('');
  // const { daysToGraph, setDaysToGraph } = useContext(InterfaceContext);

  // console.log('daysToGraph: ', daysToGraph);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [generatePresignedUrlsForUpload, { called1 }] = useMutation(
    generatePresignedUrlsForUploadMutation
  );

  const [createReportPageTemplates, { called }] = useMutation(
    createReportPageTemplatesMutation
  );

  const [previousZoom, setPreviousZoom] = useState();

  const handleSave = async () => {
    setSelectedWidgetIds([]);
    if (!name) {
      enqueueSnackbar('Please fill in a name', {
        variant: 'error'
      });
      return;
    }
    setIsLoading(true);
    setPreviousZoom(zoom);
    setIsExportMode(true);

    await setZoom(1);

    if (
      !isAllowingManagerAccountAccess &&
      (!isAllowingAccountAccess || !selectedAccountIds?.length)
    ) {
      enqueueSnackbar('Please choose access', {
        variant: 'error'
      });
      setIsExportMode(false);
      setIsLoading(false);
      return;
    }

    // 1. get signed uploadurl

    const copyOfReportPage = { ...reportPage, id: uuidv4() };

    delete copyOfReportPage.thumbnailImageDataUri;
    delete copyOfReportPage.previewImageDataUri;

    const previewImageDataUri = await createThumbnail(
      reportPage,
      1 //previewImageOptions[fileSize].scale
    );

    const previewImageFile = await dataURLtoFile(
      previewImageDataUri,
      name + '.png'
    );

    let input1 = {
      managerAccountId: currentManagerAccount._id,
      isAllowingManagerAccountAccess,
      accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
      type: 'report-page-previews',
      category: 'report-builder',
      subCategory: 'report-page-previews',
      reportUUID: 'page-templates',
      reportPageUUID: copyOfReportPage.id,
      files: [
        {
          id: uuidv4(),
          name: name,
          fileName: name,

          imageNaturalHeight: +reportPage.height.split('px')[0],
          imageNaturalWidth: +reportPage.width.split('px')[0],

          imageNaturalAspectRatio: reportPage.width / reportPage.height,
          type: 'image/png'
        }
      ]
    };
    // console.log('input1: ', input1);
    let res1 = undefined;
    try {
      res1 = await generatePresignedUrlsForUpload({
        variables: { input: input1 }
      });
    } catch (error) {
      enqueueSnackbar('Failed', {
        variant: 'error'
      });
      return;
    }

    const files = res1?.data?.generatePresignedUrlsForUpload?.files;
    const uploadUrl = files[0].url;
    const fileId = files[0]._id;
    const fileUrl = files[0].url;

    copyOfReportPage['previewImageFileId'] = fileId;

    // 4. upload file

    let data = previewImageFile; //'<file contents here>';

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: fileUrl, // 'https://nexustable-s3-bucket-0cc8f0ed-c179-43f3-8ac3-3e4a24aafc14.s3.eu-north-1.amazonaws.com/d38f28b0-2f6a-43ec-9dc2-cfed21d6ba56?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA2O4WVCUGWSHXD74S%2F20241116%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20241116T201748Z&X-Amz-Expires=3600&X-Amz-Signature=8b933efc14f61767659955d75f259ceef3fd3e0b96009168873bc0ca860f55ff&X-Amz-SignedHeaders=host&x-id=PutObject',
      headers: {
        'Content-Type': 'image/png'
      },
      data: data
    };

    try {
      const result = await axios.request(config);
      // if (result?.status === 200) {
      //   enqueueSnackbar('Assets uploaded', {
      //     variant: 'success'
      //   });
      //   // setRefetchAssets(refetchAssets + 1);
      // }
      // console.log('result: ', result);
    } catch (error) {
      console.log('error: ', error);
      enqueueSnackbar('Failed', {
        variant: 'error'
      });
      return;
    }

    // 5. add preview asset id to report page

    // copyOfReportPage[fileSize] = fileId;

    const copyOfWidgets = [
      ...copyOfReportPage.widgets.map((widget) => {
        const copyOfWidget = {
          ...widget,
          metrics: widget.metrics.map((metricObj) => {
            const copyOfMetricObj = { ...metricObj };
            // copyOfMetricObj.id = copyOfMetricObj._id;
            // delete copyOfMetricObj._id;
            return copyOfMetricObj;
          })
        };

        copyOfWidget.id = uuidv4();

        delete copyOfWidget._id;
        delete copyOfWidget.data;
        delete copyOfWidget.dates;
        delete copyOfWidget.columnWidths;

        delete copyOfWidget.value;
        delete copyOfWidget.comparisonValue;

        delete copyOfWidget.imageDataUri;
        delete copyOfWidget.imageUrl;
        delete copyOfWidget.mockupImageDataUri;
        delete copyOfWidget.imageNaturalAspectRatio;
        delete copyOfWidget.imageNaturalHeight;
        delete copyOfWidget.imageNaturalWidth;

        delete copyOfWidget.isDataFrozen;
        delete copyOfWidget.isComparisonDataFrozen;
        delete copyOfWidget.mainData;
        delete copyOfWidget.comparisonData;
        delete copyOfWidget.mainDates;
        delete copyOfWidget.comparisonDates;

        return copyOfWidget;
      })
    ];

    copyOfReportPage.widgets = copyOfWidgets;
    copyOfReportPage.name = name;
    delete copyOfReportPage.isThumbnailOutOfDate;
    delete copyOfReportPage._id;
    delete copyOfReportPage.previewImageUrl;
    delete copyOfReportPage.isDataFrozen;
    delete copyOfReportPage.isComparisonDataFrozen;
    delete copyOfReportPage.backgroundImageDataUri;

    const input = {
      // managerAccountId: currentManagerAccount._id,
      // name,
      managerAccountId: currentManagerAccount._id,
      isAllowingManagerAccountAccess,
      accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
      reportPages: [copyOfReportPage]
      // action: String,
      // updateKeys: [String],
    };

    // console.log('SaveTemplatePopper>input: ', input);

    const res = await createReportPageTemplates({
      variables: { input }
    });

    if (res?.data?.createReportPageTemplates?.response === 'success') {
      enqueueSnackbar('Template created', {
        variant: 'success'
      });
      setIsAllowingManagerAccountAccess(false);
      setIsAllowingAccountAccess(false);
      setName('');
      setAnchorEl(null);
    }
    setZoom(previousZoom || zoom);

    setIsLoading(false);
    setIsExportMode(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip title="Save as template">
        <Button
          aria-describedby={id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: '25px',
            width: '25px',
            minHeight: '25px',
            height: '25px'
            // padding: '10px'
          }}
          onClick={handleClick}
        >
          <SaveAsIcon style={{ fontSize: '16px' }} />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div
          style={{
            padding: '20px 20px 5px 20px',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          Save as template
        </div>
        <div
          style={{
            padding: '0px 20px 20px 20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            rowGap: '10px'
          }}
        >
          {/* <div
            style={{ width: '160px', fontSize: '12px', textAlign: 'center' }}
          >
            The number of days back NexusTable will visualize graphs. NexusTable
            runs slower the higher number you enter.
          </div> */}
          {/* {value < 15 && (
            <div
              style={{
                width: '160px',
                fontSize: '12px',
                textAlign: 'center',
                color: value < 15 && 'rgb(255, 107, 107)'
              }}
            >
              If you have lower than 15 days the orange performance warnings
              won't work.
            </div>
          )} */}

          <div style={{ marginBottom: '10px' }}>
            <TextField
              style={{ width: '290px' }}
              size="small"
              variant="standard"
              id="outlined-controlled"
              label="Template Name"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div style={{ width: '300px' }}>
            <div>Access:</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <div>
                <Checkbox
                  size="small"
                  checked={isAllowingManagerAccountAccess}
                  onChange={() => {
                    setIsAllowingManagerAccountAccess(
                      !isAllowingManagerAccountAccess
                    );
                    setIsAllowingAccountAccess(false);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div
                onClick={() => {
                  setIsAllowingManagerAccountAccess(
                    !isAllowingManagerAccountAccess
                  );
                  setIsAllowingAccountAccess(false);
                }}
                style={{ cursor: 'pointer' }}
              >
                Current Manager Account
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center'
              }}
            >
              <div>
                <Checkbox
                  size="small"
                  checked={isAllowingAccountAccess}
                  onChange={() => {
                    setIsAllowingAccountAccess(!isAllowingAccountAccess);
                    setIsAllowingManagerAccountAccess(false);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
              <div>
                <SelectAccountMenu
                  isDisabled={!isAllowingAccountAccess}
                  selectedAccountIds={selectedAccountIds}
                  setSelectedAccountIds={setSelectedAccountIds}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '10px',
              width: '100%',
              justifyContent: 'end'
            }}
          >
            {
              // isLoading
              false ? (
                <SpinnerSmall />
              ) : (
                <>
                  <Button
                    size="small"
                    onClick={() => {
                      setAnchorEl(null);
                    }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      // setAnchorEl(null);
                      handleSave();
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>
                </>
              )
            }
          </div>
        </div>
      </Popover>
    </div>
  );
}
