import React, { useEffect, useState, useContext, memo } from 'react';

import { useTheme } from '@emotion/react';

import FolderIcon from '@mui/icons-material/Folder';

import { AccountContext } from '../../../context/AccountContext';

import { InterfaceContext } from '../../../context/InterfaceContext';

import { MutationContext } from '../../../context/MutationContext';
import Tooltip from '@mui/material/Tooltip';

import IconComponent from './IconComponent';

import EventIcon from '@mui/icons-material/Event';

import rgba2hex from './../roitable_functions/rgba2hex';

import { useInView } from 'react-intersection-observer';

import './timebox.css';

function Timebox({
  setIsDragging,
  onContextHandler,
  zoom,
  timebox,
  onMouseDownHandler,
  onMouseUpHandler,
  setOriginalTimebox,
  mouseDownTimeboxIds,
  isShiftSelected,
  isTimeboxesMoved,
  setIsTimeboxesMoved,
  isMouseDown,
  setIsMouseDown,
  selectedUser
}) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { currentManagerAccount } = useContext(AccountContext);
  const { altHeld, shiftHeld } = useContext(InterfaceContext);
  const { updateTimeboxes } = useContext(MutationContext);
  const theme = useTheme();

  const [textInput, setTextInput] = useState('');

  // const [isDraggable, setIsDraggable] = useState(false);

  useEffect(() => {
    setTextInput(timebox.text);
  }, [timebox.text]);

  const onChangeHandler = (e) => {
    // console.log('e.target.value: ', e.target.value);
    setTextInput(e.target.value);
  };
  const onSave = async () => {
    if (!timebox.isMutable) {
      return;
    }
    const input = {
      managerAccountId: currentManagerAccount._id,
      selectedUserId: selectedUser?._id,
      updateKeys: ['text'],
      timeboxes: [{ _id: timebox._id, text: textInput?.trim() }]
    };

    await updateTimeboxes({ variables: { input } });
  };

  const [isEditMode, setIsEditMode] = useState();

  let height = ((timebox.endDateTime - timebox.startDateTime) / 60000) * zoom;

  let border = undefined;
  switch (true) {
    case isShiftSelected && mouseDownTimeboxIds.includes(timebox._id):
      border = '3px solid white';
      break;
    case timebox.status === 'COMPLETED':
      border = '2px solid rgb(0,200,0)';
      break;
  }

  // ###################### on drop ############################

  const onDropHandler = async (e) => {
    if (!timebox.isMutable) {
      return;
    }
    console.log('debug95>Timeboxer>onDrop>e: ', e);
    e.stopPropagation();
    e.preventDefault();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    let droppedItem = {};
    try {
      droppedItem = JSON.parse(droppedItemString);
    } catch (error) {
      setIsMouseDown(false);
      return;
    }

    if (
      timebox.accountId &&
      droppedItem.accountId &&
      String(timebox.accountId) !== String(droppedItem.accountId)
    ) {
      return;
    }
    console.log('debug95>row65');
    if (!droppedItem?.id) {
      return;
    }
    console.log('debug95>row68');

    let newArrayOfDocumentIds = [];
    const listOfUniqueDocumentIds = [];
    if (droppedItem.type !== 'account') {
      newArrayOfDocumentIds = [
        ...(timebox.nexusDocuments.map((doc) => String(doc._id)) || []),
        droppedItem.id
      ];
      for (let docId of newArrayOfDocumentIds) {
        if (!listOfUniqueDocumentIds.includes(String(docId))) {
          listOfUniqueDocumentIds.push(String(docId));
        }
      }
    }

    const input = {
      managerAccountId: currentManagerAccount._id,
      selectedUserId: selectedUser?._id,
      updateKeys:
        droppedItem.type === 'account' ? ['accountId'] : ['nexusDocumentIds'],
      timeboxes: [
        {
          _id: timebox._id,
          accountId: droppedItem.accountId,
          nexusDocumentIds:
            droppedItem.type !== 'account' ? listOfUniqueDocumentIds : []
        }
      ]
    };
    console.log('debug95>row68>input');

    await updateTimeboxes({ variables: { input } });
  };

  const onDragOverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // ###########################################################

  // const dragOver = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  // };

  const onDragEndHandler = (e, value) => {
    setIsDragging(false);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      var delayInMilliseconds = 0;
      setTimeout(function () {
        target.style.display = 'flex';
        //your code to be executed after 1 second
      }, delayInMilliseconds);
    }, 0);
  };

  // ####################################################

  const onDragStartHandler = (e) => {
    if (!timebox.isMutable) {
      return;
    }
    e.stopPropagation();
    // e.preventDefault();
    setIsDragging(true);
    // setIsMouseDown(false);
    // const target = e.target;

    // // console.log('target: ', target);
    // e.dataTransfer.setData(
    //   'droppedItem',
    //   JSON.stringify({
    //     id: target.id, //item._id,
    //     type: 'timebox',
    //     documentType: 'timebox'
    //   })
    // );

    // setTimeout(() => {
    //   target.style.display = 'none';
    // }, 0);
    console.log('Timebox>onDragStart>e: ', e);
    setIsMouseDown(false);
    const target = e.target;

    // console.log('target: ', target);
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        id: target.id, //item._id,
        type: 'timebox',
        documentType: 'timebox',
        status: timebox.status,
        eNativeEventOffsetY: e.nativeEvent.offsetY,
        startDateTime: timebox.startDateTime,
        endDateTime: timebox.endDateTime
      })
    );

    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  // ###########################################################

  // ################### colors #####################

  const accountColorAsRGBA = timebox.accountColor
    ? timebox.accountColor
    : theme?.palette?.mode === 'dark'
    ? 'rgba(255,255,255,0.3)'
    : 'rgba(200,200,255,1)';

  const accountColorAsHex = '#' + rgba2hex(accountColorAsRGBA);

  const transparentAccountColorAsHex =
    accountColorAsHex.slice(0, 7) +
    (theme?.palette?.mode === 'dark' ? '05' : '44');
  // const transparentAccountColorAsHexNexusDocumentIcon =
  //   accountColorAsHex.slice(0, 7) + '10';
  const transparentAccountColorAsHexNexusDocumentIconBackground =
    theme?.palette?.mode === 'dark'
      ? 'rgba(255,255,255,0.3)'
      : accountColorAsHex.slice(0, 7) + '20';

  // ###########################################################

  // console.log('timebox?.deadline: ', timebox?.deadline);
  const deadline =
    timebox?.deadline && timebox?.deadline?.toLocaleString()?.slice(0, -3);
  return (
    <div
      onDrop={onDropHandler}
      onDragOver={onDragOverHandler}
      ref={ref}
      id={timebox._id}
      onContextMenu={(e) => onContextHandler(e, timebox)}
      // onDrop={(e) => e.stopPropagation()}
      onDragStart={onDragStartHandler}
      onDragEnd={onDragEndHandler}
      // onDrop={onDropHandler}
      // onDragOver={onDragOverHandler}
      // draggable={altHeld}
      draggable={!isShiftSelected && timebox.isMutable}
      onMouseUp={(e) => {
        console.log('debug96>onMouseUp>e: ', e);
        e.stopPropagation();
        console.log('debug30>Timebox>timebox._id: ', timebox._id);
        onMouseUpHandler(e, 'timebox', timebox._id);
        console.log('debug35>!isShiftSelected: ', !isShiftSelected);
        console.log('debug35>!isTimeboxesMoved: ', !isTimeboxesMoved);
        if (!isShiftSelected && !isTimeboxesMoved && !e.shiftKey) {
          if (!timebox.isMutable) {
            return;
          }
          setIsEditMode(true);
        }
      }}
      style={{
        visibility: !inView && 'hidden',
        overflow: 'hidden',
        userSelect: 'none',
        // background: 'red',
        // border: '3px solid rgb(235 235 255 / 15%)',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgb(240 240 255 / 20%)' //'rgba(255,255,255,0.15)'
            : 'rgba(255, 255, 255, 0.5)', //'rgba(200,200,255,0.3)',
        backgroundImage:
          inView &&
          (theme?.palette?.mode === 'dark'
            ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
            : `linear-gradient(to top left, ${transparentAccountColorAsHex}, ${'rgba(255, 255, 255, 0.5)'})`),
        // padding: '5px',
        position: 'absolute',
        width: '240px',
        // minHeight: '30px',
        height: height + 'px',
        // left: '0px',
        // top: '150px',
        borderRadius: '5px',
        top:
          (Number(timebox.startDateTime.getHours()) * 60 +
            Number(timebox.startDateTime.getMinutes())) *
            zoom +
          'px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        cursor: timebox.isMutable ? 'grab' : 'not-allowed',
        border: border
          ? border
          : theme?.palette?.mode === 'dark' &&
            '1px solid rgb(235 235 255 / 10%)',

        backdropFilter: 'blur(10px)',
        boxShadow:
          theme?.palette?.mode === 'dark'
            ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' // '0px 3px 1px -2px rgb(127 127 255 / 20%), 0px 2px 2px 0px rgb(127 127 255 / 14%), 0px 1px 5px 0px rgb(127 127 255 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
            : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
      }}
      onMouseDown={(e) => {
        // e.stopPropagation();
        // e.preventDefault();
        //   setOriginalTimebox({ ...timebox });
        // onMouseDownHandler(e, timebox._id, 'moveY');
      }}
    >
      {inView ? (
        <>
          {/* <Tooltip
            title={
              <span>{'Deadline: ' + timebox?.deadline?.toLocaleString()}</span>
            }
          > */}
          {deadline && timebox?.status !== 'COMPLETED' && (
            <div
              style={{
                // borderWidth: '1px 1px 0px 1px',
                // borderStyle: 'solid',
                // borderColor:
                //   transparentAccountColorAsHexNexusDocumentIconBackground, // transparentAccountColorAsHex,
                position: 'absolute',
                right: '30px',
                bottom: '-2px',
                fontSize: '8px',
                // background:
                //   transparentAccountColorAsHexNexusDocumentDeadlineBackground,
                background: accountColorAsHex,
                // background:
                //   theme?.palette?.mode === 'dark'
                //     ? 'rgb(100, 100, 111)'
                //     : 'rgba(255,255,255,0.8)',
                borderRadius: '5px 5px 0px 0px'
                // padding: '0px 3px'
                // marginBottom: '5px'
              }}
            >
              <div
                style={{
                  borderWidth: '1px 1px 0px 1px',
                  borderStyle: 'solid',
                  borderColor:
                    transparentAccountColorAsHexNexusDocumentIconBackground,
                  width: '100%',
                  height: '100%',
                  background: 'red',
                  // position: 'relative',
                  padding: '0px 3px',
                  borderRadius: '5px 5px 0px 0px',
                  background:
                    theme?.palette?.mode === 'dark'
                      ? 'rgb(100, 100, 111)'
                      : 'rgba(255,255,255,0.90)'
                }}
              >
                {'DL: ' + deadline}
              </div>
            </div>
          )}
          {/* </Tooltip> */}
          <div
            className={
              theme?.palette?.mode === 'dark'
                ? 'timebox-resize-border-dark'
                : 'timebox-resize-border-light'
            }
            style={{
              width: '100%',
              height: '6px',
              //   background: 'white',
              cursor:
                timebox.isMutable &&
                timebox.type !== 'outlookCalendarEvent' &&
                'ns-resize',
              position: 'absolute',
              top: '0px'
            }}
            onMouseDown={(e) => {
              if (!timebox.isMutable) {
                return;
              }
              //   setOriginalTimebox({ ...timebox });
              e.stopPropagation();
              e.preventDefault();
              if (timebox.type === 'outlookCalendarEvent' || altHeld) {
                return;
              }
              onMouseDownHandler(e, timebox._id, 'changeStartTime');
            }}
            // onMouseUp={(e) => {
            //   e.stopPropagation();
            //   console.log('debug30>Timebox>timebox._id: ', timebox._id);
            //   onMouseUpHandler(e, 'timebox', timebox._id);
            // }}
            // onMouseMove={updateMousePosition}
          ></div>
          <div
            // onDrop={onDropHandler}
            // onDragOver={onDragOverHandler}
            onMouseDown={(e) => {
              if (!timebox.isMutable) {
                return;
              }
              if (!isEditMode) {
                onMouseDownHandler(e, timebox._id, 'moveY');
              } else {
                // e.preventDefault();
                e.stopPropagation();
              }
            }}
            style={{
              fontSize: '12px',
              height: '100%',
              // height: height - 6 + 'px',
              overflow: 'hidden'
            }}
          >
            {isEditMode ? (
              <textarea
                onClick={() => {
                  if (shiftHeld) {
                    onSave();
                    setIsEditMode(false);
                    setIsTimeboxesMoved(false);
                  }
                }}
                onChange={(e) => onChangeHandler(e)}
                value={textInput}
                autoFocus
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    console.log('SAVING!');
                    onSave();
                    setIsEditMode(false);
                    setIsTimeboxesMoved(false);
                    e.preventDefault();
                  }
                  // if (e.key === 'Enter') {
                  //   onSave();
                  // }
                }}
                // onKeyUp={(e) => {
                //   if (e.key === 'Enter') {
                //     e.preventDefault();
                //     return;

                //     onSave();
                //   }
                //   if (e.keyCode === 13 && !e.shiftKey) {
                //     setIsEditMode(false);
                //     setIsTimeboxesMoved(false);
                //   }
                // }}
                style={{
                  lineHeight: '1.5',
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  color: theme?.palette?.mode === 'dark' ? 'white' : 'black',
                  resize: 'none',
                  padding: '9px',
                  // width: timebox.accountId ? '220px' : '240px',
                  // paddingRight: timebox.accountId && '20px',
                  width: '220px',
                  height: '100%',
                  textAlign: 'start',
                  verticalAlign: 'start',
                  background: 'none',
                  border: 'none',
                  outline: 'none',
                  fontSize: '12px'
                }}
                onBlur={() => {
                  if (isEditMode) {
                    onSave();
                    setIsEditMode(false);
                    setIsTimeboxesMoved(false);
                  }
                }}
              />
            ) : (
              <Tooltip
                PopperProps={{
                  style: {
                    visibility: isMouseDown ? 'hidden' : 'visible'
                  }
                }}
                title={
                  <div>
                    {timebox?.accountName && (
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          marginBottom: '5px'
                        }}
                      >
                        {timebox.accountName}
                      </p>
                    )}
                    <p style={{ fontSize: '14px' }}>{timebox.text}</p>
                    <p style={{ fontSize: '12px', marginTop: '5px' }}>
                      Status: {timebox.status}
                    </p>
                    <p style={{ fontSize: '12px', marginTop: '5px' }}>
                      {(timebox?.endDateTime - timebox?.startDateTime) / 60000}{' '}
                      min
                    </p>
                    {deadline && (
                      <p style={{ fontSize: '12px', marginTop: '5px' }}>
                        {'Deadline: ' + deadline}
                      </p>
                    )}
                  </div>
                }
                enterDelay={300}
                placement="left"
              >
                <div
                  style={{
                    // width: '100%',
                    lineBreak: 'anywhere',
                    width: '220px',
                    height: '100%',
                    //   background: 'red',
                    // top: timebox?.duration <= 5 && '-5px',
                    // position: timebox?.duration <= 5 && 'relative',
                    lineHeight: timebox?.duration <= 5 && '3px',
                    padding:
                      timebox?.duration <= 10
                        ? '3px 9px 0px 9px'
                        : '9px 9px 3px 9px',
                    whiteSpace: 'pre-wrap',
                    overflowY: 'scroll'
                  }}
                  // onMouseUp={(e) => {
                  //   console.log('debug30>!isShiftSelected: ', !isShiftSelected);
                  //   console.log('debug30>!isTimeboxesMoved: ', !isTimeboxesMoved);
                  //   if (!isShiftSelected && !isTimeboxesMoved && !e.shiftKey) {
                  //     setIsEditMode(true);
                  //   }
                  // }}
                >
                  {timebox.text}
                </div>
              </Tooltip>
            )}
          </div>
          <div
            className={
              theme?.palette?.mode === 'dark'
                ? 'timebox-resize-border-dark'
                : 'timebox-resize-border-light'
            }
            style={{
              width: '100%',
              height: '6px',
              //   background: 'white',
              cursor:
                timebox.isMutable &&
                timebox.type !== 'outlookCalendarEvent' &&
                'ns-resize',
              position: 'absolute',
              bottom: '0px'
            }}
            onMouseDown={(e) => {
              if (!timebox.isMutable) {
                return;
              }
              e.stopPropagation();
              e.preventDefault();

              if (timebox.type === 'outlookCalendarEvent') {
                return;
              }
              onMouseDownHandler(e, timebox._id, 'changeEndTime');
            }}
            // onMouseUp={(e) => {
            //   e.stopPropagation();
            //   console.log('debug514>timebox>onmouseup');
            //   onMouseUpHandler(e, 'timebox', timebox._id);
            // }}
            // onMouseMove={updateMousePosition}
          ></div>

          <div
            style={{
              position: 'absolute',
              right: '0px',
              top: '-3px',
              //   width: '50px',
              height: '20px',
              display: 'flex',
              justifyContent: 'end',
              marginRight: '30px'
              //   borderRadius: '20px',
              //   background: 'red'
            }}
          >
            {timebox.type === 'outlookCalendarEvent' && (
              <Tooltip title={<span>Outlook Calendar Event</span>}>
                <div
                  style={{
                    marginTop: '1px',
                    //   position: 'absolute',
                    //   right: '0px',
                    //   bottom: '-10px',
                    width: '18px',
                    height: '18px',
                    borderRadius: '20px',
                    // background: 'rgba(200,200,255,1)',

                    // background:
                    //   theme?.palette?.mode === 'dark'
                    //     ? 'rgba(255,255,255,0.3)'
                    //     : 'rgba(200,200,255,0.3)',
                    background:
                      theme?.palette?.mode === 'dark'
                        ? 'rgba(255,255,255,0.3)'
                        : transparentAccountColorAsHexNexusDocumentIconBackground, //'rgba(200,200,255,0.3)',
                    // color: timebox.accountColor
                    //   ? timebox.accountColor
                    //   : theme?.palette?.mode === 'dark'
                    //   ? 'rgba(255,255,255,0.3)'
                    //   : 'rgba(200,200,255,1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                  }}
                >
                  {/* <FolderIcon
                 style={{
                   fontSize: '12px',
                   color: timebox.accountColor
                     ? timebox.accountColor
                     : theme?.palette?.mode === 'dark'
                     ? 'white'
                     : 'white'
                 }}
               /> */}
                  <EventIcon
                    style={{
                      fontSize: '12px',
                      color: timebox.accountColor
                        ? timebox.accountColor
                        : theme?.palette?.mode === 'dark'
                        ? 'white'
                        : 'white'
                    }}
                  />
                </div>
              </Tooltip>
            )}
            {timebox?.nexusDocuments &&
              timebox?.nexusDocuments?.map((nexusDocument, index) => (
                <Tooltip title={<span>{nexusDocument.name}</span>}>
                  <a
                    href={`${window.location.origin}/fast-table/?manager_account_id=${currentManagerAccount._id}&account_ids=${timebox.accountId}&document_ids=${nexusDocument._id}&start_date=${timebox.startDate}&end_date=${timebox.endDate}`}
                  >
                    <div
                      style={{
                        marginTop: '1px',
                        //   position: 'absolute',
                        //   right: '0px',
                        //   bottom: '-10px',
                        width: '18px',
                        height: '18px',
                        borderRadius: '20px',
                        // background: 'rgba(200,200,255,1)',

                        background:
                          theme?.palette?.mode === 'dark'
                            ? 'rgba(255,255,255,0.3)'
                            : transparentAccountColorAsHexNexusDocumentIconBackground, //'rgba(200,200,255,0.3)',
                        // color: timebox.accountColor
                        //   ? timebox.accountColor
                        //   : theme?.palette?.mode === 'dark'
                        //   ? 'rgba(255,255,255,0.3)'
                        //   : 'rgba(200,200,255,1)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                      }}
                    >
                      {/* <FolderIcon
                  style={{
                    fontSize: '12px',
                    color: timebox.accountColor
                      ? timebox.accountColor
                      : theme?.palette?.mode === 'dark'
                      ? 'white'
                      : 'white'
                  }}
                /> */}
                      <IconComponent
                        keyForMap={index}
                        documentType={nexusDocument.documentType}
                        iconColor={
                          timebox.accountColor
                            ? timebox.accountColor
                            : theme?.palette?.mode === 'dark'
                            ? 'white'
                            : 'white'
                        }
                      />
                    </div>
                  </a>
                </Tooltip>
              ))}
          </div>

          {timebox.accountId ? (
            // <Tooltip
            //   draggable={false}
            //   title={<span>{timebox.accountName}</span>}
            //   placement="bottom-end"
            // >
            <Tooltip
              PopperProps={{
                style: {
                  visibility: isMouseDown ? 'hidden' : 'visible'
                }
              }}
              title={
                <div>
                  {timebox?.accountName && (
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        marginBottom: '5px'
                      }}
                    >
                      {timebox.accountName}
                    </p>
                  )}
                  <p style={{ fontSize: '14px' }}>{timebox.text}</p>
                  <p style={{ fontSize: '12px', marginTop: '5px' }}>
                    Status: {timebox.status}
                  </p>
                  <p style={{ fontSize: '12px', marginTop: '5px' }}>
                    {(timebox?.endDateTime - timebox?.startDateTime) / 60000}{' '}
                    min
                  </p>
                  {deadline && (
                    <p style={{ fontSize: '12px', marginTop: '5px' }}>
                      {'Deadline: ' + deadline}
                    </p>
                  )}
                </div>
              }
              enterDelay={300}
              placement="left"
            >
              <div
                // draggable={false}
                onMouseDown={(e) => {
                  if (!timebox.isMutable) {
                    return;
                  }
                  if (isTimeboxesMoved) {
                    e.preventDefault();
                  }
                  onMouseDownHandler(e, timebox._id, 'moveY');
                }}
                onMouseUp={(e) => {
                  if (!isTimeboxesMoved) {
                    window.location.href = `${window.location.origin}/fast-table/?manager_account_id=${currentManagerAccount._id}&account_ids=${timebox.accountId}&start_date=${timebox.startDate}&end_date=${timebox.endDate}`;
                    // e.preventDefault();
                  }
                }}
              >
                <div
                  style={{
                    top: '-3px',
                    right: '0px',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '3px',
                    color: 'white',
                    //   right: '0px',
                    //   top: '-10px',
                    //   top: '0px',
                    //   width: '50px',
                    height: '100%',
                    width: '20px',
                    fontSize: '10px',
                    // padding: '0px 5px',
                    borderRadius: '0px 5px 5px 0px',
                    // background: 'rgba(200,200,255,1)'
                    background: timebox.accountColor
                      ? timebox.accountColor
                      : theme?.palette?.mode === 'dark'
                      ? 'rgb(220 220 250 / 15%)' //'rgba(255,255,255,0.3)'
                      : 'rgba(200,200,255,1)',

                    maxWidth: '60px',
                    cursor: 'pointer'
                    // boxShadow: `0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px ${timebox.accountColor}, 0px 1px 5px 0px rgb(255 255 255 / 12%)`
                    // boxShadow:
                    //   theme?.palette?.mode === 'dark'
                    //     ? `0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)`
                    //     : `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`
                    //   borderRadius: '20px',
                    //   background: 'red'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width: '20px',
                      borderRadius: '0px 5px 5px 0px'
                      // visibility: 'hidden',
                      // background:
                      //   theme?.palette?.mode === 'dark'
                      //     ? 'rgba(255,255,255,0.3)'
                      //     : 'rgba(200,200,255,1)'

                      // background:
                      //   theme?.palette?.mode === 'dark'
                      //     ? 'rgba(255,255,255,0.3)'
                      //     : 'rgba(255,255,255,0.3)'
                    }}
                  >
                    <div
                      style={{
                        transform: 'rotate(-90deg)'
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          height: '100%',
                          width: height - 15 + 'px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        <span
                          style={{
                            width: '100%',
                            textAlign: 'center',
                            fontWeight: 'bold'
                            // marginBottom: '0px'
                          }}
                        >
                          {timebox.accountName}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tooltip>
          ) : (
            // <></>
            <div
              // onDrop={onDropHandler}
              // onDragOver={onDragOverHandler}
              onMouseDown={(e) => {
                if (!timebox.isMutable) {
                  return;
                }
                if (!isEditMode) {
                  onMouseDownHandler(e, timebox._id, 'moveY');
                }
              }}
              style={{
                top: '-3px',
                right: '0px',
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '3px',
                color: 'white',
                //   right: '0px',
                //   top: '-10px',
                //   top: '0px',
                //   width: '50px',
                height: '100%',
                width: '20px',
                fontSize: '10px',
                // padding: '0px 5px',
                borderRadius: '0px 5px 5px 0px',
                // background: 'rgba(200,200,255,1)'
                background: timebox.accountColor
                  ? timebox.accountColor
                  : theme?.palette?.mode === 'dark'
                  ? 'rgb(220 220 250 / 15%)' // 'rgba(255,255,255,0.3)'
                  : 'rgba(200,200,255,1)',

                maxWidth: '60px'
                // cursor: 'pointer'
                // boxShadow: `0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px ${timebox.accountColor}, 0px 1px 5px 0px rgb(255 255 255 / 12%)`
                // boxShadow:
                //   theme?.palette?.mode === 'dark'
                //     ? `0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)`
                //     : `0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)`
                //   borderRadius: '20px',
                //   background: 'red'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '20px',
                  borderRadius: '0px 5px 5px 0px'
                }}
              >
                <div
                  style={{
                    transform: 'rotate(-90deg)'
                  }}
                >
                  <div
                    style={{
                      textAlign: 'center',
                      height: '100%',
                      width: height - 15 + 'px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    <span
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        fontWeight: 'bold'
                        // marginBottom: '0px'
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div></div>
        </>
      )}
    </div>
  );
}

export default memo(Timebox);
