import React, { useContext, useState, useEffect } from 'react';

import ColorPaletteMenu from './color-palette-menu/ColorPaletteMenu';

import ColorPickerPopper from './ColorPickerPopper';

import NumberSlider from './NumberSlider';

import OptionButton from './OptionButton';

import SelectFontFamily from './SelectFontFamily';

import { DialogContext } from '../../../../../context/DialogContext';

import ColorMenu from './ColorMenu';

function TopBar() {
  const { isReportBuilderOpen } = useContext(DialogContext);
  return (
    <div
      style={{
        width: '400px',
        height: '100%',
        // background: 'red',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '10px'
      }}
    >
      {isReportBuilderOpen && (
        <>
          <ColorPaletteMenu />
          {/* <ColorPickerPopper valueKey={'shapeFillColor'} /> */}
          {/* <NumberSlider valueKey={'fontSize'} /> */}
          {/* <OptionButton valueKey={'isFontBold'} />
          <OptionButton valueKey={'isFontItalic'} /> */}
          {/* <ColorPickerPopper valueKey={'fontColor'} />
          <ColorPickerPopper valueKey={'axisLineColor'} /> */}
          {/* <SelectFontFamily /> */}
          <ColorMenu
            category="fontColor"
            valueObjects={[
              'all_FontColor',
              'titleFontColor',
              'textFontColor',
              'valueFontColor',
              'dataLabelFontColor', // addera
              'legendFontColor',
              'leftAxisFontColor',
              'bottomAxisFontColor',
              'rightAxisFontColor'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />

          <ColorMenu
            category="lineColor"
            // valueObjects={[
            //   {
            //     key: 'lineColor',
            //     options: [
            //       'outerBorderColor',
            //       'leftAxisLineColor',
            //       'bottomAxisLineColor',
            //       'rightAxisLineColor',
            //       'leftValGridLineColor',
            //       'rightValGridLineColor'
            //     ]
            //   }
            // ]}
            valueObjects={[
              'all_LineColor',
              'all_BorderColor',
              'outerBorderColor',
              'leftAxisLineColor',
              'bottomAxisLineColor',
              'rightAxisLineColor',
              'leftValGridLineColor',
              'rightValGridLineColor',
              'chartBorderColor'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
          <ColorMenu
            category="fillColor"
            // valueObjects={[
            //   {
            //     key: 'fillColor',
            //     options: [
            //       'cardFillColor',
            //       'titleFillColor',
            //       'shapeFillColor'
            //       // 'chartFillColor'
            //     ]
            //   }
            // ]}

            valueObjects={[
              'all_FillColor',
              'cardFillColor',
              'titleFillColor',
              'shapeFillColor'
              // 'chartFillColor'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
          <ColorMenu
            category="fontSize"
            // valueObjects={[
            //   {
            //     key: 'fontSize',
            //     options: [
            //       'titleFontSize',
            //       'textFontSize',
            //       'dataLabelFontSize', // addera
            //       'legendFontSize',
            //       'leftAxisFontSize',
            //       'bottomAxisFontSize',
            //       'rightAxisFontSize'
            //     ]
            //   }
            // ]}

            valueObjects={[
              'all_FontSize',
              'titleFontSize',
              'textFontSize',
              'valueFontSize',
              'dataLabelFontSize', // addera
              'legendFontSize',
              'leftAxisFontSize',
              'bottomAxisFontSize',
              'rightAxisFontSize'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
          <ColorMenu
            category="bold"
            // valueObjects={[
            //   {
            //     key: 'bold',
            //     options: [
            //       'isTitleFontBold',
            //       'isTextFontBold',
            //       'isDataLabelFontBold', // addera
            //       // 'isLegendFontBold',
            //       'isLeftAxisFontBold',
            //       'isBottomAxisFontBold',
            //       'isRightAxisFontBold'
            //     ]
            //   }
            // ]}

            valueObjects={[
              'all_FontBold',
              'isTitleFontBold',
              'isTextFontBold',
              'isValueFontBold',
              'isDataLabelFontBold', // addera
              // 'isLegendFontBold',
              'isLeftAxisFontBold',
              'isBottomAxisFontBold',
              'isRightAxisFontBold'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
          <ColorMenu
            category="italic"
            // valueObjects={[
            //   {
            //     key: 'italic',
            //     options: [
            //       'isTitleFontItalic',
            //       'isTextFontItalic',
            //       'isDataLabelFontItalic', // addera
            //       'isLegendFontItalic',
            //       'isLeftAxisFontItalic',
            //       'isBottomAxisFontItalic',
            //       'isRightAxisFontItalic'
            //     ]
            //   }
            // ]}
            valueObjects={[
              'all_FontItalic',
              'isTitleFontItalic',
              'isTextFontItalic',
              'isValueFontItalic',
              'isDataLabelFontItalic', // addera
              'isLegendFontItalic',
              'isLeftAxisFontItalic',
              'isBottomAxisFontItalic',
              'isRightAxisFontItalic'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
          <ColorMenu
            category="fontFamily"
            // valueObjects={[
            //   {
            //     key: 'fontFamily',
            //     options: [
            //       'titleFontFamily',
            //       'textFontFamily',
            //       'dataLabelFontFamily', // addera
            //       'legendFontFamily',
            //       'leftAxisFontFamily',
            //       'bottomAxisFontFamily',
            //       'rightAxisFontFamily'
            //     ]
            //   }
            // ]}

            valueObjects={[
              'all_FontFamily',
              'titleFontFamily',
              'textFontFamily',
              'valueFontFamily',
              'dataLabelFontFamily', // addera
              'legendFontFamily',
              'leftAxisFontFamily',
              'bottomAxisFontFamily',
              'rightAxisFontFamily'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />

          <ColorMenu
            category="horizontalTextAlign"
            // valueObjects={[
            //   {
            //     key: 'horizontalTextAlign',
            //     options: ['left', 'center', 'right']
            //   }
            // ]}

            valueObjects={['left', 'center', 'justify', 'right'].map(
              (valueKey) => ({
                key: 'horizontalTextAlign',
                options: [valueKey]
              })
            )}
          />

          <ColorMenu
            category="verticalTextAlign"
            // valueObjects={[
            //   {
            //     key: 'verticalTextAlign',
            //     options: ['top', 'middle', 'bottom']
            //   }
            // ]}
            valueObjects={['top', 'middle', 'bottom'].map((valueKey) => ({
              key: 'verticalTextAlign',
              options: [valueKey]
            }))}
          />

          <ColorMenu
            category="borderWidth"
            // valueObjects={[
            //   {
            //     key: 'borderWidth',
            //     options: [
            //       'outerBorderWidth',
            //       'chartBorderWidth',
            //       'leftAxisLineWidth',
            //       'bottomAxisLineWidth',
            //       'rightAxisLineWidth',
            //       'leftValGridLineWidth',
            //       'rightValGridLineWidth'
            //     ]
            //   }
            // ]}

            valueObjects={[
              'all_LineWidth',
              'all_BorderWidth',
              'outerBorderWidth',
              'chartBorderWidth',
              'leftAxisLineWidth',
              'bottomAxisLineWidth',
              'rightAxisLineWidth',
              'leftValGridLineWidth',
              'rightValGridLineWidth'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />

          <ColorMenu
            category="borderStyle"
            valueObjects={[
              { key: 'outerBorderStyle', options: ['solid', 'dotted', 'dash'] }
              // 'topBorderStyle',
              // 'rightBorderStyle',
              // 'bottomBorderStyle',
              // 'rightBorderStyle'
            ]}
          />
          <ColorMenu
            category="borderRadius"
            // valueObjects={[
            //   {
            //     key: 'borderRadius',
            //     options: [
            //       'outerBorderRadius'
            //       // 'topRightBorderRadius',
            //       // 'bottomRightBorderRadius',
            //       // 'bottomLeftBorderRadius',
            //       // 'topRightBorderRadius'
            //     ]
            //   }
            // ]}

            valueObjects={[
              'outerBorderRadius'
              // 'topRightBorderRadius',
              // 'bottomRightBorderRadius',
              // 'bottomLeftBorderRadius',
              // 'topRightBorderRadius'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
          <ColorMenu
            category="opacity"
            // valueObjects={[
            //   {
            //     key: 'opacity',
            //     options: [
            //       'cardFillOpacity',
            //       'titleFillOpacity',
            //       'chartFillOpacity', // addera
            //       'outerBorderOpacity',
            //       'leftAxisLineOpacity',
            //       'bottomAxisLineOpacity',
            //       'rightAxisLineOpacity'
            //       'imageOpacity'

            //       // 'topRightBorderRadius',
            //       // 'bottomRightBorderRadius',
            //       // 'bottomLeftBorderRadius',
            //       // 'topRightBorderRadius'
            //     ]
            //   }
            // ]}

            valueObjects={[
              'textFontOpacity',
              'cardFillOpacity',
              'shapeFillOpacity',
              'titleFillOpacity',
              'chartFillOpacity', // addera
              'outerBorderOpacity',
              'leftAxisLineOpacity',
              'bottomAxisLineOpacity',
              'rightAxisLineOpacity',
              'imageOpacity'

              // 'topRightBorderRadius',
              // 'bottomRightBorderRadius',
              // 'bottomLeftBorderRadius',
              // 'topRightBorderRadius'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />

          <ColorMenu
            category="filter"
            // valueObjects={[
            //   {
            //     key: 'filter',
            //     options: ['isGrayscale', 'isCardShadowOn', 'isDropShadowOn']
            //   }
            // ]}

            valueObjects={[
              'imageBackgroundColor',
              'imageBackgroundColorOpacity',
              'imageGrayscaleFilter',
              'imageBlurFilter',
              'imageBrightnessFilter',
              'imageContrastFilter',
              'imageHueRotateFilter',
              'imageInvertFilter',
              'imageOpacityFilter',
              'imageSaturateFilter',
              'imageSepiaFilter',

              'mockupImageBackgroundColor',
              'mockupImageBackgroundOpacity',
              'mockupImageGrayscaleFilter',
              'mockupImageBlurFilter',
              'mockupImageBrightnessFilter',
              'mockupImageContrastFilter',
              'mockupImageHueRotateFilter',
              'mockupImageInvertFilter',
              'mockupImageOpacityFilter',
              'mockupImageSaturateFilter',
              'mockupImageSepiaFilter',
              'isCardShadowOn',
              'isDropShadowOn'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
          <ColorMenu
            category="elements"
            // valueObjects={[
            //   { key: 'elements', options: ['isShowingCard', 'isShowingTitle'] }
            // ]}

            valueObjects={[
              'isShowingCard',
              'isShowingTitle',
              'isShowingComparison',
              'isShowingLegend'
            ].map((valueKey) => ({
              key: valueKey,
              options: [valueKey]
            }))}
          />
        </>
      )}
    </div>
  );
}

export default TopBar;
