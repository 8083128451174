import React, { useEffect, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Button } from '@mui/material';

import Popover from '@mui/material/Popover';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import { widgetStyleKeys } from './../defaultWidgetObject';

export default function ComboBox({ valueKey, handleClose: handleMenuClose }) {
  const { widgets, setWidgets, selectedWidgetIds, presetColors } =
    useContext(ReportBuilderContext);
  const [value, setValue] = React.useState(null);

  const [options, setOptions] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  // useEffect(() => {
  //   const defaultFontSize =
  //     widgets.filter((wid) => selectedWidgetIds.includes(wid.id))?.[0]?.[
  //       valueKey
  //     ] || 14;
  //   setValue(defaultFontSize);
  // }, [anchorEl, selectedWidgetIds]);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    handleMenuClose();
    setAnchorEl(null);
  };

  const onChangeHandler = (event, newValue) => {
    const copyOfWidgets = [...widgets];
    for (let copyOfWidget of copyOfWidgets) {
      if (selectedWidgetIds.includes(copyOfWidget.id)) {
        switch (true) {
          case valueKey.includes('all_'):
            const keyPart = valueKey.split('all_')[1];

            const keysToUpdate = widgetStyleKeys.filter((str) =>
              str.includes(keyPart)
            );

            for (let k of keysToUpdate) {
              copyOfWidget[k] = newValue;
            }
            break;

          default:
            copyOfWidget[valueKey] = newValue;
        }
      }
    }
    // console.log('copyOfWidgets: ', copyOfWidgets);
    setWidgets(copyOfWidgets);
    setValue(newValue);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  console.log('options: ', options);

  const defaultProps = {
    options,
    getOptionLabel: (option) => option
  };

  useEffect(() => {
    if (!options.length) {
      if ('queryLocalFonts' in window) {
        // The Local Font Access API is supported

        const runScript = async () => {
          try {
            const availableFonts = await window.queryLocalFonts();
            // console.log('availableFonts: ', availableFonts);

            const fonts = ['Roboto'];

            for (const fontData of availableFonts) {
              if (!fonts.includes(fontData.family)) {
                fonts.push(fontData.family);
              }

              // console.log(fontData.postscriptName);
              // console.log(fontData.fullName);
              // console.log(fontData.family);
              // console.log(fontData.style);
            }
            // console.log('fonts: ', fonts);

            setOptions(fonts || []);
          } catch (error) {}
        };

        runScript();
      }
    }
  }, []);

  let name = '';
  switch (valueKey) {
    case 'all_FontFamily':
      name = 'All Font Family';
      break;
    case 'titleFontFamily':
      name = 'Title Font Family';
      break;
    case 'textFontFamily':
      name = 'Text Font Family';
      break;
    case 'valueFontFamily':
      name = 'Value Font Family';
      break;
    case 'dataLabelFontFamily':
      name = 'Data Label Font Family';
      break;
    case 'legendFontFamily':
      name = 'Legend Font Family';
      break;
    case 'leftAxisFontFamily':
      name = 'Left Axis Font Family';
      break;
    case 'bottomAxisFontFamily':
      name = 'Bottom Axis Font Family';
      break;
    case 'rightAxisFontFamily':
      name = 'Right Axis Font Family';
      break;
  }

  return (
    <>
      <Button
        aria-describedby={id}
        style={{
          width: '100%',

          textAlign: 'start',
          textTransform: 'none',
          width: '100%',
          fontSize: '12px',
          padding: '10px 10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start'
        }}
        onClick={handleClick}
      >
        <span>{name}</span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '10px',
            zoom: 0.8
          }}
        >
          {/* <div>{value}</div> */}
          <Autocomplete
            style={{ width: '200px', zoom: 0.7 }}
            size="small"
            {...defaultProps}
            //   options={top100Films.map((option) => option.title)}
            id="controlled-demo"
            value={value}
            onChange={onChangeHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                // label="controlled"
                variant="standard"
              />
            )}
          />
        </div>
      </Popover>
    </>
  );
}
