import React, { useContext, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SpeedIcon from '@mui/icons-material/Speed';

import { InterfaceContext } from '../../../context/InterfaceContext';

import Slider from '@mui/material/Slider';

import Tooltip from '@mui/material/Tooltip';

import { TextField } from '@mui/material';

export default function BasicPopover() {
  const { daysToGraph, setDaysToGraph } = useContext(InterfaceContext);

  // console.log('daysToGraph: ', daysToGraph);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [value, setValue] = React.useState(10);

  useEffect(() => {
    setValue(daysToGraph);
  }, [daysToGraph, open]);

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  return (
    <div>
      <Tooltip title="Speed settings">
        <Button
          aria-describedby={id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            minHeight: 0,
            minWidth: 0
          }}
          onClick={handleClick}
        >
          <SpeedIcon />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '10px'
          }}
        >
          <div
            style={{
              width: '300px',
              fontSize: '12px',
              textAlign: 'center',
              padding: '10px 20px'
            }}
          >
            The number of days back NexusTable will visualize graphs. NexusTable
            runs slower the higher number you enter.
          </div>

          {/* <div>{value}</div> */}
          <TextField
            size="small"
            style={{ width: '100px', margin: '10px 0px' }}
            type="number"
            id="outlined-controlled"
            // label="Controlled"
            value={value}
            onChange={(event) => {
              let newValue = event.target.value;
              if (newValue < 4) {
                newValue = 4;
              }
              if (newValue > 370) {
                newValue = 370;
              }
              setValue(Math.round(newValue));
            }}
          />
          <div style={{ width: '300px' }}>
            <Slider
              value={value}
              min={4}
              step={1}
              max={370}
              // scale={calculateValue}
              // getAriaValueText={valueLabelFormat}
              // valueLabelFormat={valueLabelFormat}
              onChange={handleChange}
            />
          </div>
          {value < 15 && (
            <div
              style={{
                width: '160px',
                fontSize: '12px',
                textAlign: 'center',
                marginBottom: '20px',
                color: value < 15 && 'rgb(255, 107, 107)'
              }}
            >
              If you have lower than 15 days the orange performance warnings
              won't work.
            </div>
          )}
          <div
            style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}
          >
            <Button
              size="small"
              onClick={() => {
                setDaysToGraph(daysToGraph);
                setAnchorEl(null);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={() => {
                setDaysToGraph(value);
                setAnchorEl(null);
              }}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
}
