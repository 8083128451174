import React, { useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import { Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { deleteReportColorPaletteMutation } from '../../../../../../graphql/queries';

import { useMutation } from '@apollo/client';

import DeleteIcon from '@mui/icons-material/Delete';

import { ReportBuilderContext } from '../../../../../../context/ReportBuilderContext';
// const useStyles = makeStyles((theme) => ({
//   typography: {
//     padding: theme.spacing(1)
//   }
// }));

export default function SimplePopover({ _id, currentManagerAccountId }) {
  const { refetchReportColorPalettes } = useContext(ReportBuilderContext);

  const [deleteReportColorPalette, { called }] = useMutation(
    deleteReportColorPaletteMutation
  );

  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickDeleteHandler = async () => {
    const input = {
      _id,
      managerAccountId: currentManagerAccountId
    };

    await deleteReportColorPalette({ variables: { input } });
    await refetchReportColorPalettes();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Button
        onClick={handleClick}
        style={{
          minWidth: '20px',
          width: '20px',
          minHeight: '20px',
          height: '20px'
        }}
      >
        <DeleteIcon style={{ fontSize: '14px', color: 'red' }} />
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography
          onClick={onClickDeleteHandler}
          // className={classes.typography}
          style={{
            cursor: 'pointer',
            padding: '5px',
            fontSize: '12px',
            border: '1px solid rgba(150,150,150,0.3)'
          }}
        >
          Delete
        </Typography>
      </Popover>
    </div>
  );
}
