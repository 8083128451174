import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';

import FormatSizeIcon from '@mui/icons-material/FormatSize';

import FontDownloadIcon from '@mui/icons-material/FontDownload';

import RoundedCornerIcon from '@mui/icons-material/RoundedCorner';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';
import BorderColorIcon from '@mui/icons-material/BorderColor';

import BorderStyleIcon from '@mui/icons-material/BorderStyle';

import LineWeightIcon from '@mui/icons-material/LineWeight';

import OpacityIcon from '@mui/icons-material/Opacity';

import Tooltip from '@mui/material/Tooltip';

import ColorPickerPopper2 from './ColorPickerPopper2';

import NumberSlider2 from './NumberSlider2';

import SelectFontFamily2 from './SelectFontFamily2';

import BooleanButton from './BooleanButton';

import TextValueButton from './TextValueButton';

import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';

import Divider from '@mui/material/Divider';

export default function BasicMenu({
  //   currentManagerAccountId,
  //   // updateDocument,
  //   item
  //   color,
  //   setColor,
  removeColor,
  index,
  category,
  valueObjects
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let icon = <></>;
  let tooltipText = '';

  switch (category) {
    case 'fillColor':
      icon = <FormatColorFillIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Fill Color';
      break;

    case 'fontColor':
      icon = <FormatColorTextIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Font Color';
      break;

    case 'lineColor':
      icon = <BorderColorIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Line Color';
      break;

    case 'opacity':
      icon = <OpacityIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Opacity';
      break;

    case 'fontSize':
      icon = <FormatSizeIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Font Size';
      break;
    case 'bold':
      icon = <FormatBoldIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Bold';
      break;
    case 'italic':
      icon = <FormatItalicIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Italic';
      break;
    case 'fontFamily':
      icon = <FontDownloadIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Font Family';
      break;
    case 'borderRadius':
      icon = <RoundedCornerIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Border Radius';
      break;
    case 'borderStyle':
      icon = <BorderStyleIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Border Style';
      break;
    case 'borderWidth':
      icon = <LineWeightIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Border Width';
      break;

    case 'horizontalTextAlign':
      icon = <FormatAlignCenterIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Horizontal Text Align';
      break;
    case 'verticalTextAlign':
      icon = <VerticalAlignCenterIcon style={{ fontSize: '16px' }} />;
      tooltipText = 'Vertical Text Align';
      break;
  }
  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Tooltip title={tooltipText}>
        <Button
          // onClick={handleClick('bottom')}
          onClick={handleClick}
          style={{
            minWidth: '20px',
            width: '20px',
            minHeight: '20px',
            height: '20px'
          }}
        >
          {icon}
        </Button>
      </Tooltip>
      <Menu
        // closeOnSelect={false}
        // onClickAway={() => {}}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // onClose={() => {}}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { py: 0 }
        }}
      >
        {[
          ...valueObjects?.filter((valueObj) => valueObj.key.includes('all_')),
          { key: 'divider', options: ['divider'] },
          ...valueObjects?.filter((valueObj) => !valueObj.key.includes('all_'))
        ]?.map((valueObj) => {
          // console.log('valueKey: ', valueKey);
          return valueObj.options.map((option) => {
            let valueKey = valueObj.key;
            let value = option;

            switch (true) {
              case valueKey === 'divider':
                return <Divider style={{ margin: '2px 0px' }} />;

              case option.includes('Color'):
                return (
                  <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
                    <ColorPickerPopper2
                      valueKey={valueKey}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                );
              case option.includes('Size') ||
                valueKey.includes('Width') ||
                valueKey.includes('Radius') ||
                valueKey.includes('Opacity'):
                return (
                  <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
                    <NumberSlider2
                      valueKey={valueKey}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                );

              case option.includes('Bold'):
                return (
                  <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
                    <BooleanButton
                      valueKey={valueKey}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                );
              case option.includes('Italic'):
                return (
                  <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
                    <BooleanButton
                      valueKey={valueKey}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                );
              case option.includes('FontFamily'):
                return (
                  <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
                    <SelectFontFamily2
                      valueKey={valueKey}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                );

              case ['horizontalTextAlign', 'verticalTextAlign'].includes(
                valueKey
              ):
                return (
                  <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
                    <TextValueButton
                      valueKey={valueKey}
                      value={value}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                );
              case category === 'borderStyle':
                return (
                  <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
                    <TextValueButton
                      valueKey={valueKey}
                      value={value}
                      handleClose={handleClose}
                    />
                  </MenuItem>
                );
            }
          });
          //        let name = "";
          // switch (valueKey) {
          //   case 'totleFontColor':
          //     name = 'Shape Fill Color';
          //     break;

          //   case 'legendFontColor':
          //     name = 'Body Text Color';
          //     break;

          //   case 'axisFontColor':
          //     name = 'Body Text Color';
          //     break;
          // }
        })}

        {/* <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
          <ColorPickerPopper2 valueKey={'legendFontColor'} />
        </MenuItem>
        <MenuItem onClick={handleClose} style={{ padding: '0px' }}>
          <ColorPickerPopper2 valueKey={'axisFontColor'} />
        </MenuItem> */}
        {/* <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}
