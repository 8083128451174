import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import { Tooltip } from '@mui/material';

import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

// logo

import NexusTableLogo from './../../img/nexuslogo.png';
import NexusTableLogoWhite from './../../img/nexuslogo-white.png';

// icons

import ViewWeek from '@mui/icons-material/ViewWeek';
import GridOnRounded from '@mui/icons-material/GridOnRounded';
import GridOnTwoToneIcon from '@mui/icons-material/GridOnTwoTone';
import TerminalIcon from '@mui/icons-material/Terminal';
import BoltIcon from '@mui/icons-material/Bolt';
import TimelineSharpIcon from '@mui/icons-material/TimelineSharp';

import EditIcon from '@mui/icons-material/Edit';
// import CloseIcon from '@mui/icons-material/Close';

import MultilineChartIcon from '@mui/icons-material/MultilineChart';

import PowerInputIcon from '@mui/icons-material/PowerInput';

// Redux

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Context

import { AccountContext } from '../../context/AccountContext';

// React router

import { Link, Redirect } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';

// menus
import NavAccountMenu from './nav-account-menu/NavAccountMenu';
import NavUserMenu from './NavUserMenu';

import Warning from './Warning';

import backgroundImagePattern from './../../img/bg-container-purple.png';
// import backgroundBeachImage from './../../img/valentineblueimg.jpg';
import backgroundBeachImage from './../../img/beachimg.jpg';
import octagonal_background from './../../img/hexagonal_background.jpg';

import { SnackbarProvider, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import { InterfaceContext } from '../../context/InterfaceContext';

const drawerWidth = 60;

const openedMixin = (theme) => ({
  // backgroundColor: 'red',
  backgroundColor: theme.palette.drawer.background,
  border: 'none',
  backdropFilter: 'blur(10px)',
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  backgroundColor: theme.palette.drawer.background,
  border: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  //   width: `calc(${theme.spacing(7)} + 1px)`,
  width: 0,
  [theme.breakpoints.up('sm')]: {
    // width: `calc(${theme.spacing(9)} + 1px)`
    width: 0
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  backgroundImage: 'none',
  backgroundColor: theme.palette.drawer.background,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => {
  return {
    backgroundImage: 'none',
    backgroundColor: theme.palette.navbar.background,
    // background: 'rgba(255,255,255,0.3)',
    backdropFilter: 'blur(10px)',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  };
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  backgroundImage: 'none',
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const MiniDrawer = ({
  stylesStoredInRedux,
  children
  // auth: { isAuthenticated, loading },
  //   profile: { profile },
  // managerAccount,
  //   currentAccountIds,
  //   setStyle,
  // user
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { isDemoModeOn } = useContext(InterfaceContext);

  // ###################################################################################################################
  // ############################################ ws snack bar start ###################################################
  // ###################################################################################################################

  const [WSMessage, setWSMessage] = useState('');

  const action = (key) => (
    <>
      {/* <button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
        Alert
      </button> */}
      <Button
        style={{ maxWidth: '40px', minWidth: '40px', width: '40px' }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon />
      </Button>
    </>
  );

  window.WSMessage = (text) => {
    if (WSMessage !== text) {
      setWSMessage(text);
    }
  };

  const [WSSnackbarId, setWSSnackbarId] = useState('');
  const [connectionErrorSnackbarId, setConnectionErrorSnackbarId] =
    useState('');
  const [disconnectedSnackbarId, setDisconnectedSnackbarId] = useState('');
  const [reconnectingSnackbarId, setReconnectingSnackbarId] = useState('');
  const [reconnectedSnackbarId, setReconnectedSnackbarId] = useState('');
  const [connectedSnackbarId, setConnectedSnackbarId] = useState('');

  useEffect(() => {
    console.log('WSMessage1: ', WSMessage);

    let variant = '';

    // let disconnectedSnackbarId = '';
    // let reconnectingSnackbarId = '';
    // let reconnectedSnackbarId = '';
    switch (WSMessage) {
      case 'Disconnected':
        if (reconnectedSnackbarId) {
          closeSnackbar(reconnectedSnackbarId);
          setReconnectedSnackbarId('');
        }
        if (connectionErrorSnackbarId) {
          closeSnackbar(connectionErrorSnackbarId);
          setConnectionErrorSnackbarId('');
        }

        if (!window?.location?.href.includes('table/')) {
          break;
        }

        variant = 'error';
        const disconnectedSnackbarId_ = enqueueSnackbar(WSMessage, {
          variant: variant,
          persist: true,
          preventDuplicate: true,
          action
        });
        setDisconnectedSnackbarId(disconnectedSnackbarId_);
        break;
      case 'Connection error':
        if (reconnectedSnackbarId) {
          closeSnackbar(reconnectedSnackbarId);
          setReconnectedSnackbarId('');
        }
        if (disconnectedSnackbarId) {
          closeSnackbar(disconnectedSnackbarId);
          setDisconnectedSnackbarId('');
        }
        variant = 'error';
        const connectionErrorSnackbarId_ = enqueueSnackbar(WSMessage, {
          variant: variant,
          persist: true,
          preventDuplicate: true,
          action
        });
        setConnectionErrorSnackbarId(connectionErrorSnackbarId_);
        break;
      case 'Reconnecting':
        // case 'Connected':
        if (disconnectedSnackbarId) {
          closeSnackbar(disconnectedSnackbarId);
          setDisconnectedSnackbarId('');
        }
        if (connectionErrorSnackbarId) {
          closeSnackbar(connectionErrorSnackbarId);
          setConnectionErrorSnackbarId('');
        }
        // closeSnackbar(disconnectedSnackbarId);
        variant = 'warning';
        const reconnectingSnackbarId_ = enqueueSnackbar(WSMessage, {
          variant: variant,
          persist: true,
          preventDuplicate: true,
          action
        });
        setReconnectingSnackbarId(reconnectingSnackbarId_);
        break;
      case 'Reconnected':
        if (reconnectingSnackbarId) {
          closeSnackbar(reconnectingSnackbarId);
          setReconnectingSnackbarId('');
        }
        variant = 'success';
        const reconnectedSnackbarId_ = enqueueSnackbar(WSMessage, {
          variant: variant,
          persist: false,
          preventDuplicate: true,
          action
        });
        setReconnectedSnackbarId(reconnectedSnackbarId_);
        break;

      case 'Connected':
        if (disconnectedSnackbarId) {
          closeSnackbar(disconnectedSnackbarId);
          setDisconnectedSnackbarId('');
        }
        if (reconnectingSnackbarId) {
          closeSnackbar(reconnectingSnackbarId);
          setReconnectingSnackbarId('');
        }
        variant = 'success';
        const connectedSnackbarId_ = enqueueSnackbar(WSMessage, {
          variant: variant,
          persist: false,
          preventDuplicate: true,
          action
        });
        setConnectedSnackbarId(connectedSnackbarId_);
        break;
    }
    // if (WSMessage) {
    //   const snackbarId = enqueueSnackbar(WSMessage, {
    //     variant: variant,
    //     persist: Boolean, //false,
    //     preventDuplicate: true,
    //     action
    //   });
    //   setWSMessage('');
    //   console.log('snackbarId1: ', snackbarId);
    //   closeSnackbar(snackbarId);
    // }
  }, [WSMessage]);

  // ###################################################################################################################
  // ############################################ ws snack bar end #########################################################
  // ###################################################################################################################

  const { isAuthenticated, user } = useContext(AuthContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (!isAuthenticated) {
    return <div>{children}</div>;
  }

  return (
    <div
      style={{
        background:
          theme.palette.mode === 'dark'
            ? 'linear-gradient(17deg, rgba(54, 82, 156, 0.8), rgba(54, 82, 156, 0) 70.71%), linear-gradient(336deg, rgba(167, 38, 131, 0.8), rgba(167, 38, 131, 0) 70.71%)'
            : 'white'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          backgroundImage:
            theme.palette.mode === 'dark'
              ? // ? `url(${backgroundImagePattern})`
                `url(${octagonal_background})`
              : `url(${backgroundBeachImage})`,
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed'
        }}
      >
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              // color="inherit"
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '64px',
                width: '100%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',

                    alignItems: 'center',
                    justifyContent: 'center'
                    // height: '45px'
                  }}
                >
                  <Link
                    style={{
                      color: stylesStoredInRedux?.MiniDrawer?.main?.color,
                      fontSize: '1rem',
                      display: 'flex'
                    }}
                    // to={'choose-manager-account'}
                    to={'dashboard'}
                  >
                    <img
                      draggable={false}
                      style={{ height: '36px', maxWidth: '180px' }}
                      // id={item._id}

                      src={
                        theme.palette.mode === 'dark' && isAuthenticated
                          ? NexusTableLogoWhite
                          : NexusTableLogo
                      }
                      alt="Logo"
                    />
                  </Link>
                </div>

                <div style={{ display: 'flex', margin: 'auto' }}>
                  <NavAccountMenu />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: 'auto'
                }}
              >
                {/* <div
                style={{
                  gridColumnStart: 6,
                  gridColumnEnd: 6,
                  width: '60px',
                  margin: 'auto'
                }}
              >
                <Typography variant="h6" noWrap>
                  {managerAccount &&
                    managerAccount.currentManagerAccount &&
                    managerAccount.currentManagerAccount._id && (
                      <NavToolsMenu />
                    )}
                </Typography>
              </div> */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {/* <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '40px'
                  }}
                >
                  <AdvancedModeSwitch colorMode={theme.palette.mode} />
                </div> */}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: '20px'
                    }}
                  >
                    <Link
                      to={`/datasourcehealthdashboard/?manager_account_id=${currentManagerAccount?._id}`}
                    >
                      <Typography variant="h6" noWrap>
                        <Warning />
                      </Typography>
                    </Link>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'end',
                      alignItems: 'center',
                      textAlign: 'right'
                    }}
                  >
                    <Typography variant="h6" noWrap>
                      <NavUserMenu
                        color={stylesStoredInRedux?.MiniDrawer?.main?.color}
                        setOpenDrawer={setOpen}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer
          </Typography> */}
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <div>
              {currentManagerAccount._id && (
                <>
                  <Tooltip
                    enterDelay={500}
                    // leaveDelay={200}
                    title="Table"
                    placement="right"
                    arrow
                  >
                    <Link
                      to={`/fast-table/?manager_account_id=${currentManagerAccount?._id}`}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <GridOnTwoToneIcon
                              style={{
                                // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                                color:
                                  theme.palette.mode === 'dark'
                                    ? 'grey'
                                    : '#21285acc'
                              }}
                            />
                            <BoltIcon
                              style={{
                                // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color,
                                color: 'yellow',
                                position: 'absolute',
                                top: '8px'
                              }}
                            />

                            <span
                              style={{ fontSize: '8px', textAlign: 'center' }}
                            >
                              Table
                            </span>
                          </div>
                        </ListItemIcon>
                        <ListItemText primary={'Table'} />
                      </ListItem>
                    </Link>
                  </Tooltip>

                  <Tooltip
                    enterDelay={500}
                    // leaveDelay={200}
                    title="Newsfeed with the latest comments, changes and pokes."
                    placement="right"
                    arrow
                  >
                    <Link
                      to={`/newsfeed/?manager_account_id=${currentManagerAccount?._id}`}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <TerminalIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color,
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'grey'
                                  : '#21285acc'
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={'NewsFeed'} />
                      </ListItem>
                    </Link>
                  </Tooltip>
                  <Divider />
                  <div
                    style={{
                      width: '59px',
                      fontSize: '8px',
                      textAlign: 'center',
                      paddingTop: '5px',
                      paddingRight: '3px'
                    }}
                  >
                    Beta
                  </div>

                  <Tooltip
                    enterDelay={500}
                    // leaveDelay={200}
                    title="Basic version of NexusTable does not load ad report data."
                    placement="right"
                    arrow
                  >
                    <Link
                      to={`/basictable/?manager_account_id=${currentManagerAccount?._id}`}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                              // justifyContent: 'center',
                              // alignItems: 'center'
                            }}
                          >
                            <GridOnTwoToneIcon
                              style={{
                                // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                                color:
                                  theme.palette.mode === 'dark'
                                    ? 'rgba(128,128,128,0.3)'
                                    : 'rgba(33, 40, 90, 0.4)'
                              }}
                            />
                            <CloseIcon
                              style={{
                                // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color,
                                color: 'rgba(167,38,131,1)',
                                position: 'absolute',
                                top: '10px',
                                left: '18px',
                                fontSize: '20px'
                              }}
                            />
                            {/* <span style={{ fontSize: '8px', textAlign: 'center' }}>
                        FAST
                      </span> */}
                            <span
                              style={{ fontSize: '8px', textAlign: 'center' }}
                            >
                              NO DATA
                            </span>
                          </div>
                        </ListItemIcon>

                        <ListItemText primary={'Table'} />
                      </ListItem>
                    </Link>
                  </Tooltip>
                </>
              )}

              {['system_admin'].includes(user.role) & !isDemoModeOn ? (
                <>
                  <Divider />

                  <Link
                    to={`/datasourcehealthdashboard/?manager_account_id=${currentManagerAccount?._id}`}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        {/* <GridOnRounded
                        style={{
                          color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                        }}
                      /> */}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <MonitorHeartIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'grey'
                                  : '#21285acc'
                            }}
                          />

                          <span
                            style={{ fontSize: '8px', textAlign: 'center' }}
                          >
                            Health
                          </span>
                          {/* <span style={{ fontSize: '6px', textAlign: 'center' }}>
                          (beta)
                        </span> */}
                        </div>
                      </ListItemIcon>
                      <ListItemText primary={'Table'} />
                    </ListItem>
                  </Link>
                  <Link
                    to={`/taxonomy/?manager_account_id=${currentManagerAccount?._id}`}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        {/* <GridOnRounded
                        style={{
                          color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                        }}
                      /> */}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <PowerInputIcon
                            style={{
                              transform: 'rotate(180deg)',
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'grey'
                                  : '#21285acc'
                            }}
                          />

                          <span
                            style={{ fontSize: '8px', textAlign: 'center' }}
                          >
                            Taxon.
                          </span>
                          {/* <span style={{ fontSize: '6px', textAlign: 'center' }}>
                          (beta)
                        </span> */}
                        </div>
                      </ListItemIcon>
                      <ListItemText primary={'Table'} />
                    </ListItem>
                  </Link>
                  <Divider />

                  <Link
                    to={`/tablev6/?manager_account_id=${currentManagerAccount?._id}`}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        {/* <GridOnRounded
                      style={{
                        color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                      }}
                    /> */}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <GridOnTwoToneIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'grey'
                                  : '#21285acc'
                            }}
                          />
                          <TimelineSharpIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color,
                              color: 'rgba(167,38,131,1)',
                              // color: 'yellow',
                              position: 'absolute',
                              top: '8px'
                            }}
                          />
                          <span
                            style={{ fontSize: '8px', textAlign: 'center' }}
                          >
                            WIP
                          </span>
                          {/* <span style={{ fontSize: '6px', textAlign: 'center' }}>
                        (beta)
                      </span> */}
                        </div>
                      </ListItemIcon>
                      <ListItemText primary={'Table'} />
                    </ListItem>
                  </Link>

                  <Divider />
                  <div
                    style={{
                      width: '59px',
                      fontSize: '8px',
                      textAlign: 'center',
                      paddingTop: '5px',
                      paddingRight: '3px'
                    }}
                  >
                    Cached
                  </div>
                  <Link
                    to={`/cachedtable/?manager_account_id=${currentManagerAccount?._id}`}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <GridOnTwoToneIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'grey'
                                  : '#21285acc'
                            }}
                          />
                          <TimelineSharpIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color,
                              color: 'rgba(167,38,131,1)',
                              // color: 'yellow',
                              position: 'absolute',
                              top: '8px'
                            }}
                          />
                          {/* <span style={{ fontSize: '8px', textAlign: 'center' }}>
                        FAST
                      </span> */}
                          <span
                            style={{ fontSize: '8px', textAlign: 'center' }}
                          >
                            Graph
                          </span>
                        </div>
                      </ListItemIcon>
                      <ListItemText primary={'Table'} />
                    </ListItem>
                  </Link>
                  <Link
                    to={`/cachedtablenograph/?manager_account_id=${currentManagerAccount?._id}`}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <GridOnTwoToneIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'grey'
                                  : '#21285acc'
                            }}
                          />
                          <BoltIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color,
                              color: 'yellow',
                              position: 'absolute',
                              top: '8px'
                            }}
                          />
                          {/* <span style={{ fontSize: '8px', textAlign: 'center' }}>
                        FAST
                      </span> */}
                          <span
                            style={{ fontSize: '8px', textAlign: 'center' }}
                          >
                            Table
                          </span>
                        </div>
                      </ListItemIcon>
                      <ListItemText primary={'Table'} />
                    </ListItem>
                  </Link>

                  <Link
                    to={`/datadashboard/?manager_account_id=${currentManagerAccount?._id}`}
                  >
                    <ListItem button>
                      <ListItemIcon>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <MultilineChartIcon
                            style={{
                              // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                              color:
                                theme.palette.mode === 'dark'
                                  ? 'grey'
                                  : '#21285acc'
                            }}
                          />

                          {/* <span style={{ fontSize: '8px', textAlign: 'center' }}>
                        FAST
                      </span> */}
                          <span
                            style={{ fontSize: '8px', textAlign: 'center' }}
                          >
                            Bench
                          </span>
                        </div>
                      </ListItemIcon>
                      <ListItemText primary={'Table'} />
                    </ListItem>
                  </Link>
                  {/* <Link to="/kanban">
                      <ListItem button>
                        <ListItemIcon>
                          <ViewWeek
                            style={{
                              color: stylesStoredInRedux.MiniDrawer.AppBar.color
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={'Kanban'} />
                      </ListItem>
                    </Link> */}
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <div>
              <Divider />
              <div
                style={{
                  width: '59px',
                  fontSize: '8px',
                  textAlign: 'center',
                  paddingTop: '5px',
                  paddingRight: '3px'
                }}
              >
                Legacy
              </div>
              <Tooltip
                enterDelay={500}
                // leaveDelay={200}
                title="Slow version is a slow legacy version of NexusTable. It gives you longer graphs at the expense of loading speed."
                placement="right"
                arrow
              >
                <Link
                  to={`/table/?manager_account_id=${currentManagerAccount?._id}`}
                >
                  <ListItem button>
                    <ListItemIcon>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <GridOnTwoToneIcon
                          style={{
                            // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color
                            color:
                              theme.palette.mode === 'dark'
                                ? 'grey'
                                : '#21285acc'
                          }}
                        />
                        <TimelineSharpIcon
                          style={{
                            // color: stylesStoredInRedux?.MiniDrawer?.AppBar?.color,
                            color: 'rgba(167,38,131,1)',
                            // color: 'yellow',
                            position: 'absolute',
                            top: '8px'
                          }}
                        />
                        <span style={{ fontSize: '8px', textAlign: 'center' }}>
                          Slow
                        </span>
                      </div>
                    </ListItemIcon>
                    <ListItemText primary={'Table'} />
                  </ListItem>
                </Link>
              </Tooltip>
            </div> */}
          </List>
          {/* <Divider /> */}
        </Drawer>
        <Box
          component="main"
          style={{
            padding: 0,
            marginTop: '64px',
            // background:
            //   theme.palette.mode === 'dark'
            //     ? 'linear-gradient(17deg, rgba(54, 82, 156, 0.8), rgba(54, 82, 156, 0) 70.71%), linear-gradient(336deg, rgba(167, 38, 131, 0.8), rgba(167, 38, 131, 0) 70.71%)'
            //     : 'white',
            height: 'calc(100vh - 64px)'
          }}
          sx={{ flexGrow: 1, p: 3 }}
        >
          <div
            style={{
              height: 'calc(100vh - 64px)'
              // backgroundImage: `url(${backgroundImagePattern})`,
              // backgroundImage:
              //   theme.palette.mode === 'dark'
              //     ? `url(${backgroundImagePattern})`
              //     : `url(${backgroundBeachImage})`,
              // backgroundSize: 'cover',
              // backgroundAttachment: 'fixed'
            }}
          >
            {children}
          </div>
        </Box>
      </Box>
    </div>
  );
};

MiniDrawer.propTypes = {
  auth: PropTypes.object.isRequired,
  //   profile: PropTypes.object,
  managerAccount: PropTypes.object.isRequired,
  //   style: PropTypes.object.isRequired,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  //   profile: state.profile,
  managerAccount: state.managerAccount
  //   stylesStoredInRedux: state.style
  // user: state.auth.user
  //   currentAccountIds: state.account.currentAccountIds
});

export default connect(mapStateToProps, {})(MiniDrawer);
