// import { pieChart } from './pieChart';

import { calcWidget } from './calcWidget';

export const calcWidgets = ({
  isReportBuilderOpen,
  reportPage,
  widgets,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData,
  selectedDateStart,
  selectedDateStop
}) => {
  const newWidgets = [];
  for (let widget of widgets) {
    const copyOfWidget = calcWidget({
      isReportBuilderOpen,
      reportPage,
      widget,
      listOfFilteredDataSourceRows,
      aggregatedDateRange,
      startDateForLastDaysData,
      selectedDateStart,
      selectedDateStop
    });

    newWidgets.push(copyOfWidget);
  }

  return newWidgets;
};
