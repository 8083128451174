import React, { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import PaletteIcon from '@mui/icons-material/Palette';

import { MutationContext } from '../../../../../../context/MutationContext';

import { AccountContext } from '../../../../../../context/AccountContext';

import { DialogContext } from '../../../../../../context/DialogContext';

import { ReportBuilderContext } from '../../../../../../context/ReportBuilderContext';

// import nexusTableLogo from './../../../../img/nexustableicon.png';

import notifyMe from '../../../../roitable_functions/sendNotification';

import ReportColorPaletteDialog from '../../../report-color-palette-dialog/ReportColorPaletteDialog';

// import { useLazyQuery } from '@apollo/client';

// import { reportColorPalettesQuery } from '../../../../../../graphql/queries';
import { Divider } from '@mui/material';

import DeleteReportColorPaletteButton from './DeleteReportColorPaletteButton';

import Tooltip from '@mui/material/Tooltip';

export default function ColorPaletteMenu({ calendarUserId }) {
  const { currentManagerAccountId, currentAccountIds } =
    useContext(AccountContext);

  const {
    reportBuilderColorPalette,
    setReportBuilderColorPalette,
    widgets,
    setWidgets,
    selectedWidgetIds,
    reportColorPalettes,
    setReportColorPalettes
  } = useContext(ReportBuilderContext);
  const { fetchOutlookCalendarEvents } = useContext(MutationContext);

  const { reportColorPaletteDialogOpen, setReporColorPaletteDialogOpen } =
    useContext(DialogContext);

  // const [reportColorPalettes, setReportColorPalettes] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // const variables = {
  //   input: {
  //     managerAccountId: currentManagerAccountId,
  //     accountIds: currentAccountIds
  //     // reportAssetListType: selectedReportAssetList?.type
  //     // reportId: selectedReport?._id,
  //     // reportType: selectedReport?.type
  //   }
  // };

  // const [loadReportColorPalettes, { called, loading, data, refetch }] =
  //   useLazyQuery(reportColorPalettesQuery, {
  //     variables,
  //     fetchPolicy: 'no-cache'
  //     // skip: !currentManagerAccountId && skip
  //   });

  // useEffect(() => {
  //   // console.log('debug9924>row52');
  //   if (currentManagerAccountId && currentAccountIds && open) {
  //     loadReportColorPalettes();
  //   }
  // }, [currentManagerAccountId, currentAccountIds, open]);

  // useEffect(() => {
  //   if (data?.reportColorPalettes && called) {
  //     setReportColorPalettes(data?.reportColorPalettes);
  //   }
  // }, [data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (e, value) => {
    switch (value) {
      // case 'fetchOutlookCalendarEvents':
      //   const input = {
      //     managerAccountId: currentManagerAccount._id,
      //     userId: calendarUserId
      //   };
      //   console.log('debug95>row68>input');

      //   await fetchOutlookCalendarEvents({ variables: { input } });

      //   break;

      // case 'turnOnNotifications':
      //   notifyMe('Notifications are now on!');
      //   break;
      default:
    }
    setAnchorEl(null);
  };

  const onClickReportColorPaletteHandler = (e, reportColorPalette) => {
    // console.log('reportColorPalette: ', reportColorPalette);
    if (selectedWidgetIds?.length) {
      const copyOfWidgets = [...widgets];
      for (let copyOfWidget of copyOfWidgets) {
        if (selectedWidgetIds.includes(copyOfWidget.id)) {
          copyOfWidget.chartFillColors = reportColorPalette?.colors || [];
        }
      }
      // console.log('copyOfWidgets: ', copyOfWidgets);
      setWidgets(copyOfWidgets);
    } else {
      setReportBuilderColorPalette(reportColorPalette?.colors || []);
    }

    setAnchorEl(null);
  };

  const managerAccountReportColorPalettes = reportColorPalettes?.filter(
    (reportColorPalette) => reportColorPalette?.managerAccountIds?.length
  );

  const accountReportColorPalettes = reportColorPalettes?.filter(
    (reportColorPalette) => reportColorPalette?.accountIds?.length
  );

  return (
    <div>
      <Tooltip title="Color Palette">
        <Button
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20px',
            height: '20px',
            minHeight: 0,
            minWidth: 0
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <PaletteIcon style={{ fontSize: '18px' }} />
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <ListItemIcon>
          <ContentCut fontSize="small" />
        </ListItemIcon> */}
        {/* <MenuItem
          style={{ fontSize: '12px' }}
          onClick={(e) => handleClose(e, 'fetchOutlookCalendarEvents')}
        >
          Fetch Outlook Calendar Events
        </MenuItem> */}
        {/* <MenuItem
          style={{ fontSize: '12px' }}
          onClick={(e) => handleClose(e, 'turnOnNotifications')}
        >
          Turn on notifications
        </MenuItem> */}
        {managerAccountReportColorPalettes?.length ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                marginRight: '5px',
                height: '1px',
                width: '100%',
                background: 'rgba(0, 0, 0, 0.12)'
              }}
            ></div>
            <span
              style={{
                fontSize: '10px',
                whiteSpace: 'nowrap'
              }}
            >
              Manager Account
            </span>
            <div
              style={{
                marginLeft: '5px',
                height: '1px',
                width: '100%',
                background: 'rgba(0, 0, 0, 0.12)'
              }}
            ></div>
            {/* <Divider /> */}
          </div>
        ) : (
          <></>
        )}

        {managerAccountReportColorPalettes?.map((reportColorPalette) => (
          <MenuItem
            style={{ fontSize: '12px' }}
            onClick={(e) =>
              onClickReportColorPaletteHandler(e, reportColorPalette)
            }
            // onClick={(e) => {
            //   setReporColorPaletteDialogOpen(true);
            //   handleClose(e, 'fetchOutlookCalendarEvents');
            // }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{reportColorPalette?.name}</span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '2px'
                  }}
                >
                  {reportColorPalette?.colors?.map((color) => (
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '2px',
                        backgroundColor: color
                      }}
                    ></div>
                  ))}
                </div>
              </div>
              <DeleteReportColorPaletteButton
                _id={reportColorPalette._id}
                currentManagerAccountId={currentManagerAccountId}
              />
            </div>
          </MenuItem>
        ))}
        {accountReportColorPalettes?.length ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                marginRight: '5px',
                height: '1px',
                width: '100%',
                background: 'rgba(0, 0, 0, 0.12)'
              }}
            ></div>
            <span
              style={{
                fontSize: '10px',
                whiteSpace: 'nowrap'
              }}
            >
              Account
            </span>
            <div
              style={{
                marginLeft: '5px',
                height: '1px',
                width: '100%',
                background: 'rgba(0, 0, 0, 0.12)'
              }}
            ></div>
            {/* <Divider /> */}
          </div>
        ) : (
          <></>
        )}

        {accountReportColorPalettes?.map((reportColorPalette) => (
          <MenuItem
            style={{ fontSize: '12px' }}
            onClick={(e) =>
              onClickReportColorPaletteHandler(e, reportColorPalette)
            }
            // onClick={(e) => {
            //   setReporColorPaletteDialogOpen(true);
            //   handleClose(e, 'fetchOutlookCalendarEvents');
            // }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>{reportColorPalette?.name}</span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: '2px'
                  }}
                >
                  {reportColorPalette?.colors?.map((color) => (
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '2px',
                        backgroundColor: color
                      }}
                    ></div>
                  ))}
                </div>
              </div>
              <DeleteReportColorPaletteButton
                _id={reportColorPalette._id}
                currentManagerAccountId={currentManagerAccountId}
              />
            </div>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          style={{ fontSize: '12px' }}
          onClick={(e) => {
            setReporColorPaletteDialogOpen(true);
            handleClose(e, 'fetchOutlookCalendarEvents');
          }}
        >
          Custom color palette
        </MenuItem>
      </Menu>
      <ReportColorPaletteDialog />
    </div>
  );
}
