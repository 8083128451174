const textWidget = async (widget, pres, slide, chartFillColors) => {
  if (widget.isShowingCard) {
    slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
      fill: {
        // color: 'FFFFFF',
        // transparency: (widget.opacity - 1) * -100 //5
        color: widget.cardFillColor || 'FFFFFF',
        transparency: (widget.cardFillOpacity - 1) * -100 //5
      },
      // border: { pt: '1', color: 'f1f1f1', transparency: 10 },
      line: +widget?.outerBorderWidth?.split('px')[0] !== 0 && {
        width: +widget?.outerBorderWidth?.split('px')[0] / 2,
        color: widget.outerBorderColor.split('#')[1], //'f1f1f1',
        transparency: (widget.outerBorderOpacity - 1) * -100 //5
      },
      w: +widget.width.split('px')[0] / 72 / 2,
      h: +widget.height.split('px')[0] / 72 / 2,
      x: +widget.left.split('px')[0] / 72 / 2,
      y: +widget.top.split('px')[0] / 72 / 2,

      // rectRadius: 0.05,
      rectRadius:
        +widget.outerBorderRadius.split('px')[0] / 72 / 2 +
        (+widget.outerBorderRadius.split('px')[0] === 0 ? 0.05 : 0),
      shadow: widget.isCardShadowOn && {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    });
  }

  slide.addText(widget.text, {
    w: +widget.width.split('px')[0] / 72 / 2,
    h: +widget.height.split('px')[0] / 72 / 2,
    x: +widget.left.split('px')[0] / 72 / 2,
    y: +widget.top.split('px')[0] / 72 / 2,

    margin: 5,
    fontFace: widget?.textFontFamily || 'Roboto', // 'Roboto', // 'Arial',
    // fontSize: (+widget?.bodyTextFontSize?.split('px')[0] / 96) * 72 || 7,
    fontSize: +widget?.textFontSize?.split('px')[0] / 2 || 7,
    color: widget?.textFontColor || '#000000',
    bold: widget?.isTextFontBold || false,
    italic: widget?.isTextFontItalic || false,
    // underline: true,
    isTextBox: true,
    align: widget.horizontalTextAlign, //'left'
    valign: widget.verticalTextAlign,

    transparency: (widget.textFontOpacity - 1) * -100 //5
    // fill: { color: 'F1F1F1' }
    // highlight: '#bcbcbc'
  });

  let titleWidth = Math.round(widget?.title?.length * 9 + 10);
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  // slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
  //   fill: {
  //     color: 'ababab',
  //     transparency: 90
  //   },
  //   border: { pt: '1', color: 'f1f1f1', transparency: 10 },
  //   w: (titleWidth + 20) / 72 / 2,
  //   h: 25 / 72 / 2,
  //   x:
  //     +widget.left.split('px')[0] / 72 / 2 +
  //     widget.width.split('px')[0] / 72 / 2 -
  //     (titleWidth + 20) / 72 / 2,
  //   y: +widget.top.split('px')[0] / 72 / 2 + 0 / 72 / 2,

  //   rectRadius: 0.01
  //   // shadow: {
  //   //   type: 'outer',
  //   //   blur: 6,
  //   //   offset: 2,
  //   //   angle: 45,
  //   //   color: 'bcbcbc',
  //   //   opacity: 0.2
  //   // }
  // });

  // slide.addText(widget.title, {
  //   w: titleWidth / 72 / 2,
  //   h: 20 / 72 / 2,
  //   x:
  //     +widget.left.split('px')[0] / 72 / 2 +
  //     widget.width.split('px')[0] / 72 / 2 -
  //     (titleWidth + 10) / 72 / 2,
  //   y: +widget.top.split('px')[0] / 72 / 2 + 2 / 72 / 2,

  //   margin: 0,
  //   fontFace: 'Roboto', // 'Arial',
  //   fontSize: 6,
  //   color: '000000',
  //   bold: true,
  //   // underline: true,
  //   isTextBox: true,
  //   align: 'left'
  //   // fill: { color: 'F1F1F1' }
  //   // highlight: '#bcbcbc'
  // });
};

export default textWidget;
